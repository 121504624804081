.product-compare-page-block {
	padding-top: 16px;
	.cap-me {
		text-transform: uppercase;
	}
	.product-list-page-block {
		.items-grid {
			.product-items {
				.item {
					width: 240px !important;
					height: auto !important;
					padding-bottom: 10px !important;
					margin-top: 10px;
					margin-left: 0px;
					margin-right: 20px;
					.remove-product-from-compare {
						position: absolute;
						z-index: 2;
						top: 0px;
						right: 0px;
						width: 20px;
						height: 20px;
						a {
							display: block;
							width: 14px;
							height: 14px;
                            margin: 12px 12px 0 0;
							@include svg-icon("ic_cross_thin_text");
                            transition: none;
							&:hover {
								@include svg-icon("ic_cross_thin_red");
							}
						}
					}
					.product-item-info {
						width: auto;
						.product-image-photo {
							margin: auto;
						}
						.actions-primary {
							float: left !important;
							button {
								display: block;
								font-size: 10px;
								font-weight: 700;
								letter-spacing: 1px;
								height: 32px;
								padding-top: 0px;
								padding-bottom: 0px;
							}
						}
						.actions-secondary {
							margin-top: 0px !important;
							float: right;
							a {
								display: block;
								width: 32px !important;
								height: 32px;
								margin-bottom: 0px;
								background-size: 14px auto !important;
								&:after {
                                    transform: scale(0.8);
                                    margin-top: -9px;
								}
								&:before {
									display: none !important;
								}
							}
						}
					}
				}
			}
		}
	}
	.table-wrapper {
		&.comparison {
			margin-top: -10px;
			table {
				table-layout: auto;
				tbody {
					tr {
						th {
							font-size: 16px;
							font-weight: 800;
							color: $color-13;
							padding-right: 30px;
							padding-top: 20px;
							padding-bottom: 20px;
							text-align: right;
							text-transform: uppercase;
							border-bottom: 0px !important;
							width: 260px !important;
						}
						td {
							padding-left: 10px;
							padding-right: 10px;
							padding-top: 20px;
							padding-bottom: 20px;
							border-bottom: 0px !important;
							width: 260px !important;
							.text-style {
								color: $color-2;
							}
						}
					}
					&:not(:first-child) {
						tr {
							&:nth-child(odd) {
								td {
									background-color: $color-12;
								}
							}
						}
					}
				}
			}
		}
	}
}
