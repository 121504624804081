.leasing-page-block {
	padding-top: 40px;
	.form-block {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
		.leasing-info-text {
			margin-bottom: 10px;
			font-weight: bold;
			font-size: 14px;
			color: $color-3;
		}
		.checkbox-mp {
			float: left;
			.mp-12 {
				width: 50%;
				float: left;
			}
			&:after {
				content: " ";
				clear: both;
			}
		}
		.inputblock {
			.inputblock-select {
				&:before {
					display: none;
				}
				.field {
					.control {
						&:before {
							content: "";
							position: absolute;
							top: 1px;
							right: 1px;
							background-color: $color-1;
							border-radius: 4px;
                            width: 10px;
                            height: 6px !important;
							@include svg-icon("ic_chevron_thick_down_red");
							pointer-events: none;
                            transform: scale(1.2);
                            margin: 22px 10px;

                            @media (max-width: map-get($grid-breakpoints, "md") - 1) {
                                margin: 15px 10px;
                            }
						}
					}
				}
			}
			.field {
				margin-bottom: 20px;

				.control {
					.ui-datepicker-trigger {
						position: absolute;
						top: 50%;
						right: 0px;
						@include transform(translateY(-50%));
					}
				}
			}
		}
		.actions-toolbar {
			margin-top: 40px;
			.primary {
				float: right;
				button {
					margin-right: 0px;
				}
			}
			.secondary {
				float: left;
				a {
					margin-top: 15px;
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.leasing-page-block {
		.form-block {
			.inputblock {
				.inputblock-select {
					.field {
						.control {
							&:before {
								height: 38px;
							}
						}
					}
				}
			}
		}
	}
}
