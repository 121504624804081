.detachable-block {
	.secondary-detach {
		display: none;
	}
	&:not(.primary-detached) {
		.primary-detach {
			.container {
				padding: 0px;
				max-width: none;
				&:before,
				&:after {
					display: none;
				}
			}
		}
	}
	&.primary-detached {
		.primary-detach {
			position: fixed;
			z-index: 999;
			bottom: 0px;
			left: 0px;
			right: 0px;
			.container {
				text-align: right;
				background-color: $color-1;
				@include boxy-shadow("0px 1px 14px rgba(0, 0, 0, 0.1)");
				.detachable-block-container-inner {
					text-align: left;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					padding-top: 15px;
					padding-bottom: 15px;
					padding-left: 30px;
					padding-right: 30px;
					.title-wrapper {
						flex: 1 1 auto;
						h4 {
							padding-top: 14px;
							padding-right: 20px;
							margin-bottom: 0px;
							text-align: right;
						}
					}
					.elegant-wrapper-block {
						flex: 0 1 auto;
						border: 0px;
						padding: 0px;
						padding-top: 10px;
						padding-right: 20px;
						margin: 0px;
					}
					.actions {
						flex: 0 1 auto;
						margin: 0px !important;
						text-align: right;
					}

					.field {
						float: none !important;
						display: inline-block !important;
					}
					i {
						float: none !important;
					}
					.product-options-wrapper {
						display: inline-block;
					}
				}
			}
		}
	}
	&.secondary-detached {
		.secondary-detach {
			position: fixed;
			z-index: 100;
			bottom: 0px;
			left: 0px;
			right: 0px;
			display: block;
			.container {
				text-align: right;
				background-color: $color-1;
				@include boxy-shadow("0px 1px 14px rgba(0, 0, 0, 0.1)");
				.detachable-block-container-inner {
					text-align: left;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					padding-top: 15px;
					padding-bottom: 15px;
					padding-left: 30px;
					padding-right: 30px;
					button {
						div {
							display: grid;
							grid-auto-flow: column;
							align-items: center;
						}
						span {
							margin-top: 2px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.detachable-block {
		&.primary-detached {
			.primary-detach {
				.container {
					.detachable-block-container-inner {
						.title-wrapper {
							flex: 1 1 100%;
							h4 {
								text-align: left;
							}
						}
						.actions {
							flex: 1 0 auto;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.detachable-block {
		&.primary-detached {
			.primary-detach {
				.container {
					max-width: 100%;
					.detachable-block-container-inner {
						.actions {
							flex: 1 0 auto;
							text-align: center;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: map-get($grid-breakpoints, "md") - 1) {
	.detachable-block {
		&.secondary-detached {
			.secondary-detach {
				.container {
					.detachable-block-container-inner {
						.actions {
							text-align: center;
							margin: 0px !important;
							flex: 1 1 auto;
							button {
								width: auto;
							}
						}
					}
				}
			}
		}
	}
}
