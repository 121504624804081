/*  Ways to include fonts:

    URL references to outside server:
        + for an example, if it's a google font, there is a high chance their servers have less latency and better caching mechanism
        + easier font management, just 1 line in header

    URL references to local server:
        - complicated font management
        - if you have to generate webfont yourself, then fonts generated by webfont generators (like fontsquirrel) need to be manually adjusted in their settings, because they tend to come out distorted

    Base64:
    	- complicated font management
        + bigger latency, less bandwidth

 */

/* OPEN SANS */
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=latin-ext');

/* RALEWAY */
//@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800&subset=latin-ext');

/* GILROY */
@import url('/pub/assets/fonts/Gilroy/declaration.css');
