/* Safari 10.1+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

	.cart-summary-wrapper {
		.cart-summary {
			.shipping {
				.arrowed-link-style {
					top: 0%;
					@include transform(none);
				}
				.refresh-cart {
					top: 0%;
					@include transform(none);
				}
			}
		}
	}

}}