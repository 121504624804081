.delivery-block {
	display: flex;
	flex-direction: row;
	.left {
		flex: 1 0 420px;
		max-width: 420px;
		.delivery-options-list {
			.item {
				margin-bottom: 20px;
				.title {
					display: flex;
					position: relative;
					font-size: 16px;
					font-weight: 300;
					.icon-wrapper {
						flex: 0 1 72px;
					}
					.title-inner {
						flex: 1 1 5px;
						.title-wrapper {
							position: relative;
							top: 50%;
							@include transform(translateY(-50%));
							.meta, .desc-meta {
								font-weight: 600;
							}
							.desc-meta {
								font-size: 14px;
							}
							.desc-after-meta {
								font-weight: 400;
							}
							.highlight {
								font-weight: 600;
								color: $color-3;
							}
						}
					}
				}
				.content {

				}
			}
		}
	}
	.right {
		flex: 1 1 auto;
		padding-left: 40px;
		padding-right: 40px;
		.splash-wrapper {
			position: relative;
			top: 50%;
			@include transform(translateY(-50%));
			text-align: center;
			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 20px;
			}
			.highlight {
				span {
					display: inline-block;
					font-size: 16px;
					line-height: 1;
					font-weight: 800;
					letter-spacing: 0.9px;
					padding-left: 24px;
					padding-right: 24px;
					color: $color-3;
					position: relative;
					&:before {
						content: "";
						position: absolute;
						height: 18px;
						top: -1px;
						left: -1px;
						border-left: 1px solid $color-8;
					}
					&:first-child {
						padding-left: 0px;
						&:before {
							display: none;
						}
					}
					&:last-child {
						padding-right: 0px;
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.delivery-block {
		.right {
			.splash-wrapper {
				.highlight {
					span {
						padding-top: 10px;
						padding-bottom: 10px;
						padding-left: 0px;
						padding-right: 0px;
						&:before {
							left: 50%;
							@include transform(translateX(-50%));
							width: 50px;
							height: auto;
							border-left: 0px;
							border-top: 1px solid $color-8;
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.delivery-block {
		flex-wrap: wrap;
		.left {
			margin-bottom: 30px;
		}
		.right {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

@media (max-width: 359px) {
	.delivery-block {
		.left {
			flex: 1 0 auto;
			max-width: 100%;
		}
	}
}
