.review-summary-wrapper {
	position: absolute;
	top: 31px;
	left: 0px;
	.review-ratings {
		float: none;
		margin-left: -7px;
		margin-bottom: 17px;
		float: left;
		.rating-summary {
			.rating-result  {
				&:before {
					color: $color-8;
				}
				& > span {
					&:before {
						color: $color-3;
					}
				}
			}
		}
	}
	h3 {
		float: left;
		margin-left: 50px;
	}
}

.review-list-block {
	margin-bottom: 0px;
	.block-content {
		ol {
			margin-bottom: 0px;
			li {
				padding: 0px;
				margin: 0px;
				border: 0px;
				border-bottom: 1px solid $color-12;
				padding-top: 28px;
				padding-bottom: 5px;
				&:before {
					display: none;
				}
				.review-ratings {
					float: none;
					margin-left: -7px;
					margin-bottom: 17px;
					.rating-summary {
						.rating-result  {
							&:before {
								color: $color-8;
							}
							& > span {
								&:before {
									color: $color-3;
								}
							}
						}
					}
				}
				.review-title-holder {
					margin-bottom: 7px;
				}
				.review-details {
					margin-left: 0px;
					.review-author {
						font-size: 12px;
						font-weight: 300;
						line-height: 14px;
						color: $color-13;
						float: left;
						strong {
							font-weight: 300;
						}
					}
					.review-date {
						font-size: 12px;
						font-weight: 300;
						line-height: 14px;
						color: $color-8;
						float: left;
						time {
							padding-left: 4px;
						}
					}
				}
				.review-content {
					margin-left: 0px;
					margin-bottom: 0px;
				}
				&:last-child {
					border-bottom: 0px;
				}
			}
		}
		.review-toolbar {
			border: 0px;
			margin: 0px;
			border-top: 1px solid $color-12;
			.pager-block {
				float: right;
				.pager {
					.pages {
						border: 0px;
						padding: 0px;
					}
					&.loading {
						&:before {
							background-size: 40px 40px;
						}
					}
				}
				ul {
					margin-top: 25px;
					margin-bottom: -10px;
					li {
						padding-left: 0px;
						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.review-summary-wrapper {
		h3 {
			font-size: 17px;
			margin-top: 5px;
		}
	}
}