.product-gifting-block {
	font-size: 12px;
	line-height: 16px;
	font-weight: 800;
	color: $color-20;
	.product-gifting-inner {
		position: relative;
		display: inline-block;
		padding-top: 2px;
		padding-left: 25px;
		img {
			position: absolute;
			top: 0px;
			left: 0px;
			max-width: 16px;
			max-height: 16px;
		}
	}
}