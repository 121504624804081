.user-menu-block {
	& > a {
		color: $color-13;
		padding-left: 33px;
		position: relative;
		float: left;
		text-decoration: none;
		.icon {
			position: absolute;
			bottom: 3px;
			left: 0px;
		}
		&.wishlist-link {
			height: 28px;
			line-height: 28px;
			.link-inner {
				display: inline-block;
				vertical-align: bottom;
				line-height: 14px;
			}
		}
		&.auth-link {
			padding-left: 29px;
			height: 28px;
			line-height: 28px;
            padding-right: 10px;
			.link-inner {
				display: inline-block;
				vertical-align: bottom;
				line-height: 14px;
                width: 75px;
			}
			&.logged-in {
				padding-top: 7px;
			}
		}
		&:hover {
			color: $color-3;
		}
	}
	.auth-wrapper {
		position: relative;
		float: left;
		.auth-link {
			display: block;
			color: $color-13;
			position: relative;
			text-decoration: none;
			padding-left: 29px;
			height: 28px;
			line-height: 28px;
			.icon {
				position: absolute;
				bottom: 3px;
				left: 0px;
			}

			.link-inner {
				display: inline-block;
				vertical-align: bottom;
				line-height: 14px;
			}
            .auth-link-text {
                width: 75px;
            }

            &.logged-in {
				padding-top: 7px;
			}
			&:hover {
				color: $color-3;
			}
		}
		.logout-wrapper {
			position: absolute;
			z-index: 2;
			top: 100%;
			left: 0px;
			right: 0px;
			visibility: hidden;
			@include opacity(0);
			@include transition(all $default_transition_time);
			.logout-link {
				display: block;
				padding-top: 8px;
				padding-bottom: 8px;
				padding-left: 10px;
				padding-right: 10px;
				border-radius: 5px;
				background-color: $color-1;
				border: 1px solid $color-21;
				@include transition(all $default_transition_time);
				text-align: center;
				color: $color-13;
				text-decoration: none;
				&:hover {
					color: $color-3;
				}
			}
		}
		&:hover {
			.logout-wrapper {
				visibility: visible;
				@include opacity(100);
				.logout-link {
					@include transform(translateY(10px));
				}
			}
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
	html {
		&[lang="ru"] {
			.user-menu-block {
				& > a {
					&.wishlist-link {
						margin-right: -30px;
					}
				}
			}
		}
	}
}
