.open-product-page-block {
	position: relative;
	.product-list-slider-block {
		margin-bottom: 73px;
		.highlighted {
			font-weight: 600;
		}
	}
	.product-gallery-details-wrapper {
		margin-bottom: 112px;
		.left {
			float: left;
			width: 610px;
			.product.media {
				margin-bottom: 25px;
			}
			.left-inner-wrapper {
				max-width: 480px;
				margin-left: auto;
				.product-gifting-block {
					font-size: 16px;
					line-height: 26px;
					text-align: center;
					margin-bottom: 10px;
					.product-gifting-inner {
						padding-top: 5px;
						padding-left: 31px;
						img {
							max-width: 26px;
							max-height: 26px;
						}
					}
				}
			}
		}
		.right {
			float: right;
			width: 630px;
			.page-title-wrapper {
				.page-title {
					font-size: 30px;
					line-height: 36px;
					font-weight: 600;
					letter-spacing: 1.5px;
					text-transform: uppercase;
					margin-bottom: 4px;
				}
			}
			.product-info-main {
				.product-info-price {
					margin-bottom: 15px !important;
					.product-info-stock-sku {
						border: 0px;
						margin-top: 0px;
						margin-bottom: 16px;
						padding-bottom: 0px;
						.stock {
							display: none;
						}
						.product {
							font-size: 16px;
							font-weight: 300;
							color: $color-8;
						}
					}
					.product-reviews-summary {
						margin-top: 0px;
						margin-bottom: 27px;
						.rating-summary {
							padding-right: 15px;
							top: -2px;
							.rating-result  {
								&:before {
									color: $color-8;
								}
								& > span {
									&:before {
										color: $color-3;
									}
								}
							}
						}
						.reviews-actions {
							.action {
								&.view {
									display: inline-block;
									color: $color-3;
									font-size: 16px;
									line-height: 1;
									font-weight: 800;
									letter-spacing: 0.9px;
									position: relative;
									margin-right: 21px;
									padding-right: 25px;
									&:before {
										content: "(";
										letter-spacing: 0px;
									}
									&:after {
										content: ")";
										letter-spacing: 0px;
									}
									span {
										&:first-child {
											&:before {
												content: "";
												position: absolute;
												top: 50%;
												@include transform(translateY(-50%));
												right: 0px;
												height: 15px;
												border-left: 1px solid $color-8;
											}
										}
									}
								}
								&.add {
									display: inline-block;
									color: $color-3;
									font-size: 16px;
									line-height: 1;
									font-weight: 800;
									letter-spacing: 0.9px;
									position: relative;
									padding-right: 20px;
									margin-right: 0px;
									&:before {
										content: "";
										position: absolute;
										top: 50%;
										right: 0px;
										@include transform(translateX(0px) translateY(-50%));
										width: 16px;
										height: 16px;
										@include svg-icon("ic_chevron_medium_right_red");
										@include transition(all $default_transition_time);
									}
									&:hover {
										&:before {
											@include transform(translateX(3px) translateY(-50%));
										}
									}
								}
							}
						}
					}
                    .kursi-discount-text-PDP {
                        font-size: 15px;
                        line-height: 20px;
                    }
					.price-outer-wrapper {
						display: flex;
						.price-outer-wrapper-left {
							flex: 0 1 auto;
							padding-right: 20px;
						}
						.price-box {
                            div.register-kursi-15-percent {
                                display: none;
                            }
							display: flex;
							.normal-price,
							.special-price,
							.old-price {
                                margin: 0 0 3px;
								display: block;
								flex: 0 1 auto;
								justify-content: center;
								.price-container {
									display: block;
									.countering-ko-removal-actions-wrapper {
										display: block;
										position: relative;
									}
									.price-label {
										display: none !important;
									}
									.price-wrapper {
										display: inline-block;
										font-size: 16px;
										line-height: 1;
										font-weight: 600;
										color: $color_13;
										position: relative;
										padding-right: 25px;
										&:before {
											content: attr(data-price-full);
											font-size: 50px;
											position: relative;
											z-index: 1;
										}
										&:after {
											content: attr(data-price-change);
											position: absolute;
											top: 1px;
											left: calc(100% - 20px);
											font-size: 24px;
											font-weight: 600;
											@include noselect();
										}
										.price {
											overflow: hidden;
											position: absolute;
											bottom: 8px;
											right: 0px;
											font-size: 18px;
											font-weight: 600;
											@include noselect();
											width: 20px;
											height: 23px;
											text-indent: -9999px;
											&:before {
												content: "€";
												font-size: 18px;
												line-height: 1;
												position: absolute;
												bottom: 0px;
												left: 0px;
												text-indent: 0px;
											}
										}
									}
								}
							}
                            .special-price {
                                .price-container {
                                    padding-right: 25px;
                                    .countering-ko-removal-actions-wrapper {
                                        .price-wrapper {
                                            color: #E53647;
                                        }
                                    }
                                }
                            }
							.old-price {
								order: 2;
								max-width: 50%;
								line-height: 54px;
                                font-size: 45px;
								.price-container {
									padding-right: 25px;
									.countering-ko-removal-actions-wrapper {
										&:before {
											content: "";
											width: 100%;
											border-bottom: 2px solid $color_8;
											@include transform(rotate(-20deg));
											position: absolute;
											z-index: 2;
											top: 44%;
											left: 0px;
										}
									}
									.price-wrapper {
										color: $color_8;
										&:before {
											font-size: 45px;
										}
										&:after {
											font-size: 24px;
										}
										.price {
											bottom: 8px;
											&:before {
												font-size: 18px;
											}
										}
										.strike {
											width: 100%;
											border-bottom: 2px solid $color_8;
											@include transform(rotate(-20deg));
											position: absolute;
											z-index: 2;
											top: 44%;
											left: 0px;
										}
									}
								}
							}
							.price-unit {
								flex: 0 1 auto;
								margin-left: 15px;
								padding-left: 10px;
								border-left: 1px solid $color-13;
								color: $color-13;
								font-size: 18px;
								font-weight: 600;
								height: 45px;
								line-height: 76px;
                                order: 3;
							}
							.minimal-price-link {
								display: none;
							}
						}
						.prices-tier-wrapper {
							max-width: 333px;
							margin-left: auto;
							border-radius: 3px;
							border: 1px solid $color-12;
							padding-top: 8px;
							padding-bottom: 8px;
							padding-left: 18px;
							padding-right: 18px;
							.prices-tier {
								width: 100%;
								background-color: transparent;
								margin: 0px;
								padding: 0px;
								td {
									padding: 0px;
									&:first-child {
										font-size: 12px;
										font-weight: 800;
										color: $color-22;
										text-align: left;
										padding-right: 10px;
									}
									&:last-child {
										font-size: 16px;
										font-weight: 800;
										color: $color-22;
										text-align: right;
										padding-left: 10px;
										&:first-letter {
											color: $color-3;
										}
									}
								}
							}
						}
					}
					.additional-pricing-info {
						.pricing-info {
							font-size: 14px;
							line-height: 1;
							font-weight: 600;
							color: $color-13;
						}
						.sale-info {
							font-size: 12px;
							line-height: 14px;
							font-weight: 300;
							letter-spacing: 0.5px;
							color: $color-8;
						}
					}
				}
				.product-delivery-info {
					display: flex;
					border-top: 1px solid $color-8;
					border-bottom: 1px solid $color-8;
					padding-top: 10px;
					padding-bottom: 10px;
					margin-bottom: 20px;
					.delivery-info-item {
						font-size: 14px;
						line-height: 1;
						font-weight: 300;
						color: $color-13;
						flex: 1 1 auto;
						padding-left: 18px;
						padding-right: 18px;
						.inner-wrapper {
							position: relative;
							padding-top: 14px;
							padding-bottom: 14px;
							i {
								position: absolute;
								left: 0px;
								top: 50%;
								@include transform(translateY(-50%));
							}
							b {
								font-weight: 800;
							}
							a {
								display: block;
								font-size: 12px;
								font-weight: 800;
								color: $color-3;
								margin-top: 4px;
							}
						}
						&.pickup {
							.inner-wrapper {
								padding-left: 45px;
							}
						}
						&.delivery {
							.inner-wrapper {
								padding-left: 57px;
								&:before {
									content: "";
									position: absolute;
									top: 0px;
									bottom: 0px;
									left: -18px;
									border-left: 1px solid $color-12;
								}
							}
						}
						&:first-child {
							padding-left: 0px;
							&.delivery {
								.inner-wrapper {
									&:before {
										display: none;
									}
								}
							}
						}
						&:last-child {
							padding-right: 0px;
						}
					}
				}
				.product-add-form {
					margin-bottom: 10px;
					border: 0px;
					#product-addtocart-button {
						div {
							display: grid;
							grid-auto-flow: column;
							align-items: center;
							min-height: 24px;
							span {
								margin-top: 2px;
							}
						}
					}
					.box-tocart {
						display: block;
						margin: 0px;
						.fieldset {
							margin: 0px;
							letter-spacing: 0px;
							.title {
								font-size: 16px;
								font-weight: 800;
								color: $color-13;
								margin-bottom: 16px;
							}
							.elegant-wrapper-block {
								i {
									float: left;
									margin-top: 5px;
									margin-right: 30px;
								}
								.field {
									float: left;
									display: block;
									padding-left: 0px;
									line-height: 1;
									margin: 0px;
									margin-right: 30px;
									.control {
										margin: 0px;
										display: inline-block;
										position: relative;
										input, select {
											font-family: $default_font_stack;
											display: block !important;
											width: 62px !important;
											border: 1px solid $color-8;
											border-radius: 3px;
											font-size: 12px;
											font-weight: 300;
											color: $color-13;
											text-align: center;
											@include transition(all $default_transition_time);
											float: left;
											margin-right: 0px;
											height: 32px;
											&:focus {
												@include boxy-shadow("0px 0px 2px rgba(52, 74, 106, 0.5)");
												border-color: $color-14;
											}
											&.mage-error {
												border-color: $color-3;
											}
											& + {
												.mage-error {
													display: none !important;
												}
											}
										}
										select {
											width: 110px !important;
										}
										.qty-buttons-wrapper {
											display: none;
										}
									}
                                    .qty-changer {
                                        display: inline-block;
                                        margin-left: 4px;
                                        .minus,
                                        .plus {
                                            display: block;
                                            height: 14px;
                                            width: 14px;
                                            background-color: $color-26;
                                            background-position: center center;
                                            background-repeat: no-repeat;
                                            background-size: 8px 8px;
                                            -webkit-transition: all .3s;
                                            transition: all .3s;
                                            border-radius: 2px;
                                            &:hover {
                                                background-color: $color-3;
                                            }
                                        }
                                        .minus {
                                            background-image: url('../images/btn-minus.png');
                                        }
                                        .plus {
                                            background-image: url('../images/btn-plus.png');
                                            margin-bottom: 4px;
                                        }
                                    }
									.label {
										display: inline-block;
										font-size: 12px;
										line-height: 1;
										font-weight: 600;
										color: $color-2;
										margin: 0px;
										vertical-align: top;
										margin-top: 10px;
										margin-left: 2px;
									}
								}
								.product-options-wrapper {
									.swatch-opt {
										width: 100%;
										float: left;
										margin: 0;
										font-size: 14px;
										.swatch-attribute-label {
											float: left;
											margin-top: 6px;
										}
										.swatch-attribute-selected-option {
											display: none;
										}
										.swatch-attribute-options {
											margin-bottom: 6px !important;
											float: left;
											.swatch-option {
												margin-bottom: 0;
											}
										}
									}
									.field {
										.label {
											display: none;
										}
										.control {
											&:before {
												content: "";
												position: absolute;
												top: 1px;
												right: 1px;
												background-color: $color-1;
												border-radius: 4px;
												width: 8px;
												height: 6px;
												@include svg-icon("ic_chevron_thick_down_red");
                                                pointer-events: none;
                                                margin: 12px 10px;
                                                transform: scale(1.2);
											}
										}
										&:nth-last-child(2) {
											margin-right: 0px;
										}
									}
								}
							}
							.actions {
								float: left;
								margin-bottom: 25px;
								margin-right: 20px;
								button {
									min-width: 234px;
									padding-left: 20px;
									padding-right: 20px;
								}
							}
						}
					}
				}
			}
			.product-social-links {
				margin-bottom: 24px;
				&:before,
				&:after {
					content: "";
					display: table;
				}
				&:after {
					clear: both;
				}
				.product-addto-links {
					a {
						float: left;
						width: 53px;
						height: 53px;
						background-repeat: no-repeat;
						background-position: center center;
						background-size: auto 20px;
						@include transition(all $default_transition_time);

                        &:after {
                            content: '';
                            display: block;
                            width: 24px;
                            height: 30px;
                            margin: 0 auto;
                        }

						span {
							display: none;
						}
						&.towishlist {
							background-color: $color-3;
                            &:after {
                                @include svg-icon("ic_heart_white");
                            }
							&:hover {
								background-color: lighten($color-3, 5%);
							}
						}
						&.tocompare {
							display:grid;
							margin-bottom: 0;
                            &:after {
								@include svg-icon("ic_compare_white");
								background-image: url('/assets/images/ic_compare_blue.svg');
								background-repeat: no-repeat;
								width: 36px;
								transform: scale(0.8);
								height: 36px;
                            }
							&:hover {
								opacity: 60%
							}
							&.added-to-comparison-table {
                                &:after {
									background-image: url('/assets/images/theme/ic_check_blue.svg');
                                    width: 15px;
                                    height: 9px;
                                    margin: 11px auto;
                                    transform: scale(1.4);
                                }
								background-size: 16px 16px;
							}
							.label {
								display: block;
								order:2;
								font-size: 14px;
								color: $color-14;
								text-wrap: nowrap;
								line-height: normal;
							}
						}
					}
				}
				.mailto {
					display: none;
				}
			}
			& > .product.pricing {
				font-weight: 400;
			}
			.product-short-description {
				.product.attribute.overview {
					margin-top: 19px;
					margin-bottom: 22px;
					.value {
						& > div,
						& > p {
							min-height: 26px;
						}
					}
				}
			}
		}
	}
	.product.info.detailed {
		& > .items {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			.item {
				flex: 0 1 auto;
				&:nth-child(odd) {
					order: 1;
				}
				&:nth-child(even) {
					order: 2;
					margin-left: 0px;
					flex: 0 1 100%;
					max-width: 100%;
				}
				&.title {
					a {
						display: inline-block !important;
						border: 0px !important;
						font-size: 16px !important;
						line-height: 16px !important;
						font-weight: 600 !important;
						color: $color-13 !important;
						padding: 0px !important;
						padding-bottom: 15px !important;
						padding-left: 28px !important;
						padding-right: 28px !important;
						margin: 0px !important;
						height: auto !important;
						text-transform: uppercase !important;
						background-color: transparent !important;
						@include transition(all $default_transition_time);
						strong {
							font-weight: 600 !important;
						}
						&:first-child {
							padding-left: 0px !important;
						}
						&:after {
							display: none !important;
						}
					}
					&:hover,
					&.active {
						a {
							color: $color-3 !important;
						}
					}
				}
				&.content {
					border-top: 1px solid $color-8 !important;
					border-bottom: 1px solid $color-8 !important;
					padding: 0px !important;
					padding-top: 34px !important;
					padding-bottom: 34px !important;
					margin-top: 0px !important;
					position: relative;
					.description {
						.value {
							& > div,
							& > p {
								min-height: 26px;
							}
						}
						& + .amasty-product-attachment-block {
							margin-top: 20px;
						}
					}
					&#additional {
						.additional-attributes-wrapper {
							margin-top: -20px;
							table {
								width: 700px;
								tbody {
									tr {
										th {
											border-bottom: 1px solid $color-12;
											padding-right: 10px;
											padding-top: 19px;
											padding-bottom: 0px;
											width: 50%;
										}
										td {
											border-bottom: 1px solid $color-12;
											padding-left: 10px;
											padding-top: 19px;
											padding-bottom: 0px;
											width: 50%;
										}
									}
								}
							}
						}
					}
					&#reviews {
						.review-add {
							margin-bottom: 0px;
							.block-content {
								.top-toolbar {
									border-bottom: 1px solid $color-12;
									padding-bottom: 30px;
									.arrowed-link-style {
										float: right;
									}
								}
								.form-block {
									border-bottom: 1px solid $color-12;
									display: none;
									padding-top: 13px;
									padding-bottom: 35px;
									.review-form {
										max-width: 700px;
										margin-left: auto;
										margin-right: auto;
										.review-fieldset {
											margin-bottom: 0px;
											.review-legend {
												display: none;
											}
										}
										.actions-toolbar {
											margin-top: 40px;
											.actions-primary {
												float: none;
												display: block;
											}
										}
										.review-field-ratings {
											.control {
												.nested {
													.review-field-rating {
														.control {
															margin-top: 0px;
															margin-bottom: 13px;
															margin-left: -7px;
															&.review-control-vote {
																label {
																	&:before {
																		color: $color-3;
																	}
																}
															}
														}
													}
												}
											}
										}
									}
									&.active {
										display: block;
									}
								}
							}
						}
						#product-review-container {
							&.loading {
								height: 200px;
								overflow: hidden;
							}
						}
						&.empty {
							.review-add {
								.block-content {
									.top-toolbar {
										border-bottom: 0px;
										padding-bottom: 0px;
									}
									.form-block {
										border-bottom: 0px;
									}
								}
							}
						}
					}
					&#amfaq_product {
						.amfaq-product-tab {
							.block-title {
								display: none;
							}
							.amfaq_questions {
								max-width: 700px;
								margin-left: auto;
								margin-right: auto;
								.am-item {
									margin-bottom: 0px;
									border-bottom: 1px solid $color-12;
									.am-title {
										position: relative;
										padding-right: 40px;
										font-size: 16px;
										font-weight: 800;
										border-bottom: 1px solid $color-12;
										margin-bottom: -1px;
										padding-top: 24px;
										span {
											width: 20px;
											height: 20px;
											float: none;
											display: block;
											position: absolute;
											top: 19px;
											right: 0px;
											background-repeat: no-repeat;
											background-position: center center;
											&:before {
												display: none;
											}
											&.am-minus {
												@include svg-icon("ic_plus_red");
											}
											&.am-plus {
												@include svg-icon("ic_minus_red");
											}
										}
										&:hover {
											color: $color-3;
										}
									}
									.am-content {
										padding-top: 17px;
										p {
											margin-bottom: inherit;
										}
										.amfaq-question-footer-wrapper {
											.amfaq-rating-yesno {
												color: $color-8;
												margin-top: 10px;
												.amfaq-was-it-helpful {
													font-size: 12px;
													line-height: 14px;
													font-weight: 300;
													color: $color-2;
												}
												.amfaq-rating-yesno-vote {
													font-size: 12px;
													line-height: 14px;
													font-weight: 300;
													color: $color-3;
													padding-left: 12px;
													padding-right: 12px;
													&.yesno-voted {
														font-weight: 800;
													}
												}
												.amfaq-rating-data-wrapper {
													font-size: 12px;
													line-height: 14px;
													font-weight: 300;
													color: $color-8;
													padding-left: 7px;
												}
											}
										}
									}
								}
							}
							.ask-question-form {
								max-width: 700px;
								margin-left: auto;
								margin-right: auto;
								margin-top: 20px;
								margin-bottom: -15px;
								.button-container {
									text-align: center;
									margin: 0px;
									.button-info {
										font-size: 16px;
										font-weight: 800;
										float: none;
										margin: 0px;
										vertical-align: top;
										margin-top: 5px;
										margin-bottom: 5px;
									}
									a {
										margin-left: 5px;
										margin-top: 5px;
										margin-bottom: 5px;
									}
								}
								.form-block {
									margin-top: 32px;
									textarea {
										min-height: 100px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.text-style {
		font-weight: 400;
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.open-product-page-block {
		.product-gallery-details-wrapper {
			.left {
				float: none;
				margin-left: auto;
				margin-right: auto;
			}
			.right {
				float: none;
				width: auto;
				margin-bottom: 70px;
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.open-product-page-block {
		.product.info.detailed {
			.items[role="tablist"] {
				.item {
					&.content {
						&#additional {
							.additional-attributes-wrapper {
								table {
									width: auto;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.open-product-page-block {
		.product-gallery-details-wrapper {
			.left {
				width: auto;
			}
			.right {
				.product-info-main {
					.product-info-price {
						.product-reviews-summary {
							.reviews-actions {
								.action {
									display: block !important;
									margin-right: 0px !important;
									margin-top: 10px;
									margin-bottom: 10px;
									&.view {
										padding-right: 0px;
										span {
											&:before {
												display: none;
											}
										}
									}
									&:first-child {
										margin-top: 0px;
									}
									&:last-child {
										margin-bottom: 0px;
									}
								}
							}
						}
					}
					.product-delivery-info {
						display: block;
						padding-top: 0px;
						padding-bottom: 0px;
						.delivery-info-item {
							padding-left: 0px;
							padding-right: 0px;
							.inner-wrapper {
								padding-left: 0px;
								padding-right: 0px;
							}
							&.pickup {
								& + .delivery {
									border-top: 1px solid $color-12;
								}
							}
							&.delivery {
								.inner-wrapper {
									&:before {
										border-left: 0px;
									}
								}
							}
						}
					}
					.product-add-form {
						.box-tocart {
							.fieldset {
								.elegant-wrapper-block {
									i {
										margin-right: 10px;
									}
									.field {
										margin-right: 10px;
										.control {
											input {
												width: 44px !important;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.product.info.detailed {
			.items[role="tablist"] {
				.item {
					&.content {
						&#reviews {
							.review-add {
								.block-content {
									.top-toolbar {
										padding-top: 40px;
										padding-bottom: 18px;
										text-align: center;
										.arrowed-link-style {
											float: none;
										}
									}
								}
							}
						}
						&#additional {
							.additional-attributes-wrapper {
								table {
									display: block;
									tbody {
										display: block;
										tr {
											display: block;
											th,
											td {
												display: block;
												width: auto;
												padding: 0px;
											}
											th {
												border-bottom: 0px;
												display: none;
											}
											td {
												padding-bottom: 10px;
												margin-bottom: 10px;
												&:before {
													content: attr(data-th) ': ';
													display: inline-block;
													padding-right: 10px;
													font-weight: 800;
												}
											}
										}
									}
								}
							}
						}
						&#amfaq_product {
							.amfaq-product-tab {
								.amfaq_questions {
									.am-item {
										.am-content {
											.amfaq-question-footer-wrapper {
												.amfaq-rating-yesno {
													.amfaq-rating-data-wrapper {
														display: block;
														padding-left: 0px;
														padding-bottom: 2px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.open-product-page-block {
		.product-gallery-details-wrapper {
			.right {
				.product-info-main {
					.product-info-price {
						margin: 0;
						.price-outer-wrapper {
							display: block;
							.prices-tier-wrapper {
								margin-right: auto;
							}
							.price-outer-wrapper-right {
								.prices-tier-wrapper {
									margin-top: 20px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 350px) {
	.open-product-page-block {
		.product-gallery-details-wrapper {
			.right {
				.product-info-main {
					.product-info-price {
						.price-outer-wrapper {
							.price-box {
								.price-unit {
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
}
