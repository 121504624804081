.slider-block {
	overflow: hidden;
	.slider-holder {
		z-index: 1; /*IE9 fix*/
		height: 100%;
		.slick-list {
			height: 100%;
			.slick-track {
				height: 100%; /* this disables adaptive height */
			}
		}
		.slider-item {
			height: 100%;
			outline: 0;
			position: relative;
			cursor: default;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			color: $color-1;
			a {
				display: block;
				position: relative;
				width: 100%;
				height: 100%;
				.text-overlay {
					position: absolute;
					z-index: 1;
					right: 0px;
					bottom: 55px;
					.discount-block {
						float: right;
					}
					.major-title {
						background-color: $color-3;
						color: $color-1;
						font-size: 32px;
						font-weight: 800;
						float: right;
						clear: right;
						padding-top: 24px;
						padding-bottom: 26px;
						padding-left: 42px;
						padding-right: 42px;
					}
				}
			}
		}
		.slick-arrow {
			position: absolute;
			z-index: 2;
			bottom: 10px;
			width: 40px;
			height: 40px;
			background-repeat: no-repeat;
			background-position: center center;
			overflow: hidden;
			text-indent: -9999px;
			border-radius: 50%;
			background-color: transparent;
			@include transition(transform $default_transition_time);
			&.slick-prev {
				@include svg-icon("ic_slider_nav_left_red");
				left: 10px;
			}
			&.slick-next {
				@include svg-icon("ic_slider_nav_right_red");
				left: 67px;
			}
		}
	}
	&.promo-slider {
		height: 470px;
		margin-bottom: 16px;
		&:before {
			background-color: $color-3;
			background-image: getInlinedImage("ic_loading_white_on_red.gif");
		}
	}
}

@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
	.slider-block {
		.slider-holder {
			.slick-arrow {
				&.slick-prev {
					&:hover {
						@include transform(translateX(-2px));
						@include svg-icon("ic_slider_nav_left_full_red");
					}
				}
				&.slick-next {
					&:hover {
						@include transform(translateX(2px));
						@include svg-icon("ic_slider_nav_right_full_red");
					}
				}
			}
		}
	}
}

@media screen and (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.slider-block {
		.slider-holder {
			.slider-item {
				a {
					.text-overlay {
						.major-title {
							font-size: 18px;
							line-height: 20px;
							padding-top: 14px;
							padding-bottom: 16px;
							padding-left: 22px;
							padding-right: 22px;
						}
					}
				}
			}
			.slick-arrow {
				&.slick-prev {
					&:active {
						@include transform(translateX(-2px));
						@include svg-icon("ic_slider_nav_left_full_red");
					}
				}
				&.slick-next {
					&:active {
						@include transform(translateX(2px));
						@include svg-icon("ic_slider_nav_right_full_red");
					}
				}
			}
		}
		&.promo-slider {
			height: 300px;
			margin-left: -10px;
			margin-right: -10px;
		}
	}
}


