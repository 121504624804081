.ultra-mega-menu-block {
	& > .block {
		margin-bottom: 0px;
	}
	.dropdown-block {
		border-bottom: 1px solid $color-8;
	}
	.block-content {
		visibility: hidden;
		@include opacity(0);
		@include transition(all $default_transition_time);
		pointer-events: none;
		position: absolute;
		top: 51px;
		left: 0px;
		right: 0px;
		.back {
			display: none;
		}
		& > .navi {
			height: 100%;
			& > ul {
				width: 240px;
				border: 1px solid $color-8;
				border-top: 0px;
				border-bottom: 0px;
				background-color: $color-1;
				&:before {
					content: "";
					display: block;
					position: absolute;
					z-index: 2;
					bottom: 0px;
					left: 0px;
					width: 240px;
					border-bottom: 1px solid $color-8;
				}
				& > li {
					@include transition(all $default_transition_time);
					margin-top: -1px;
					margin-left: -1px;
					margin-right: -1px;
					& > a {
						background-color: $color-1;
						font-size: 12px;
						line-height: 16px;
						font-weight: 600;
						color: $color-13;
						padding-top: 15px;
						padding-bottom: 15px;
						padding-left: 38px;
						padding-right: 36px;
						border: 1px solid $color-8;
						border-top: 1px solid $color-12;
						position: relative;
						@include transition(none);
						& > .icon-wrapper {
							position: absolute;
							top: 6px;
							left: 5px;
							width: 32px;
							height: 32px;
							text-align: center;
							line-height: 32px;
							i {
								width: 32px;
								height: 32px;
								background-size: 100% auto;
								display: inline-block;
								vertical-align: middle;
							}
							&.hover {
								visibility: hidden;
								@include opacity(0);
							}
						}
						span {
							white-space: normal;
						}
						.caret {
							font-size: 19px;
							@include opacity(100);
							width: 36px;
							height: 36px;
							line-height: 36px;
							position: absolute;
							top: 50%;
							right: 0px;
							@include transform(translateY(-50%));
							text-align: center;
						}
					}
					& > .submenu-outer-wrapper {
						position: absolute;
						top: 0px;
						left: 239px !important;
						padding-top: 29px;
						padding-bottom: 29px;
						padding-left: 35px;
						padding-right: 35px;
						background-color: $color-1;
						border: 1px solid $color-8;
						display: none;
						& > .submenu-inner-wrapper {
							& > .submenu-holder {
								& > ul { // 2nd level
									display: flex !important;
									position: static;
									flex-direction: column;
									flex-wrap: wrap;
									max-height: 551px;
									& > li {
										@include transition(all $default_transition_time);
										position: static;
										padding-left: 20px;
										padding-right: 20px;
										& > a {
											background-color: $color-1;
											font-size: 12px;
											line-height: 16px;
											font-weight: 600;
											color: $color-9;
											position: relative;
											padding-top: 11px;
											padding-bottom: 11px;
											padding-left: 10px;
											.icon-wrapper {
												display: none;
											}
											.caret {
												position: absolute;
												@include opacity(100);
                                                width: 6px;
                                                height: 8px;
												top: 50%;
												@include transform(translateY(-50%));
												left: -3px;
												text-align: left;
												margin: 0px;
												margin-top: -2px;

												&:before {
												    display: none;
												}
											}
										}
										& > .submenu-outer-wrapper {
											display: none;
											& > .submenu-inner-wrapper {
												& > .submenu-holder {
													& > ul { // 3rd level
														display: block !important;
														position: static;
														margin-bottom: 5px;
														& > li {
															@include transition(all $default_transition_time);
															margin-top: -1px;
															position: static;
															& > a {
																font-size: 12px;
																line-height: 16px;
																font-weight: 600;
																color: $color-13;
																padding-top: 7px;
																padding-bottom: 7px;
																padding-left: 25px;
																color: $color-21;
																.icon-wrapper {
																	display: none;
																}
															}
															& > .submenu-outer-wrapper {
																display: none;
																& > .submenu-inner-wrapper {
																	& > .submenu-holder {
																		& > ul { // 4th level
																			display: block !important;
																			position: static;
																			margin-bottom: 5px;
																			& > li {
																				@include transition(all $default_transition_time);
																				margin-top: -1px;
																				position: static;
																				& > a {
																					font-size: 12px;
																					line-height: 16px;
																					font-weight: 600;
																					color: $color-13;
																					padding-top: 7px;
																					padding-bottom: 7px;
																					padding-left: 25px;
																					color: $color-21;
																					.icon-wrapper {
																						display: none;
																					}
																				}
																				& > .submenu-outer-wrapper {
																					display: none;
																					& > .submenu-inner-wrapper {
																						& > .submenu-holder {
																							& > ul { // 5th level
																								display: block !important;
																								position: static;
																								margin-bottom: 5px;
																								& > li {
																									@include transition(all $default_transition_time);
																									margin-top: -1px;
																									position: static;
																									& > a {
																										font-size: 12px;
																										line-height: 16px;
																										font-weight: 600;
																										color: $color-13;
																										padding-top: 7px;
																										padding-bottom: 7px;
																										padding-left: 25px;
																										color: $color-21;
																										.icon-wrapper {
																											display: none;
																										}
																									}
																									& > .submenu-outer-wrapper {
																										display: none;
																										& > .submenu-inner-wrapper {
																											& > .submenu-holder {
																												& > ul { // 6th level
																													display: block !important;
																													position: static;
																													margin-bottom: 5px;
																													& > li {
																														@include transition(all $default_transition_time);
																														margin-top: -1px;
																														position: static;
																														& > a {
																															font-size: 12px;
																															line-height: 16px;
																															font-weight: 600;
																															color: $color-13;
																															padding-top: 7px;
																															padding-bottom: 7px;
																															padding-left: 25px;
																															color: $color-21;
																															.icon-wrapper {
																																display: none;
																															}
																														}
																														& > .submenu-outer-wrapper {
																															display: none;
																															& > .submenu-inner-wrapper {
																																& > .submenu-holder {
																																	& > ul { // 7th level
																																		display: block !important;
																																		position: static;
																																		margin-bottom: 5px;
																																		& > li {
																																			@include transition(all $default_transition_time);
																																			margin-top: -1px;
																																			position: static;
																																			& > a {
																																				font-size: 12px;
																																				line-height: 16px;
																																				font-weight: 600;
																																				color: $color-13;
																																				padding-top: 7px;
																																				padding-bottom: 7px;
																																				padding-left: 25px;
																																				color: $color-21;
																																				.icon-wrapper {
																																					display: none;
																																				}
																																			}
																																			& > .submenu-outer-wrapper {
																																				display: none;
																																				& > .submenu-inner-wrapper {
																																					& > .submenu-holder {
																																						& > ul { // 8th level
																																							& > li {
																																								& > a {
																																									.caret {
																																										display: none;
																																									}
																																								}
																																							}

																																						}
																																					}
																																				}
																																			}
																																		}
																																	}
																																}
																															}
																														}
																													}
																												}
																											}
																										}
																									}
																								}
																							}
																						}
																					}
																				}
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
										&:first-child {
											& > a {
												border-top-color: $color-8;
											}
										}
										&:last-child {
											& > a {
												border-bottom: 0px;
											}
										}
										&.active {
											& > a {
												.caret {
													@include transform(translateY(-50%) rotate(90deg));
												}
											}
										}
									}
								}
							}
						}
					}
					&:first-child {
						& > a {
							border-top-color: $color-8;
						}
					}
					&:last-child {
						& > a {
							border-bottom-color: $color-12;
						}
					}
					&:nth-child(13) {
						& > a {
							border-bottom-color: $color-8;
						}
					}
				}
			}
		}
	}
	&.active {
		.dropdown-block {
			span {
				&:before {
					@include transform(translateY(-50%) rotate(90deg) scale(1.1));
				}
			}
		}
		.block-content {
			visibility: visible;
			@include opacity(100);
			pointer-events: all;
			@include transform(translateY(0px));
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
	.ultra-mega-menu-block {
		position: relative;
		.block-content {
			@include transition(opacity $default_transition_time);
			& > .navi {
				& > ul {
					& > li {
						& > .submenu-outer-wrapper {
							& > .submenu-inner-wrapper {
								& > .submenu-holder {
									& > ul { // 2nd level
										& > li {
											width: 223px;
											& > .submenu-outer-wrapper {
												& > .submenu-inner-wrapper {
													& > .submenu-holder {
														& > ul { // 3nd level
															& > li {
																&:hover,
																&.active {
																	& > a {
																		color: $color-3;
																		text-decoration: underline;
																	}
																}
															}
														}
													}
												}
											}
											& > a {
												&:hover {
													text-decoration: underline;
												}
											}
											&:hover {
												& > a {

												}
											}
											&.active {
												& > .submenu-outer-wrapper {
													display: block;
												}
											}
										}
									}
								}
							}
						}
						&:hover {
							& > a {
								border-color: $color-8;
								.caret {
									visibility: hidden;
								}
							}
							& > .submenu-outer-wrapper {
								display: block;
							}
						}
						&.nav-item--only-subcategories {
							&:hover {
								& > a {
									border-right-color: $color-1;
								}
							}
						}
					}
				}
			}
			&:hover {
				z-index: 2;
			}
		}
		&:hover {
			.dropdown-block {
				span {
					&:before {
						@include transform(translateY(-50%) rotate(90deg) scale(1.1));
					}
				}
			}
			.block-content {
				visibility: visible;
				@include opacity(100);
				pointer-events: all;
				@include transform(translateY(0px));
			}
		}
		&.always-active {
			.dropdown-block {
				span {
					&:before {
						@include transform(translateY(-50%) rotate(90deg) scale(1.1));
					}
				}
			}
			.block-content {
				visibility: visible;
				@include opacity(100);
				pointer-events: all;
				@include transform(translateY(0px));
			}
		}
		&.act-as-overlay {
			position: static;
			.block-content {
				left: 10px;
				right: 10px;
			}
		}
	}

	body {
		&.cms-index-index,
		&.catalog-category-view:not(.page-with-filter) {
			.ultra-mega-menu-block {
				.dropdown-block {
					span {
						&:before {
							@include transform(translateY(-50%) rotate(90deg) scale(1.1));
						}
					}
				}
				.block-content {
					visibility: visible;
					@include opacity(100);
					pointer-events: all;
					@include transform(translateY(0px));
				}
			}
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "xl")) {
	.ultra-mega-menu-block {
        // Level 1 and 2 categories that should be hidden from desktop menu
        .nav-item.level1.hide-on-desktop,
        .nav-item.level2.hide-on-desktop {
            display: none;
        }
		.block-content {
			& > .navi {
				& > ul {
					& > li {
						& > .submenu-outer-wrapper {
							& > .submenu-inner-wrapper {
								& > .submenu-holder {
									& > ul { // 2nd level
										& > li {
											width: 252px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.ultra-mega-menu-block {
        // Ensure level 1 and 2 categories with include_in_menu = 0 are still visible on mobile
        .nav-item.level1.hide-on-desktop,
        .nav-item.level2.hide-on-desktop {
            display: block;
        }

		& > .block {
			margin-bottom: 0px;
		}
		.dropdown-block {
			z-index: 0;
			span {
				display: block;
				text-align: left;
				padding-left: 11px;
				color: $color-9;
				height: 42px;
			}
			&.has-submenu {
				span {
					padding-right: 36px;
					&:before {
                        margin-right: 15px;
					}
				}
			}
		}
		.block-content {
			z-index: 1;
			top: 0;
			left: 0px;
			right: 0px;
			height: 100%;
			@include transform(translateX(-100%));
			@include opacity(100);
			.back {
				display: block;
				background-color: $color-27;
				position: relative;
				padding-left: 38px;
				padding-right: 5px;
				height: 44px;
				line-height: 44px;
				.back-btn {
					position: absolute;
					left: 3px;
					top: 50%;
					@include transform(translateY(-50%));
					width: 36px;
					height: 36px;
					@include transition(all $default_transition_time);

                    &::before {
                        content: '';
                        display: block;
                        @include svg-icon("ic_chevron_thick_left_white");
                        width: 5px;
                        height: 10px;
                        margin: 13px;
                        transform: scale(1.2);
                    }
				}
				span {
					display: inline-block;
					vertical-align: middle;
					font-size: 12px;
					line-height: 14px;
					font-weight: 800;
					color: $color-1 !important;
					text-transform: uppercase;
					margin-top: 1px;
				}
			}
			& > .navi {
				& > ul {
					border: 0px;
					width: 100%;
					overflow: auto;
					height: calc(100% - 92px);
					min-height: 0px;
					& > li {
						margin-left: 0px;
						margin-right: 0px;
						& > a {
							border-left: 0px;
							border-right: 0px;
							padding-top: 13px;
							padding-bottom: 13px;
							z-index: 1;
							&:active {
								background-color: $color-12;
							}
							.caret {
								width: 100%;
								height: 100%;
								line-height: unset;

								&::before {
									right: 10px;
									position: absolute;
									top: 50%;
									@include transform(translateY(-50%));
								}
							}
						}
						&:first-child {
							& > a {
								border-top-color: transparent;
							}
						}
						& > .submenu-outer-wrapper {
							position: absolute;
							top: -44px !important;
							bottom: 92px;
							left: 0px !important;
							right: auto !important;
							width: 100% !important;
							@include transform(translateX(-100%));
							@include transition(all $default_transition_time);
							z-index: 2;
							background-color: $color-1;
							display: block;
							padding: 0px;
							border: 0px;
							visibility: hidden;
							& > .submenu-inner-wrapper {
								border: 0px;
								height: 100%;
								& > .submenu-holder {
									height: calc(100% - 46px);
									& > ul {
										overflow: auto;
										height: 100%;
										max-height: none;
										display: block !important;
										& > li {
											margin-top: -1px;
											padding-left: 0px;
											padding-right: 0px;
											& > a {
												border-right: 0px;
												padding-top: 13px;
												padding-bottom: 13px;
												padding-left: 17px;
												padding-right: 17px;
												border-top: 1px solid $color-12;
												border-bottom: 1px solid $color-12;
												color: $color-13;
												position: relative;
												&:active {
													background-color: $color-12;
												}
												.caret {
													font-size: 19px;
													@include opacity(100);
													width: 100%;
													height: 100%;
													position: absolute;
													top: 50%;
													right: 0px;
													@include transform(translateY(-50%));
													text-align: center;

													&::before {
														right: 10px;
														position: absolute;
														top: 50%;
														@include transform(translateY(-50%));
														display: inline-block;
													}
												}
											}
											&:first-child {
												& > a {
													border-top-color: transparent;
												}
											}
											& > .submenu-outer-wrapper {
												position: absolute;
												top: 0px !important;
												bottom: 0px;
												left: 0px !important;
												right: auto !important;
												width: 100% !important;
												@include transform(translateX(-100%));
												@include transition(all $default_transition_time);
												z-index: 3;
												background-color: $color-1;
												display: block;
												visibility: hidden;
												& > .submenu-inner-wrapper {
													border: 0px;
													height: 100%;
													& > .submenu-holder {
														height: calc(100% - 46px);
														& > ul {
															overflow: auto;
															height: 100%;
															max-height: none;
															display: block !important;
															& > li {
																margin-top: -1px;
																padding-left: 0px;
																padding-right: 0px;
																& > a {
																	border-right: 0px;
																	padding-top: 13px;
																	padding-bottom: 13px;
																	padding-left: 17px;
																	padding-right: 17px;
																	border-top: 1px solid $color-12;
																	border-bottom: 1px solid $color-12;
																	color: $color-13;
																	position: relative;
																	&:active {
																		background-color: $color-12;
																	}
																	.caret {
																		font-size: 19px;
																		@include opacity(100);
																		width: 100%;
																		height: 100%;
																		position: absolute;
																		top: 50%;
																		right: 0px;
																		@include transform(translateY(-50%));
																		text-align: center;

																		&::before {
																			right: 10px;
																			position: absolute;
																			top: 50%;
																			@include transform(translateY(-50%));
																		}
																	}
																}
																&:first-child {
																	& > a {
																		border-top-color: transparent;
																	}
																}
																& > .submenu-outer-wrapper {
																	position: absolute;
																	top: 0px !important;
																	bottom: 0px;
																	left: 0px !important;
																	right: auto !important;
																	width: 100% !important;
																	@include transform(translateX(-100%));
																	@include transition(all $default_transition_time);
																	z-index: 4;
																	background-color: $color-1;
																	display: block;
																	visibility: hidden;
																	& > .submenu-inner-wrapper {
																		border: 0px;
																		height: 100%;
																		max-height: none;
																		display: block !important;
																		& > .submenu-holder {
																			height: calc(100% - 46px);
																			& > ul {
																				overflow: auto;
																				height: 100%;
																				max-height: none;
																				display: block !important;
																				position: static;
																				margin-bottom: 5px;
																				& > li {
																					@include transition(all $default_transition_time);
																					margin-top: -1px;
																					position: static;
																					padding-left: 0px;
																					padding-right: 0px;
																					& > a {
																						font-size: 12px;
																						line-height: 16px;
																						font-weight: 600;
																						border-right: 0px;
																						padding-top: 13px;
																						padding-bottom: 13px;
																						padding-left: 17px;
																						padding-right: 17px;
																						border-top: 1px solid $color-12;
																						border-bottom: 1px solid $color-12;
																						color: $color-13;
																						position: relative;
																						&:active {
																							background-color: $color-12;
																						}
																						.icon-wrapper {
																							display: none;
																						}
																						.caret {
																							font-size: 19px;
																							@include opacity(100);
																							width: 100%;
																							height: 100%;
																							position: absolute;
																							top: 50%;
																							right: 0px;
																							@include transform(translateY(-50%));
																							text-align: center;

																							&::before {
																								right: 10px;
																								position: absolute;
																								top: 50%;
																								@include transform(translateY(-50%));
																							}
																						}
																					}
																					&:first-child {
																						& > a {
																							border-top-color: transparent;
																						}
																					}
																					& > .submenu-outer-wrapper {
																						position: absolute;
																						top: 0px !important;
																						bottom: 0px;
																						left: 0px !important;
																						right: auto !important;
																						width: 100% !important;
																						@include transform(translateX(-100%));
																						@include transition(all $default_transition_time);
																						z-index: 5;
																						background-color: $color-1;
																						display: block;
																						visibility: hidden;
																						& > .submenu-inner-wrapper {
																							border: 0px;
																							height: 100%;
																							max-height: none;
																							display: block !important;
																							& > .submenu-holder {
																								height: calc(100% - 46px);
																								& > ul {
																									overflow: auto;
																									height: 100%;
																									max-height: none;
																									display: block !important;
																									position: static;
																									margin-bottom: 5px;
																									& > li {
																										@include transition(all $default_transition_time);
																										margin-top: -1px;
																										position: static;
																										padding-left: 0px;
																										padding-right: 0px;
																										& > a {
																											font-size: 12px;
																											line-height: 16px;
																											font-weight: 600;
																											border-right: 0px;
																											padding-top: 13px;
																											padding-bottom: 13px;
																											padding-left: 17px;
																											padding-right: 17px;
																											border-top: 1px solid $color-12;
																											border-bottom: 1px solid $color-12;
																											color: $color-13;
																											position: relative;
																											&:active {
																												background-color: $color-12;
																											}
																											.icon-wrapper {
																												display: none;
																											}
																											.caret {
																												font-size: 19px;
																												@include opacity(100);
																												width: 100%;
																												height: 100%;
																												position: absolute;
																												top: 50%;
																												right: 0px;
																												@include transform(translateY(-50%));
																												text-align: center;

																												&::before {
																													right: 10px;
																													position: absolute;
																													top: 50%;
																													@include transform(translateY(-50%));
																												}
																											}
																										}
																										&:first-child {
																											& > a {
																												border-top-color: transparent;
																											}
																										}
																										& > .submenu-outer-wrapper {
																											position: absolute;
																											top: 0px !important;
																											bottom: 0px;
																											left: 0px !important;
																											right: auto !important;
																											width: 100% !important;
																											@include transform(translateX(-100%));
																											@include transition(all $default_transition_time);
																											z-index: 6;
																											background-color: $color-1;
																											display: block;
																											visibility: hidden;
																											& > .submenu-inner-wrapper {
																												border: 0px;
																												height: 100%;
																												max-height: none;
																												display: block !important;
																												& > .submenu-holder {
																													height: calc(100% - 46px);
																													& > ul {
																														overflow: auto;
																														height: 100%;
																														max-height: none;
																														display: block !important;
																														position: static;
																														margin-bottom: 5px;
																														& > li {
																															@include transition(all $default_transition_time);
																															margin-top: -1px;
																															position: static;
																															padding-left: 0px;
																															padding-right: 0px;
																															& > a {
																																font-size: 12px;
																																line-height: 16px;
																																font-weight: 600;
																																border-right: 0px;
																																padding-top: 13px;
																																padding-bottom: 13px;
																																padding-left: 17px;
																																padding-right: 17px;
																																border-top: 1px solid $color-12;
																																border-bottom: 1px solid $color-12;
																																color: $color-13;
																																position: relative;
																																&:active {
																																	background-color: $color-12;
																																}
																																.icon-wrapper {
																																	display: none;
																																}
																																.caret {
																																	font-size: 19px;
																																	@include opacity(100);
																																	width: 100%;
																																	height: 100%;
																																	position: absolute;
																																	top: 50%;
																																	right: 0px;
																																	@include transform(translateY(-50%));
																																	text-align: center;

																																	&::before {
																																		right: 10px;
																																		position: absolute;
																																		top: 50%;
																																		@include transform(translateY(-50%));
																																	}
																																}
																															}
																															&:first-child {
																																& > a {
																																	border-top-color: transparent;
																																}
																															}
																															& > .submenu-outer-wrapper {
																																position: absolute;
																																top: 0px !important;
																																bottom: 0px;
																																left: 0px !important;
																																right: auto !important;
																																width: 100% !important;
																																@include transform(translateX(-100%));
																																@include transition(all $default_transition_time);
																																z-index: 7;
																																background-color: $color-1;
																																display: block;
																																visibility: hidden;
																																& > .submenu-inner-wrapper {
																																	border: 0px;
																																	height: 100%;
																																	max-height: none;
																																	display: block !important;
																																	& > .submenu-holder {
																																		height: calc(100% - 46px);
																																		& > ul {
																																			overflow: auto;
																																			height: 100%;
																																			max-height: none;
																																			display: block !important;
																																			position: static;
																																			margin-bottom: 5px;
																																			& > li {
																																				@include transition(all $default_transition_time);
																																				margin-top: -1px;
																																				position: static;
																																				padding-left: 0px;
																																				padding-right: 0px;
																																				& > a {
																																					font-size: 12px;
																																					line-height: 16px;
																																					font-weight: 600;
																																					border-right: 0px;
																																					padding-top: 13px;
																																					padding-bottom: 13px;
																																					padding-left: 17px;
																																					padding-right: 17px;
																																					border-top: 1px solid $color-12;
																																					border-bottom: 1px solid $color-12;
																																					color: $color-13;
																																					position: relative;
																																					&:active {
																																						background-color: $color-12;
																																					}
																																					.icon-wrapper {
																																						display: none;
																																					}
																																					.caret {
																																						font-size: 19px;
																																						@include opacity(100);
																																						width: 100%;
																																						height: 100%;
																																						position: absolute;
																																						top: 50%;
																																						right: 0px;
																																						@include transform(translateY(-50%));
																																						text-align: center;

																																						&::before {
																																							right: 10px;
																																							position: absolute;
																																							top: 50%;
																																							@include transform(translateY(-50%));
																																						}
																																					}
																																				}
																																				&:first-child {
																																					& > a {
																																						border-top-color: transparent;
																																					}
																																				}
																																				& > .submenu-outer-wrapper {
																																					position: absolute;
																																					top: 0px !important;
																																					bottom: 0px;
																																					left: 0px !important;
																																					right: auto !important;
																																					width: 100% !important;
																																					@include transform(translateX(-100%));
																																					@include transition(all $default_transition_time);
																																					z-index: 8;
																																					background-color: $color-1;
																																					display: block;
																																					visibility: hidden;
																																					& > .submenu-inner-wrapper {
																																						border: 0px;
																																						height: 100%;
																																						max-height: none;
																																						display: block !important;
																																						& > .submenu-holder {
																																							height: calc(100% - 46px);
																																							& > ul {
																																								overflow: auto;
																																								height: 100%;
																																								max-height: none;
																																								display: block !important;
																																								position: static;
																																								margin-bottom: 5px;
																																								& > li {
																																									@include transition(all $default_transition_time);
																																									margin-top: -1px;
																																									position: static;
																																									padding-left: 0px;
																																									padding-right: 0px;
																																									& > a {
																																										font-size: 12px;
																																										line-height: 16px;
																																										font-weight: 600;
																																										border-right: 0px;
																																										padding-top: 13px;
																																										padding-bottom: 13px;
																																										padding-left: 17px;
																																										padding-right: 17px;
																																										border-top: 1px solid $color-12;
																																										border-bottom: 1px solid $color-12;
																																										color: $color-13;
																																										position: relative;
																																										&:active {
																																											background-color: $color-12;
																																										}
																																										.icon-wrapper {
																																											display: none;
																																										}
																																										.caret {
																																											font-size: 19px;
																																											@include opacity(100);
																																											width: 100%;
																																											height: 100%;
																																											position: absolute;
																																											top: 50%;
																																											right: 0px;
																																											@include transform(translateY(-50%));
																																											text-align: center;

																																											&::before {
																																												right: 10px;
																																												position: absolute;
																																												top: 50%;
																																												@include transform(translateY(-50%));
																																											}
																																										}
																																									}
																																									&:first-child {
																																										& > a {
																																											border-top-color: transparent;
																																										}
																																									}
																																									& > .submenu-outer-wrapper {
																																										position: absolute;
																																										top: 0px !important;
																																										bottom: 0px;
																																										left: 0px !important;
																																										right: auto !important;
																																										width: 100% !important;
																																										@include transform(translateX(-100%));
																																										@include transition(all $default_transition_time);
																																										z-index: 9;
																																										background-color: $color-1;
																																										display: block;
																																										visibility: hidden;
																																										& > .submenu-inner-wrapper {
																																											border: 0px;
																																											height: 100%;
																																											max-height: none;
																																											display: block !important;
																																											& > .submenu-holder {
																																												height: calc(100% - 46px);
																																												& > ul {
																																													overflow: auto;
																																													height: 100%;
																																													max-height: none;
																																													display: block !important;
																																													position: static;
																																													margin-bottom: 5px;
																																													& > li {
																																														@include transition(all $default_transition_time);
																																														margin-top: -1px;
																																														position: static;
																																														padding-left: 0px;
																																														padding-right: 0px;
																																														& > a {
																																															font-size: 12px;
																																															line-height: 16px;
																																															font-weight: 600;
																																															border-right: 0px;
																																															padding-top: 13px;
																																															padding-bottom: 13px;
																																															padding-left: 17px;
																																															padding-right: 17px;
																																															border-top: 1px solid $color-12;
																																															border-bottom: 1px solid $color-12;
																																															color: $color-13;
																																															position: relative;
																																															&:active {
																																																background-color: $color-12;
																																															}
																																															.icon-wrapper {
																																																display: none;
																																															}
																																															.caret {
																																																font-size: 19px;
																																																@include opacity(100);
																																																width: 100%;
																																																height: 100%;
																																																position: absolute;
																																																top: 50%;
																																																right: 0px;
																																																@include transform(translateY(-50%));
																																																text-align: center;

																																																&::before {
																																																	right: 10px;
																																																	position: absolute;
																																																	top: 50%;
																																																	@include transform(translateY(-50%));
																																																}
																																															}
																																														}
																																														&:first-child {
																																															& > a {
																																																border-top-color: transparent;
																																															}
																																														}
																																													}
																																												}
																																											}
																																										}
																																									}
																																									&.active {
																																										& > .submenu-outer-wrapper {
																																											visibility: visible;
																																											@include transform(translateX(0%));
																																										}
																																									}
																																								}
																																							}
																																						}
																																					}
																																				}
																																				&.active {
																																					& > .submenu-outer-wrapper {
																																						visibility: visible;
																																						@include transform(translateX(0%));
																																					}
																																				}
																																			}
																																		}
																																	}
																																}
																															}
																															&.active {
																																& > .submenu-outer-wrapper {
																																	visibility: visible;
																																	@include transform(translateX(0%));
																																}
																															}
																														}
																													}
																												}
																											}
																										}
																										&.active {
																											& > .submenu-outer-wrapper {
																												visibility: visible;
																												@include transform(translateX(0%));
																											}
																										}
																									}
																								}
																							}
																						}
																					}
																					&.active {
																						& > .submenu-outer-wrapper {
																							visibility: visible;
																							@include transform(translateX(0%));
																						}
																					}
																				}
																			}
																		}
																	}
																}
																&.active {
																	& > .submenu-outer-wrapper {
																		visibility: visible;
																		@include transform(translateX(0%));
																	}
																}
															}
														}
													}
												}
											}
											&.active {
												& > .submenu-outer-wrapper {
													visibility: visible;
													@include transform(translateX(0%));
												}
											}
										}
									}
								}
							}
						}
						&.active {
							& > .submenu-outer-wrapper {
								visibility: visible;
								@include transform(translateX(0%));
							}
						}
					}
				}
			}
		}
		&.active {
			.dropdown-block {
				span {
					&:before {
						@include transform(translateY(-50%) rotate(90deg) scale(1.1));
					}
				}
			}
			.block-content {
				@include transform(translateX(0px));
			}
		}
	}
}
