.mini-contact-info-block {
	a {
		font-size: 13px;
		line-height: 14px;
		color: $color-9 !important;
		float: left;
		margin-right: 30px;
		i {
			margin-right: 5px;
		}
		&:first-child {
			i {
				margin-top: -2px;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}
