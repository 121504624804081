.my-orders-block {
	.table-block {
		padding-top: 0px;
		table {
			thead {
				tr {
					th {
						width: auto;
						white-space: nowrap;
						background-color: $color-14;
					}
				}
			}
			tbody {
				tr {
					td {
						width: auto;
					}
				}
			}
		}
	}
}

.order-products-toolbar.top {
	.control {
		&:before {
			display: none;
		}
		.pager .pages, .pager .limiter {
			display: none;
		}

		.toolbar-amount {
			font-size: 16px;
			display: inline-block !important;
		}
	}
}
.order-products-toolbar.bottom {
	.toolbar-amount {
		display: none;
	}
}

#my-orders-table {
	ul.pages-items {
		li {
			&:before {
				display: none;
			}
		}
	}
}

@media (max-width: 639px) {
	.my-orders-block {
		.table-block {
			table {
				tbody {
					tr {
						border-bottom: 1px solid $color-8;
						td {
							background-color: transparent !important;
						}
					}
				}
			}
		}
	}
}