.blog-page-block {
	position: relative;
	z-index: 1;
	.two-columns-right {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		section.content {
			padding-right: 0px !important;
			width: auto !important;
			float: none !important;
			flex: 1 1 760px;
			max-width: 760px;
		}
		aside.right {
			width: auto !important;
			float: none !important;
			flex: 1 1 372px;
			max-width: 372px;
			margin-left: auto;
			.desktop-block {
				margin-bottom: 0px;
			}
		}
		&.mobile {
			.swipe-me {
				display: none;
			}
		}
	}
	.block-title {
        padding-bottom: 5px;
        padding-top: 5px;
	}
	.amblog-post {
        .am-content {
            word-wrap: break-word;
        }
        span {
            &.am-tags,
            &.views,
            &.categories {
                float: left;
                margin-right: 20px;
            }
        }

		.am-header {
			order: 2;
			font-size: 12px;
			line-height: 14px;
			font-weight: 800;
			letter-spacing: 0.5px;
            padding: 0.5em 0;
            margin-bottom: 1em;

            i {
                display: none;
            }

            .posted-by {
                display: none;
            }

            .date {
                color: $color-13;
                float: left;
                margin-right: 20px;
            }
			.views {
				color: $color-8;
				padding-left: 23px;

                &:before {
                    content: '';
                    display: inline-block;
                    @include svg-icon("ic_eye_gray");
                    width: 16px;
                    height: 10px;
                    vertical-align: middle;
                    margin-right: 5px;
                }
			}
			.comments {
				color: $color-8;
				float: left;
				margin-left: 0px;
				a {
					display: block;
					color: $color-8;
					padding-left: 23px;
					background-repeat: no-repeat;
					background-position: left center;

					&:before {
                        content: '';
                        display: inline-block;
                        @include svg-icon("ic_comment_gray");
                        width: 15px;
                        height: 12px;
                        vertical-align: middle;
                        margin-right: 5px;
					}
				}
			}
		}
	}
	#amblog_content_list {
		padding-top: 10px;
		padding-bottom: 70px;
		.amblog-container {
			.am-blog-head {
				display: none;
			}
			.amblog-list {
				.amblog-post {
					display: flex;
					flex-direction: column;
					border-bottom: 1px solid $color-8;
					margin-bottom: 44px;
					.thumbnail-container {
						order: 1;
						display: flex;
						flex-direction: column;
						.post-thumbnail {
							order: 2;
							margin-bottom: 11px;
							display: block;
							a {
								display: block;
								img {
									display: block;
								}
							}
						}
						.post-content {
							order: 1;
							width: auto;
							margin-left: 0px;
							display: block;
							.am-post-header {
								h2 {
									margin-bottom: 16px;
									a {
										color: $color-23;
									}
								}
							}
						}
						.am-details {
							display: none;
							order: 3;
						}
					}
					.am-content {
						order: 3;
						margin-bottom: 13px;
					}
				}
			}
			.pager-block {
				margin-top: -25px;
			}
		}
	}
	#amblog_sidebar_categories,
	#amblog_sidebar_tags,
	#amblog_sidebar_recent_posts {
		margin-bottom: 60px;
		.block-title {
			font-size: 16px;
			line-height: 18px;
			color: $color-13;
			text-transform: uppercase;
			strong {
				font-weight: 800;
			}
		}
	}
	#amblog_sidebar_categories {
		.amblog-categories {
			.block-content {
				padding: 0px;
				.mplog-categories {
					counter-reset: my-badass-counter;
					.amblog-category {
						padding-left: 24px;
						margin-bottom: 0px;
						position: relative;
						&:before {
							content: counter(my-badass-counter);
							counter-increment: my-badass-counter;
							position: absolute;
							top: 24px;
							left: 0px;
							color: $color-8;
							font-size: 16px;
							font-weight: 800;
							letter-spacing: 0.9px;
						}
						i {
							display: none;
						}
						a {
							display: block;
							font-size: 16px;
							font-weight: 800;
							color: $color-23;
							border-bottom: 1px solid rgba($color-8, 0.4);
							padding-top: 24px;
							padding-bottom: 26px;
							text-decoration: none;
							position: relative;
							&:before {
								content: "";
								height: 2px;
								background-color: $color-3;
								position: absolute;
								bottom: -1px;
								left: 0px;
								width: 0%;
								@include transition(all $default_transition_time);
							}
							&:hover {
								&:before {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
	#amblog_sidebar_recent_posts {
		.amblog-recentposts {
			.block-content {
				padding: 0px;
				.mbplog-recent-posts {
					padding-top: 17px;
					.amblog-recent-post {
						margin-bottom: 0px;
						position: relative;
						margin-bottom: 14px;
						&:before {
							content: "";
							display: block;
							height: 1px;
							background-color: rgba($color-8, 0.4);
							position: absolute;
							bottom: 0px;
							left: 15px;
							right: 0px;
							@include transition(all $default_transition_time);
						}
						&:after {
							content: "";
							height: 2px;
							background-color: $color-3;
							position: absolute;
							bottom: -1px;
							left: 15px;
							width: 0%;
							@include transition(all $default_transition_time);
						}
						a {
							display: flex;
							flex-direction: column;
							padding-bottom: 10px;
							text-decoration: none;
							.title {
								order: 2;
								display: block;
								padding-left: 15px;
								font-size: 16px;
								line-height: 20px;
								font-weight: 800;
								color: $color-23;
								margin-top: 0px;
								margin-bottom: 9px;
							}
							.am-recentpost-date {
								order: 1;
								font-size: 12px;
								line-height: 20px;
								font-weight: 300;
								font-style: normal;
								color: rgba($color-23, 0.4);
								margin-top: 0px;
								margin-bottom: 10px;
							}
							.am-post-thesis {
								order: 3;
								padding-left: 15px;
								margin-top: 0px;
								font-size: 14px;
								line-height: 18px;
								font-weight: 300;
								color: $color-23;
							}
						}
						&:hover {
							&:after {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
	#amblog_content_post {
		.amblog-post {
			.am-header {
				margin-bottom: 26px;
			}
		}
		.post-thumbnail {
			margin-bottom: 40px;
		}
		.am-details {
			display: none;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.blog-page-block {
		.two-columns-right {
			section.content {
				flex: 1 1 600px;
				max-width: 600px;
			}
			aside.right {
				flex: 1 1 300px;
				max-width: 300px;
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.blog-page-block {
		.two-columns-right {
			section.content {
				flex: 1 1 100%;
				max-width: 100%;
			}
			aside.right {
				margin-right: auto;
			}
			.desktop {
				section.content {
					margin-bottom: 40px;
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.blog-page-block {
		.two-columns-right {
			aside.right {
				position: static;
				display: block;
				overflow: visible;
				height: auto;
			}
		}
	}
}


.amblog-post span.am-tags,
.amblog-post span.categories,
.amblog-post span.posted-by,
.amblog-post span.date,
.amblog-post span.go-to-comments,
.amblog-post span.leave-a-comment,
.amblog-post span.comments,
.amblog-post span.rss-comments,
.amblog-post span.views {
    font-size: smaller;
}
