.share-links-block {
	float: right;
	margin-bottom: 20px;
	a {
		float: left;
		width: 53px;
		height: 53px;
		margin-right: 20px;
		background-repeat: no-repeat;
		background-position: center center;
        transition: transform .3s;
		&.facebook {
			@include svg-icon("ic_facebook_outline_red");
			&:hover {
				@include svg-icon("ic_facebook_fill_red");
			}
		}
		&.twitter {
			@include svg-icon("ic_twitter_outline_red");
			&:hover {
				@include svg-icon("ic_twitter_fill_red");
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}
