
#gdpr-notice-cookie-block {
	background-color: rgba($color-13, 0.95);
    padding: 16px;
	position: fixed;
	z-index: 102;
	top: 0;
	left: 0;
	right: 0;

	.close {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		width: 32px;
		height: 32px;
		cursor: pointer;
		@include transition(all $default_transition_time);
		&:hover {
			background-color: rgba($color-13, 1);
		}

		&:before {
            display: block;
            content: '';
            @include svg-icon("ic_cross_white");
            width: 16px;
            height: 16px;
            margin-top: 8px;
            margin-left: 8px;
		}
	}

	.content {
		.text-style {
			color: $color-1;
			h3 {
				color: $color-1;
			}
			a {
				color: $color-1;
				font-weight: 800;
			}
		}

		.actions {
			margin: 0;
			position: relative;
			top: 50%;
			@include transform(translateY(-50%));
			text-align: center;
		}
	}

    .container {
        padding: 0;
    }

    .col-md-3 {
        padding: 0;
        margin-bottom: 8px !important;
    }

    .row {
        margin: 0;
    }

    .text-style {
        strong {
            padding-right: 24px;
        }
    }

    .button {
        padding: 11px 8px;
        font-size: 12px !important;
    }
}

@media (min-width: map-get($grid-breakpoints, "md") - 1) {
    #gdpr-notice-cookie-block {
        .content {
            .actions {
                text-align: right;
            }
        }

        .button {
            padding: 14px 8px;
            font-size: 14px !important;
        }

        .col-md-3 {
            margin-bottom: 0 !important;
            padding-left: 10px;
        }
    }
}
