.blog-item-block {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	height: 414px;
	position: relative;
	overflow: hidden;
	margin-bottom: 10px;
	.panel {
		position: absolute;
		top: auto;
		bottom: 0px;
		left: 0px;
		right: 0px;
		background-color: rgba($color-14, 0.9);
		@include transition(background $default_transition_time);
		padding-top: 35px;
		padding-bottom: 47px;
		padding-left: 40px;
		padding-right: 40px;
		.title {
			font-size: 15px;
			line-height: 18px;
			font-weight: 800;
			color: $color-1;
			margin-bottom: 13px;
		}
		.description {
			position: absolute;
			left: 0px;
			right: 0px;
			padding-left: 40px;
			padding-right: 40px;
			@include transition(all $default_transition_time);
			@include opacity(0);
			.text-style {
				.mobile-text {
					display: none;
				}
			}
		}
		.bottom {
			position: absolute;
			bottom: 24px;
			left: 0px;
			right: 0px;
			padding-left: 40px;
			padding-right: 40px;
			.arrowed-link-style {
				float: left;
			}
			.side-wrapper {
				float: right;
				.post-date {
					display: none;
				}
				.socials-block {

				}
			}
		}
	}
	&:hover {
		.panel {
			//top: 0px;
			background-color: $color-14;
			.description {
				@include opacity(100);
			}
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "lg")) and (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.blog-item-block {
		.panel {
			.description {
				.text-style {
					font-size: 14px;
					line-height: 18px;
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.blog-items-wrapper {
		& > [class*="col"] {
			&:nth-child(even) {
				.blog-item-block {
					.panel {
						padding-left: 94px;
						padding-right: 10px;
						.bottom {
							padding-left: 94px;
							.side-wrapper {
								right: auto;
								left: 0px;
							}
						}
					}
				}
			}
		}
	}

	.blog-item-block {
		height: 266px;
		margin-left: -10px;
		margin-right: -10px;
		.panel {
			top: 0px !important;
			padding-top: 10px;
			padding-left: 10px;
			padding-right: 93px;
			background-color: rgba($color-14, 0.75);
			.title {
				font-size: 20px;
				line-height: 20px;
				letter-spacing: 1px;
				margin-top: 6px;
			}
			.description {
				position: static;
				@include opacity(100);
				padding-left: 0px;
				padding-right: 0px;
			}
			.bottom {
				bottom: 7px;
				padding-left: 10px;
				padding-right: 10px;
				.side-wrapper {
					position: absolute;
					bottom: -7px;
					right: 0px;
					height: 266px;
					width: 53px;
					background-color: $color-3;
					text-align: center;
					.post-date {
						display: block;
						color: $color-1;
						font-size: 12px;
						line-height: 14px;
						font-weight: 800;
						margin-top: 35px;
						margin-bottom: 53px;
						.day,
						.month {
							display: block;
						}
					}
					.socials-block {
						a {
							float: none;
							display: block;
							margin-right: 0px;
							i {
								vertical-align: middle;
							}
							&:first-child {
								margin-bottom: 56px;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.blog-item-block {
		.panel {
			.description {
				.text-style {
					.web-text {
						display: none;
					}
					.mobile-text {
						display: block;
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.blog-item-block {
		height: 166px;
		.panel {
			.description {
				.text-style {
					line-height: 12px;
				}
			}
			.bottom {
				.side-wrapper {
					height: 166px;
					.post-date {
						margin-top: 18px;
						margin-bottom: 25px;
					}
					.socials-block {
						a {
							&:first-child {
								margin-bottom: 21px;
							}
						}
					}
				}
			}
		}
	}
}
