.footer-block {
	.top {
		background-color: $color-12;
	}
	.bottom {
		background-color: $color-3;
		color: $color-1;
		padding-top: 15px;
		padding-bottom: 15px;
		a {
			color: $color-1;
		}
		.row {
			& > [class*="col"] {
				&:first-child {
					line-height: 29px;
					.text-style-small-footer-text {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}
	}
	.footer-menu-block-holder {
		position: relative;
		.footer-menu-block {
			width: 33.33%;
			float: left;
			&:first-child {
				&:before {
					display: none;
				}
			}
		}
	}
	.partners-block {
		margin-top: 38px;
		margin-bottom: 4px;
	}
	.footing-wrapper {
		float: right;
	}
	.socials-block {
		margin-top: 3px;
		float: left;
	}
	.developer-block {
		margin-top: 5px;
		margin-left: 23px;
		float: left;
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.footer-block {
		.footer-menu-block-holder {
			.footer-menu-block {
				padding-left: 40px;
				padding-right: 40px;
				&:before {
					@include transform(translateX(-43px));
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.footer-block {
		.bottom {
			text-align: center;
			padding-bottom: 5px;
			.row {
				& > [class*="col"] {
					&:first-child {
						line-height: 1;
						margin-bottom: 10px;
					}
				}
			}
		}
		.footer-menu-block-holder {
			.footer-menu-block {
				float: none;
				width: auto;
				padding-left: 0px;
				padding-right: 0px;
				&:before {
					display: none;
				}
			}
		}
		.footing-wrapper {
			float: none;
			display: inline-block;
		}
		.logo-block {
			img {
				margin-top: 0px;
			}
		}
		.partners-block {
			margin-top: 25px;
			margin-bottom: 25px;
		}
	}
}
