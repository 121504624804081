.subscribe-block {
	max-width: 860px;
	margin-left: auto;
	margin-right: auto;
	table {
		width: 100%;
		tbody {
			tr {
				td {
					padding: 0px;
					&:first-child {
						width: 100%;
					}
				}
			}
		}
	}
	h2 {
		color: $color-3;
	}
	.actions {
		margin-left: 20px;
	}
	.field.choice {
		label {
			color: $color-2;
			font-size: 12px;
			line-height: 14px;
			font-weight: 300;
			letter-spacing: 0.5px;
			padding-top: 2px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.subscribe-block {
		table {
			display: block;
			tbody {
				display: block;
				tr {
					display: block;
					td {
						display: block;
					}
				}
			}
		}
		.actions {
			margin-top: 20px;
			margin-left: 0px;
			text-align: center;
		}
	}
}