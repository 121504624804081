.text-style {
	&-small-footer-text {
		font-size: 12px;
		line-height: 14px;
		font-weight: 300;
		letter-spacing: 0.5px;
	}
	&-main-menu {
		font-size: 14px;
		line-height: 20px;
		font-weight: 800;
	}
	&-product-heading {
		font-size: 12px;
		line-height: 18px;
		font-weight: 800;
	}
	&-links-in-bold {
		a {
			font-size: 14px;
			line-height: 20px;
			font-weight: 800;
		}
	}
	&.style-white {
		color: $color-1;
	}
}
h1 {
	font-size: 44px;
	line-height: 52px;
	font-weight: 600;
	color: $color-23;
}
h2 {
	font-size: 30px;
	line-height: 36px;
	font-weight: 800;
	color: $color-13;
	letter-spacing: 1.5px;
	margin-bottom: 28px;
	&.highlighted {
		padding-top: 12px;
		padding-bottom: 12px;
		padding-left: 26px;
		border-left: 20px solid $color-3;
		color: $color-3;
	}
	&.content-title {
		text-transform: uppercase;
		margin-bottom: 23px;
	}
}
h3 {
	font-size: 24px;
	line-height: 1.15;
	font-weight: 800;
	letter-spacing: 1px;
	color: $color-13;
	margin-bottom: 13px;
}
h4 {
	font-size: 16px;
	line-height: 26px;
	font-weight: 800;
	color: $color-13;
	margin-bottom: 16px;
}
.text-style,
.cke_editable_themed,
#tinymce {
	font-family: $default_font_stack;
	font-size: $default_font_size;
	line-height: $default_line_height;
	font-weight: 300;
	color: $color-13;
	p {
		margin-bottom: 20px;
	}
	.subheading-block {
		text-align: center;
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		h3 {
			color: $color-3;
			margin-bottom: 17px;
		}
	}
	.share-links-block-wrapper {
		.share-links-block {
			float: right;
			margin-bottom: -16px;
		}
	}
	hr {
		border-top: 1px solid $color-8;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	b {
		font-weight: 800;
		color: $color-13;
	}
	.image-block {
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 22px;
		img {
			display: block;
		}
		.meta {
			font-size: 12px;
			line-height: 1.15;
			color: $color-8;
			margin-top: 6px;
			b {
				color: $color-8;
			}
		}
	}
	.blockquote-block {
		border-top: 1px solid $color-8;
		border-bottom: 1px solid $color-8;
		max-width: 664px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 31px;
		blockquote {
			font-size: 24px;
			line-height: 38px;
			font-weight: 800;
			font-style: normal;
			color: $color-13;
			margin: 0px;
			padding: 0px;
			padding-top: 27px;
			padding-left: 50px;
			border: 0px;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				display: block;
				top: 7px;
				left: 0px;
				width: 34px;
				height: 34px;
				background-repeat: no-repeat;
				background-position: center center;
				@include svg-icon("ic_quotes_red");
			}
		}
		.meta {
			font-size: 12px;
			line-height: 1.15;
			color: $color-8;
			text-align: right;
			margin-top: 9px;
			margin-bottom: 2px;
			b {
				color: $color-8;
			}
		}
	}
	.highlighted-ol-block {
		border-top: 1px solid $color-8;
		border-bottom: 1px solid $color-8;
		max-width: 728px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 28px;
		color: $color-13;
		margin-bottom: 38px;
		ol {
			li {
				padding-top: 3px;
				padding-left: 80px;
				&:before {
					content: counter(my-badass-counter);
					width: 30px;
					height: 30px;
					border-radius: 50%;
					border: 2px solid $color-3;
					line-height: 28px;
					text-align: center;
					left: 30px;
				}
			}
		}
	}
	ul {
		list-style: none;
		margin-left: 0px;
		margin-bottom: 20px;
		li {
			padding-left: 24px;
			position: relative;
			margin-bottom: 3px;
			&:before {
				content: "";
				position: absolute;
				top: 9px;
				left: 0px;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: $color-3;
			}
		}
	}
	ol {
		list-style: none;
		margin-left: 0px;
		margin-bottom: 20px;
		counter-reset: my-badass-counter;
		li {
			position: relative;
			padding-left: 24px;
			margin-bottom: 3px;
			&:before {
				content: counter(my-badass-counter)".";
				counter-increment: my-badass-counter;
				position: absolute;
				top: 0px;
				left: 0px;
				color: $color-3;
				font-weight: 800;
			}
		}
	}
	dl {
		dd {
			margin-bottom: 5px;
		}
		dl {
			margin-left: 25px;
		}
	}
	table {
		width: 100%;
		tr {
			th {
				padding-top: 8px;
				padding-bottom: 8px;
				padding-left: 10px;
				padding-right: 10px;
				border-bottom: 3px solid $color-8;
				font-weight: 800;
			}
			td {
				padding-top: 8px;
				padding-bottom: 8px;
				padding-left: 10px;
				padding-right: 10px;
				border-top: 1px solid $color-8;
				border-bottom: 1px solid $color-8;
			}
		}
		&.valign-middle {
			tr {
				th {
					vertical-align: middle;
				}
				td {
					vertical-align: middle;
				}
			}
		}
	}
	.amasty-product-attachment-block {
		.label {
			display: none;
		}
		ul {
			li {
				padding-left: 0px;
				margin-bottom: 10px;
				&:before {
					display: none;
				}
				a {
					display: inline-block;
					padding-left: 47px;
					padding-top: 7px;
					position: relative;
					min-height: 32px;
					font-size: 16px;
					line-height: 20px;
					font-weight: 800;
					letter-spacing: 0.9px;
					&:before {
						content: "";
						position: absolute;
						top: 0px;
						left: 0px;
						@include svg-icon("ic_cloud_red");
						width: 40px;
						height: 32px;
					}
				}
			}
		}
	}
	.table-block {
		padding-top: 16px;
		padding-bottom: 31px;
		h2 {
			max-width: none !important;
		}
		table {
			tr {
				th {
					background-color: $color-13;
					color: $color-1;
					font-weight: 800;
					padding-top: 21px;
					padding-bottom: 21px;
					padding-left: 12px;
					padding-right: 12px;
					border-bottom: 0px;
					border-left: 1px solid $color-13;
					border-right: 1px solid $color-13;
					text-align: center;
				}
				td {
					color: $color-13;
					padding-top: 8px;
					padding-bottom: 8px;
					padding-top: 21px;
					padding-bottom: 21px;
					padding-left: 12px;
					padding-right: 12px;
					border-top: 0px;
					border-bottom: 0px;
					border-left: 1px solid $color-1;
					border-right: 1px solid $color-1;
				}
				&:nth-child(even) {
					td {
						background-color: $color-12;
					}
				}
			}
		}
		.meta {
			font-size: 12px;
			line-height: 1.15;
			color: $color-8;
			text-align: right;
			margin-top: 9px;
			b {
				color: $color-8;
			}
		}
	}
	.iframe-block {
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 41px;
		iframe {
			max-width: 100% !important;
		}
		.meta {
			font-size: 12px;
			line-height: 1.15;
			color: $color-8;
			margin-top: 9px;
			b {
				color: $color-8;
			}
		}
	}
	.custom-grid-layout-block {
		img {
			margin-bottom: 20px;
		}
		iframe {
			margin-bottom: 20px;
			max-width: 100% !important;
			@include boxy-shadow("none");
			padding: 0px;
		}
	}
	.float-left {
		margin-bottom: 30px;
		margin-right: 35px;
	}
	.float-right {
		margin-bottom: 30px;
		margin-left: 35px;
	}
	.alignleft {
		display: block;
		margin-right: auto;
	}
	.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.alignright {
		display: block;
		margin-left: auto;
	}
	span {
		&.paragraph-large {
			font-size: 20px;
			line-height: 34px;
		}
	}
	iframe {
		@include boxy-shadow("0px 1px 14px rgba(0, 0, 0, 0.1)");
		padding: 20px;
		width: 100%;
		max-width: 740px;
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	h1 {
		font-size: 30px;
		line-height: 32px;
		margin-bottom: 28px;
	}
	h2 {
		font-size: 20px;
		line-height: 20px;
		letter-spacing: 1px;
		margin-bottom: 15px;
		&.highlighted {
			padding-top: 5px;
			padding-bottom: 5px;
			padding-left: 11px;
			border-left: 10px solid $color-3;
		}
	}
	h3 {
		font-size: 18px;
	}
	.text-style,
	.cke_editable_themed,
	#tinymce {
		font-size: 10px;
		line-height: 15px;
		.blockquote-block {
			blockquote {
				font-size: 18px;
				line-height: 24px;
			}
		}
		.highlighted-ol-block {
			ol {
				li {
					padding-left: 50px;
					&:before {
						left: 0px;
					}
				}
			}
		}
		ul {
			li {
				padding-left: 15px;
				&:before {
					top: 3px;
				}
			}
		}
		ol {
			li {
				padding-left: 15px;
			}
		}
	}
}
