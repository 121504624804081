.header-block {
	position: relative;
	z-index: 101;
	margin-bottom: 20px;
	.mini-contact-info-block {
		float: left;
		margin-top: 18px;
	}
	.language-block {
		float: right;
		margin-top: 15px;
	}
	.user-menu-block {
		float: right;
		clear: right;
		margin-top: 26px;
	}
	.minicart-block {
		float: right;
	}
	.left {
		.mini-contact-info-block {
			display: none;
		}
	}
	.middle {
		& > .container {
			position: relative;
		}
		.search-block {
			position: absolute;
			bottom: 26px;
			left: 270px;
		}
	}
	.top,
	.bottom {
		& > .container {
			& > .row {
				position: relative;
				& > [class*="col"] {
					position: static;
				}
			}
		}
	}
	.bottom {
		.language-block {
			display: none;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.header-block {
		.mini-contact-info-block {
			margin-top: 15px;
		}
		.language-block {
			float: right;
			margin-top: 10px;
		}
		.user-menu-block {
			margin-top: 29px;
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "lg")) and (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.header-block {
		.top,
		.bottom {
			& > .container {
				& > .row {
					position: relative;
					& > [class*="col"] {
						&:first-child {
							flex: 0 0 260px;
							max-width: 260px;
						}
						&:last-child {
							flex: 0 0 700px;
							max-width: 700px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "sm")) and (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.header-block {
		.bottom {
			.container {
				.row {
					clip-path: inset(0px -15px 0px 0px);
					box-shadow: 5px 0 10px -2px #888;
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.header-block {
		padding-top: 102px;
		.top {
			position: fixed;
			z-index: 1000;
			top: 0px;
			left: 0px;
			right: 0px;
			background-color: $color-1;
			&:before {
				content: "";
				position: absolute;
				top: 0px;
				left: 0px;
				right: 0px;
				height: 42px;
				background-color: $color-3;
			}
			.left {
				height: 42px;
				overflow: hidden;
				.logo-block {
					float: left;
					margin-left: -25px;
				}
				.mini-contact-info-block {
					display: block;
					float: right;
					a {
						color: $color-1 !important;
						margin-right: 0px;
						i {
							@include svg-icon("ic_phone_white");
						}
						&:last-child {
							display: none;
						}
					}
				}
			}
			.right {
				background-color: $color-1;
				.mobile-menu-wrapper {
					padding-top: 10px;
					padding-bottom: 5px;
					.mobile-menu-status-button {
						display: inline-block;
						margin-right: 13px;
                        width: 40px;
                        height: 40px;

						.mobile-menu-open-button {
							width: 40px;
							height: 40px;
							display: table-cell;
							vertical-align: middle;
							text-align: center;
							cursor: pointer;
                            &-image {
							width: 20px;
							height: 20px;
							margin: 0 auto;
							@include svg-icon("ic_hamburger_text");
						} /* iOS devices won't trigger js click event on an empty div. Either add empty onclick="" or cursor: pointer */
						}
						.mobile-menu-close-button {
							width: 40px;
							height: 40px;
							vertical-align: middle;
							text-align: center;
							display: none;
							cursor: pointer;
                            &-image {
								width: 18px;
								height: 15px;
								margin: 0 auto;
								@include svg-icon("ic_cross_text");
							}
						}
						&.opened {
							.mobile-menu-open-button {
								display: none;
								&-image {
									display: none;
								}
							}
							.mobile-menu-close-button {
								display: table-cell;
							}
						}
					}
					.user-menu-block {
						display: inline-block;
						margin-top: 0px;
						float: right;
						& > a {
							width: 40px;
							height: 40px;
							overflow: hidden;
							text-indent: -9999px;
							padding: 0px;
							text-align: center;
							margin: 0 15px;
							.icon {
								top: 50%;
								left: 50%;
								bottom: auto;
								@include transform(translateX(-50%) translateY(-50%));
							}
						}
						.mini-cart {
							margin-top: 0px;
							margin-left: 10px;
							width: 40px;
							height: 40px;
							.mini-cart-heading {
								line-height: 40px;
								max-height: 40px;
								padding-left: 0;
								padding-right: 0;
							}
							.label {
								display: none;
							}
						}
						.auth-wrapper {
							.auth-link {
								width: 40px;
								height: 40px;
								overflow: hidden;
								text-indent: -9999px;
								padding: 0px;
								text-align: center;
								margin-left: 13px;
								margin-right: 13px;
								.icon {
									top: 50%;
									left: 50%;
									bottom: auto;
									@include transform(translateX(-50%) translateY(-50%));
								}
							}
							.logout-wrapper {
								display: none;
							}
						}
					}
				}
				.mini-contact-info-block {
					display: none;
				}
				.search-block {
					display: none;
				}
				& > .language-block {
					display: none;
				}
			}
		}
		.middle {
			padding-top: 4px;
			.search-block {
				position: relative;
				left: 0px;
				bottom: 0px;
				.search {
					float: none;
					#search {
						width: 100%;
					}
				}
			}
		}
		.bottom {
			position: fixed;
			z-index: 999;
			top: 106px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			visibility: hidden;
			& > .container {
				max-width: 240px;
				margin-left: 0px;
				padding-left: 0px;
				padding-right: 0px;
				height: 100%;
				position: relative;
				@include transform(translateX(-100%));
				@include transition(all $default_transition_time);
				&:before {
					content: "";
					position: absolute;
					top: 0px;
					bottom: -1px;
					right: -17px;
					width: 17px;
					height: 100%;
					@include linear-gradient("90deg, rgba(167, 169, 172, 0.15) 0%, rgba(196, 196, 196, 0) 100%");
				}
				& > .row {
					height: 100%;
					display: block;
					background-color: $color-1;
					margin-left: 0px;
					margin-right: 0px;
					.ultra-mega-menu-block {
						.dropdown-block {
							border-top: 1px solid $color-8;
						}
					}
					& > [class*="col"] {
						padding-left: 0px;
						padding-right: 0px;
						&:last-child {
							height: calc(100% - 46px);
						}
					}
				}
			}
			.language-block {
				display: block;
				margin-top: 0px;
				float: none;
				position: absolute;
				bottom: 0px;
				left: 0px;
				right: 0px;
				text-align: center;
				padding-top: 10px;
				padding-bottom: 10px;
				z-index: 999;
				background-color: $color-1;
			}
			&.active {
				visibility: visible;
				& > .container {
					@include transform(translateX(0%));
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.header-block {
		.bottom {
			& > .container {
				max-width: 100%;
			}
		}
	}
}
