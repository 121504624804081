.breadcrumbs {
	.items {
		.item {
			font-size: 12px;
			line-height: 14px;
			font-weight: 300;
			letter-spacing: 0.5px;
			color: $color-8;
			a {
				font-size: 12px;
				line-height: 14px;
				font-weight: 300;
				letter-spacing: 0.5px;
				color: $color-13;
			}
			&:after {
				content: "/";
				font-size: 12px;
				margin-top: -2px;
				padding-left: 11px;
				padding-right: 11px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}
