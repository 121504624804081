.sale-notification-block {
	.inner-wrapper {
		padding-right: 430px;
		position: relative;
		.discount-block {
			position: absolute;
			bottom: 0px;
			right: 430px;
		}
		.info-wrapper {
			position: absolute;
			top: 0px;
			bottom: 0px;
			right: 0px;
			width: 430px;
			background-color: $color-3;
			.d-table {
				height: 100%;
				color: $color-1;
				h3 {
					color: $color-1;
					font-weight: 400;
				}
				h2 {
					color: $color-1;
					p {
						display: inline;
						margin: 0px;
						font-weight: 400;
					}
				}
				.d-table-row {
					.d-table-cell {
						padding-top: 20px;
						padding-bottom: 20px;
						padding-left: 62px;
						padding-right: 62px;
						vertical-align: middle;
					}
				}
			}
		}
		img {
			display: block;
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "lg") - 1) and (max-width: map-get($grid-breakpoints, "xl")) {
	.sale-notification-block {
		.inner-wrapper {
			.info-wrapper {
				.d-table {
					h2 {
						font-size: 20px;
						line-height: 26px;
						margin-bottom: 10px;
					}
					.text-style {
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.sale-notification-block {
		.inner-wrapper {
			.info-wrapper {
				.d-table {
					.arrowed-link-style {
						float: right;
					}
					.d-table-row {
						.d-table-cell {
							padding-left: 30px;
							padding-right: 30px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.sale-notification-block {
		.inner-wrapper {
			padding-right: 0px;
			.discount-block {
				top: 0px;
				left: 0px;
				width: 110px;
				padding-left: 5px;
				padding-right: 5px;
				.discount-inner-wrapper {
					top: 50%;
					@include transform(translateY(-50%));
				}
			}
			.info-wrapper {
				position: relative;
				padding-left: 110px;
				width: auto;
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.sale-notification-block {
		& > .container {
			max-width: none;
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.sale-notification-block {
		.inner-wrapper {
			.info-wrapper {
				.d-table {
					h2 {
						margin-bottom: 8px;
					}
					.arrowed-link-style {
						margin-top: 10px;
						margin-bottom: -10px;
					}
					.d-table-row {
						.d-table-cell {
							padding-top: 13px;
							padding-bottom: 13px;
							padding-left: 10px;
							padding-right: 10px;
						}
					}
				}
			}
		}
	}
}
