.partners-block {
	text-align: center;
	.item {
		display: inline-block;
		margin-left: 64px;
		margin-right: 64px;
		margin-bottom: 20px;
		&:first-child {
			margin-left: 0px;
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.partners-block {
		.item {
			margin-left: 30px;
			margin-right: 30px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.partners-block {
		.item {
			margin-left: 0px;
			margin-right: 0px;
			display: block;
		}
	}
}
