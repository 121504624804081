.bottom-b-block {
	position: fixed;
	z-index: 999;
	bottom: 0px;
	left: 0px;
	width: 1000%;
	background-color: #ff6600;
	display: none;
	.close {
		position: absolute;
		bottom: 76px;
		left: 14px;
		width: 40px;
		height: 40px;
		@include svg-icon("ic_close_white_on_red");
		background-repeat: no-repeat;
		background-position: center center;
		cursor: pointer;
		@include transition(all $default_transition_time);
		&:hover {
			@include opacity(75);
		}
	}
	.slider {
		position: absolute;
		bottom: 0px;
		left: 0px;
		.full-element {
			float: left;
			img {
				float: left;
			}
		}
	}
	&.initialized {
		display: block;
		.slider {
			@include transform(translateX(0%));
			animation-name: slide;
			animation-timing-function: linear;
			animation-iteration-count: infinite;
		}
	}
}

@keyframes slide {
	from {
		@include transform(translateX(0%));
	}
	to {
		@include transform(translateX(-50%));
	}
}
