.drawer-block {
	position: fixed;
	z-index: 1000;
	top: 50%;
	left: 100%;
	@include transform(translateY(-50%));
	.drawer-item {
		position: relative;
		@include transition(all $default_transition_time);
		margin-bottom: 20px;
		.title {
			width: 246px;
			height: 53px;
			background-color: $color-3;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
			position: absolute;
			top: 0px;
			left: 0px;
			-webkit-transform-origin: 0 0;
			transform-origin: 0 0;
			@include transform(rotate(90deg));
			@include transition(all $default_transition_time);
			@include noselect();
			cursor: pointer;
			h3 {
				color: $color-1;
				text-align: center;
				margin-bottom: 0px;
				line-height: 53px;
				font-size: 22px;
			}
			&:hover {
				background-color: lighten($color-3, 5%);
			}
		}
		.drawer-block-content {
			width: 340px;
			height: 246px;
			padding: 20px;
			background-color: $color-1;
		}
		&.opened {
			@include transform(translateX(-340px));
		}
		&:nth-child(even) {
			.title {
				background-color: $color-14;
				&:hover {
					background-color: lighten($color-14, 5%);
				}
			}
		}
		&:last-child {
			margin-bottom: 0px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.drawer-block {
		display: none;
	}
}