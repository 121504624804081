.for-legal-type-block {
	font-size: 12px;
	margin-bottom: 20px;
	position: relative;
	padding-top: 1px;
	@include noselect();
	strong {
		font-weight: 800;
	}
	.field-tooltip {
		text-align: center;
		.field-tooltip-action {
			cursor: pointer;
		}
		.field-tooltip-content {
			&:before,
			&:after {
				display: none;
			}
		}
	}
}