.product-list-slider-block {
	margin-bottom: 73px;
	.product-list-page-block {
		margin-left: -10px !important;
		margin-right: -10px !important;
		.items-grid {
			.product-items {
				margin-left: 0px;
				margin-right: 0px;
				.item {
					.product-item-info {
						.product-item-img {
							&:before {
								width: 210px;
								right: auto;
								left: 50%;
								@include transform(translateX(-50%));
							}
						}
					}
				}
			}
		}
	}
	.owl-carousel {
		.owl-wrapper-outer {
			padding-top: 10px;
			overflow: visible;
			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0px;
				bottom: 0px;
				width: 100vw;
				background-color: $color-1;
				z-index: 2;
			}
			&:before {
				right: 100%;
			}
			&:after {
				left: 100%;
			}
			.owl-wrapper {
				.owl-item {
					height: 409px !important;
					& > .item {
						width: auto;
					}
				}
			}
		}
		.owl-controls {
			.owl-buttons {
				position: static;
				.owl-prev,
				.owl-next {
					position: absolute;
					z-index: 3;
					top: 43%;
					@include transform(translateY(-50%));
					width: 40px;
					height: 40px;
					background-repeat: no-repeat;
					background-position: center center !important;
					background-color: transparent !important;
					overflow: hidden;
					text-indent: -9999px;
					@include transition(all $default_transition_time);
				}
				.owl-prev {
					left: -10px;
					@include svg-icon("ic_arrow_circle_left_red", important);
					&:hover {
						left: -15px;
					}
				}
				.owl-next {
					right: -10px;
					@include svg-icon("ic_arrow_circle_right_red", important);
					&:hover {
						right: -15px;
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.product-slider-block {
		.owl-carousel {
			.owl-wrapper-outer {
				.owl-wrapper {
					.owl-item {
						& > .item {
							max-width: 305px;
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
			}
		}
	}
}
