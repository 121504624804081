.vacancy-block {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 15px;
	.vacancy-title {
		position: relative;
		cursor: pointer;
		margin-bottom: 0px;
		padding-right: 40px;
		@include transition(all $default_transition_time);
		@include noselect();
		font-weight: 400;
		&:before {
			content: "";
			width: 20px;
			height: 20px;
			@include svg-icon("ic_chevron_medium_right_red");
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			top: 8px;
			right: 0px;
		}
	}
	.vacancy-content {
		@include transition(all $default_transition_time);
		visibility: hidden;
		height: 0px;
		overflow: hidden;
		.vacancy-content-inner {
			padding-top: 20px;
			padding-bottom: 20px;
			padding-left: 20px;
			.vacancy-shops {

			}
			.vacancy-responsibilities {

			}
			.vacancy-requirements {

			}
			.vacancy-offers {

			}
			.vacancy-additional {

			}
		}
	}
	&.active {
		.vacancy-title {
			&:before {
				@include transform(rotate(90deg));
			}
		}
		.vacancy-content {
			visibility: visible;
			height: auto;
			overflow: visible;
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "md")) {
	.vacancy-block {
		.vacancy-title {
			&:hover {
				h3 {
					color: $color_3;
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.vacancy-block {
		.vacancy-title {
			&:before {
				top: 1px;
			}
		}
	}
}
