.textdoc-page-block {
	.text-style {
		color: $color-8;
		& > h1 {
			text-align: center;
			max-width:  960px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 27px;
		}
		// & > p {
		// 	max-width: 700px;
		// 	margin-left: auto;
		// 	margin-right: auto;
		// }
		// & > h2 {
		// 	max-width: 700px;
		// 	margin-left: auto;
		// 	margin-right: auto;
		// }
		// & > ul {
		// 	max-width: 700px;
		// 	margin-left: auto;
		// 	margin-right: auto;	
		// }
		// & > ol {
		// 	max-width: 700px;
		// 	margin-left: auto;
		// 	margin-right: auto;	
		// }
		.amasty-product-attachment-block {
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;
			ul {
				max-width: none !important;
			}
		}
		.highlighted-ol-block {
			ol {
				max-width: none !important;
			}
		}
		.tab-block {
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;	
		}
	}
}