.forgot-password-block {
	a {
		font-weight: 800;
	}
	.form-block {
		.success-wrapper {
			.text-style {
				font-size: 14px;
				line-height: 18px;
				margin-bottom: 53px;
			}
		}
	}
}