.category-page-header-block {
	.row {
		flex-wrap: nowrap;
		.col {
			&:first-child {
				flex: 1 1 auto;
			}
			&:last-child {
				flex: 0 1 auto;
				width: auto;
			}
		}
	}
	h2 {
		text-transform: uppercase;
		margin-bottom: 0px;
        margin-top: 20px;
	}
	.product-compare-button {
		font-size: 12px !important;
		i {
			position: relative;
			top: -1px;
			left: -14px;
		}
		span {
			color: $color_3;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.category-page-header-block {
		.row {
			flex-wrap: initial;
			display: block;
		}
		.product-compare-button {
			float: right;
		}
	}
}
