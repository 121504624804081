/* Colors -----------------------------------------------------------------*/

$color-1 : #FFFFFF; // White
$color-2 : #000000; // Black
$color-3 : #E53647; // Primary red
$color-4 : #00A887; // Secondary green
$color-5 : #5B87DA; // Secondary light blue
$color-6 : #0033A0; // Secondary dark blue
$color-7 : #F9F2DF; // wrong beige
$color-8 : #A7A9AC; // Complimentory grey
$color-9 : #160F3E; // Complimentory dark
$color-10 : #F1E4C5; // Complimentory beige
$color-11 : #B28D69; // Complimentory brown
$color-12 : #F4F4F4; // light web bg
$color-13 : #363636; // text
$color-14 : #2A3F5C; // navy
$color-15 : #152B48; // navy - hover
$color-16 : #C72434; // Primary red - hover
$color-17 : #DDDDDD; // Gray
$color-18 : #D5D5D5; // Gray 2
$color-19 : #c8cacb; // Gray 3
$color-20 : #7291D7; // Blueish
$color-21 : #8E8E8E; // Gray 4
$color-22 : #272727; // Dark
$color-23 : #1D1F24; // Dark 2
$color-24 : #7B7B7B; // Gray 5
$color-25 : #E6E6E6; // Gray 6
$color-26 : #AAAAAA; // Gray 7
$color-27 : #494948; // Gray 8

/* Fonts ------------------------------------------------------------------*/

$default_font_stack: "Gilroy", sans-serif;
$default_font_color: $color-13;
$default_font_size: 16px;
$default_line_height: 26px;

/* Other ------------------------------------------------------------------*/

$main_content_width: 1280px + 20px; // content + outside gutter
$image_directory: "/assets/images/";
$default_transition_time: 0.3s;

/* Inlined Images -----------------------------------------------------------------*/

$inlined_images: (
	"ic_loading_red_on_white.gif": $image_directory + "ic_loading_red_on_white.gif",
	"ic_loading_white_on_red.gif": $image_directory + "ic_loading_white_on_red.gif",
	"ic_loading_magnify_white.gif": $image_directory + "ic_loading_magnify_white.gif",
    "ic_delivery_blue.svg": $image_directory + "ic_delivery_blue.svg",
    "ic_2h_red.svg": $image_directory + "ic_2h_red.svg",
    "ic_delivery_point_dreilini.svg": $image_directory + "ic_delivery_point_dreilini.svg",
	"ic_delivery_point_circlek.svg": $image_directory + "ic_delivery_point_circlek.png",
	"ic_delivery_point_omnivia.svg": $image_directory + "ic_delivery_point_omnivia.png",
	"ic_delivery_point_pasts.svg": $image_directory + "ic_delivery_point_pasts.png",
	"ic_delivery_point_dpd.svg": $image_directory + "ic_delivery_point_dpd.png",
	"ic_wallet_red.jpg": $image_directory + "ic_wallet_red.jpg",
    "not_found_background.svg": $image_directory + "not_found_background.svg",
    "ic_green_checkmark.svg": $image_directory + "ic_green_checkmark.svg",
);
