.main-menu-block {
	.nav-container {
		& > .container {
			padding-left: 0px;
			padding-right: 0px;
			.inner-container {
				padding-left: 0px;
				padding-right: 0px;
				width: 100%;
				#mainmenu {
					border-top: 1px solid $color-8;
					border-bottom: 1px solid $color-8;
					& > ul {
						& > li {
							position: relative;
							& > a {
								padding-left: 21px;
								padding-right: 21px;
								position: relative;
								.content {
									color: $color-13;
									font-size: 14px;
									font-weight: 800;
									height: 50px;
									line-height: 18px;
									position: relative;
									text-transform: uppercase;
									vertical-align: top;
									white-space: normal;
                                    align-items: center;
                                    display: flex;
                                    justify-content: center;
                                    padding: 0 0 0 17px;
                                }
								&:before {
									content: "";
									position: absolute;
									z-index: 98;
									top: 0px;
									bottom: 0px;
									left: 0px;
									right: 0px;
								}
								&.has-submenu {
									.content {
										.submenu-toggler {
											height: 36px;
											right: 0;
											width: 18px;
                                            margin-left: 3px;
                                            position: relative;
                                            top: -1px;
											&:before {
												@include transition(transform $default_transition_time);
												content: "";
												display: block;
                                                background-image: url(/assets/images/css/arrow_right.svg);
                                                background-repeat: no-repeat;
                                                height: 8px;
                                                margin: 13px auto;
                                                transform-origin: 25%;
                                                width: 6px;
											}
										}
									}
								}
								& + .submenu-wrapper {
									& > .back {
										display: none;
									}
									& > ul {
										visibility: hidden;
										@include opacity(0);
										@include transition(all $default_transition_time);
										position: absolute;
										z-index: 99;
										top: 100%;
										left: 50%;
										width: 240px;
										@include transform(translateX(-50%) translateY(0px));
										margin-top: 1px;
										pointer-events: none;
										& > li {
											@include transition(all $default_transition_time);
											margin-top: -1px;
											& > a {
												background-color: $color-1;
												font-size: 12px;
												line-height: 16px;
												font-weight: 300;
												color: $color-13;
												padding-top: 15px;
												padding-bottom: 15px;
												padding-left: 17px;
												padding-right: 17px;
												border: 1px solid $color-8;
												border-top: 1px solid $color-12;
											}
											&:first-child {
												& > a {
													border-top-color: $color-8;
												}
											}
											&:hover,
											&.active {
												& > a {
													background-color: $color-3;
													color: $color-1;
													border-color: $color-3;
												}
												& + li {
													& > a {
														border-top-color: $color-3;
													}
												}
												&:last-child {
													& > a {
														border-bottom-color: $color-3;
													}
												}
											}
										}
									}
								}
							}
							&:first-child {
								& > a {
									padding-left: 0px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
	.main-menu-block {
		.nav-container {
			& > .container {
				.inner-container {
					#mainmenu {
						& > ul {
							& > li {
								&:hover {
									& > a {
										&.has-submenu {
											.content {
												.submenu-toggler {
													&:before {
														@include transform(rotate(90deg));
													}
												}
											}
										}
										& + .submenu-wrapper {
											& > ul {
												visibility: visible;
												@include opacity(100);
												pointer-events: all;
												@include transform(translateX(-50%) translateY(0px));
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.main-menu-block {
		.nav-container {
			& > .container {
				.inner-container {
					#mainmenu {
						& > ul {
							& > li {
								& > a {
									.content {
										font-size: 12px;
										padding-left: 8px;
										padding-right: 8px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.main-menu-block {
		position: relative;
		height: 100%;
		.nav-container {
			height: 100%;
			& > .container {
				height: 100%;
				.inner-container {
					height: 100%;
					.navi-wrapper {
						height: 100%;
					}
					#mainmenu {
						border-top: 1px solid $color-12;
						border-bottom: 0px;
						height: 100%;
						& > ul {
							position: relative;
							height: 100%;
							& > li {
								float: none;
								position: static;
								& > a {
									padding-left: 11px !important;
									padding-right: 0px !important;
									border-bottom: 1px solid $color-12;
									.content {
										color: $color-9 !important;
										font-size: 12px;
										font-weight: 300;
										height: auto;
										padding-bottom: 12px;
										padding-left: 0px;
										padding-right: 36px !important;
										padding-top: 13px;
										text-transform: none;
                                        justify-content: left;
									}
									&.has-submenu {
										.content {
											.submenu-toggler {
												height: 36px;
												width: 36px;
												z-index: 99;
                                                @include transform(translateY(-50%));
                                                position: absolute;
                                                top: 50%;
											}
										}
									}
									& + .submenu-wrapper {
										position: absolute;
										z-index: 999;
										top: -44px;
										bottom: 45px;
										left: 0px;
										right: 0px;
										@include transition(all $default_transition_time);
										@include transform(translateX(-100%));
										background-color: $color-1;
										.back {
											display: block;
											background-color: $color-3;
											position: relative;
											padding-left: 38px;
											padding-right: 5px;
											height: 44px;
											line-height: 44px;
											.back-btn {
												position: absolute;
												left: 3px;
												top: 50%;
												@include transform(translateY(-50%));
												width: 36px;
												height: 36px;
												@include transition(all $default_transition_time);

												&::before {
												    content: '';
												    display: block;
                                                    @include svg-icon("ic_chevron_thick_left_white");
                                                    width: 5px;
                                                    height: 10px;
                                                    margin: 13px;
                                                    transform: scale(1.2);
                                                }
											}
											span {
												display: inline-block;
												vertical-align: middle;
												font-size: 12px;
												line-height: 14px;
												font-weight: 800;
												color: $color-1 !important;
												text-transform: uppercase;
												margin-top: 1px;
											}
										}
										& > ul {
											visibility: visible;
											@include opacity(100);
											@include transform(none);
											pointer-events: all;
											overflow: auto;
											top: 44px;
											bottom: 0px;
											left: 0px;
											& > li {
												& > a {
													border-bottom: 0px;
													border-left: 0px;
													border-right: 0px;
													line-height: 15px;
													padding-top: 14px;
													padding-bottom: 14px;
												}
											}
										}
									}
								}
								&.active {
									& > a {
											& + .submenu-wrapper {
											visibility: visible;
											@include transform(translateX(0%));
										}
									}
								}
							}
							&:before {
								content: "";
								position: absolute;
								top: 0px;
								left: 0px;
								right: 0px;
								width: 100%;
								height: 17px;
								@include linear-gradient("180deg, rgba(167, 169, 172, 0.15) 0%, rgba(196, 196, 196, 0) 100%");
							}
						}
					}
				}
			}
		}
	}
}
