.pager-block {
	.field.limiter {
		font-size: 12px;
		font-weight: 300;
		margin-top: 4px;
		.label {
			display: inline;
			margin: 0px;
		}
		.control {
			position: relative;
			margin-left: 7px;
			margin-right: 7px;
			&:before {
				content: "";
				position: absolute;
				top: 1px;
				right: 1px;
				background-color: $color-1;
				border-radius: 4px;
                width: 8px;
                height: 6px;
                transform: scale(1.1);
                margin: 12px 5px;
				@include svg-icon("ic_chevron_thick_down_red");
				pointer-events: none;
			}
			.limiter-options {
				font-family: $default_font_stack;
				margin-left: 2px;
				border: 1px solid $color-8;
				border-radius: 3px;
				font-size: 12px;
				line-height: 30px;
				padding-top: 0px;
				padding-bottom: 0px;
				padding-left: 12px;
				padding-right: 20px;
				font-weight: 300;
				color: $color-13;
				cursor: pointer;
				margin: 0px;
			}
		}
	}
	.pages {
		.pages-items {
			display: block;
			.item {
				width: 24px;
				height: 32px;
				text-align: center;
				vertical-align: top;
				font-size: 16px;
				line-height: 32px;
				font-weight: 800;
				letter-spacing: 0.9px;
				margin-top: 4px;
				color: $color-2;
				a {
					width: 100%;
					height: 100%;
					color: $color-2;
					&:before {
						display: none;
					}
					&:hover {
						color: $color-3;
					}
				}
				&.current {
					.page {
						font-size: inherit;
						line-height: inherit;
						letter-spacing: inherit;
						font-weight: inherit;
						color: $color-3;
						width: auto;
					}
				}
				&.pages-item-previous {
					width: 40px;
					height: 40px;
					@include svg-icon("ic_arrow_circle_left_red");
					margin-top: 0px;
					margin-right: 10px;
					a {
						width: 100%;
						height: 100%;
					}
				}
				&.pages-item-next {
					width: 40px;
					height: 40px;
					@include svg-icon("ic_arrow_circle_right_red");
					margin-top: 0px;
					margin-left: 10px;
					a {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.pager-block {
		.pages {
			float: none !important;
			text-align: center;
			margin-bottom: 20px;
			.pages-items {
				display: inline-block;
			}
		}
		.field.limiter {
			float: none !important;
		}
	}
}
