.not-found-block {
	background-repeat: no-repeat;
	background-position: left center;
	background-image: getInlinedImage("not_found_background.svg");
	min-height: 377px;
	color: $color-13;
	padding-top: 58px;
	margin-top: 75px;
	.not-found-img {
		margin-bottom: 26px;
	}
	h3 {
		margin-bottom: 25px;
	}
	a {
		font-weight: 800;
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.not-found-block {
		margin-top: 0px;
	}
}
