.dropdown-block {
	position: relative;
	cursor: default;
	text-align: center;
	span {
		display: inline-block;
		vertical-align: top;
		position: relative;
		height: 50px;
		font-size: 14px;
		line-height: 18px;
		font-weight: 800;
		text-transform: uppercase;
		color: $color-13;
		padding-top: 17px;
		padding-left: 17px;
		padding-right: 17px;
		white-space: normal;
		i {
			margin-right: 10px;
			margin-top: -2px;
		}
	}
	&.has-submenu {
		span {
			padding-right: 20px;
			&:before {
				content: "";
                width: 6px;
                height: 8px;
				@include svg-icon("ic_chevron_thick_right_dark");
				position: absolute;
				right: 0px;
				top: 50%;
				@include transition(all $default_transition_time);
				@include transform(translateY(-50%));
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.dropdown-block {
		span {
			padding-top: 13px;
		}
	}
}
