.promo-block {
	.promo-mini-items-wrapper {
		width: 320px;
		float: right;
	}
}

@media screen and (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.promo-block {
		.promo-mini-items-wrapper {
			display: none;
		}
	}
}
