.tab-block {
	.nav-tabs {
		border-bottom: 1px solid $color_8;
		.nav-item {
			padding-left: 0px;
			@include noselect();
			&:before {
				display: none;
			}
			.nav-link {
				border-color: transparent;
				border-radius: 0px;
				border: 0px;
				color: $color_23;
				margin-bottom: 1px;
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 15px;
				padding-right: 15px;
				text-align: center;
				cursor: pointer;
				position: relative;
				&:before {
					content: "";
					position: absolute;
					bottom: -1px;
					left: 0px;
					right: 0px;
					height: 3px;
				}
			}
			&:first-child {
				.nav-link {
					padding-left: 0px;
				}
			}
			&:last-child {
				.nav-link {
					padding-right: 0px;
				}
			}
			&.active {
				.nav-link {
					color: $color_3;
					font-weight: 700;
					&:before {
						background-color: $color_3;
					}
				}
				&:first-child {
					.nav-link {
						&:before {
							right: 15px;
						}
					}
				}
				&:last-child {
					.nav-link {
						&:before {
							left: 15px;
						}
					}
				}
			}
		}
	}
	.tab-content {
		.tab-pane {
			padding-top: 40px;
			&[data-tab-id="user_role_legal"] {
				.amasty-custom-form-block {
					.field {
						&.fb-undefined {
							& + .field {
								padding-left: 0px;
							}
						}
					}
				}
			}
		}
	}
}
