.breadcrumbs {
	margin-top: 5px;
	.items {
		line-height: 14px;
		.item {
			font-size: 12px;
			line-height: 14px;
			font-weight: 300;
			letter-spacing: 0.5px;
			color: $color-8;
			a {
				font-size: 12px;
				line-height: 14px;
				font-weight: 300;
				letter-spacing: 0.5px;
				color: $color-13;
			}
			&:after {
				content: "/";
				font-size: 12px;
				margin-top: -2px;
				padding-left: 11px;
				padding-right: 11px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.breadcrumbs {
		margin-bottom: 21px;
		.items {
			> .item {
				display: none;
				&:nth-last-child(-n+3) {
					display: unset;
				}
				&:nth-last-child(-n+2) {
					&:after {
						display: none;
					}
				}
				&:last-child {
					display: none;
				}
			}
		}
	}
}
