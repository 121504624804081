.homepage-banners-wrapper {
    margin-bottom: 50px;

    .items {
        display: flex;
        flex-direction: row;
    }
}

@media screen and (max-width: map-get($grid-breakpoints, "lg") - 1) {
    .homepage-banners-wrapper {
        .items {
            flex-direction: column;
        }

        .item.hidden_mobile {
            display: none;
        }
    }
}
