.category-list-block {
	.items-grid {
		.product-items {
			.item {
				@include boxy-shadow("0px 1px 14px rgba(0, 0, 0, 0.1)");
				height: 240px !important;
				text-align: center;
				@include transition(all $default_transition_time);
				&:before {
					display: none;
				}
				.product-item-img {
					margin-bottom: 0px;
					a {
						display: block;
						padding-top: 18px;
						padding-bottom: 18px;
						padding-left: 20px;
						padding-right: 20px;
						height: 191px;
						img {
							max-height: 155px;
							display: block;
							margin-left: auto;
							margin-right: auto;
							position: relative;
							top: 50%;
							@include transform(translateY(-50%));
						}
					}
				}
				.product-item-name {
					margin-top: 0px;
					margin-bottom: 0px;
					a {
						display: block;
						font-size: 16px;
						font-weight: 600;
						line-height: 1.15;
						letter-spacing: 0.9px;
						color: #000000;
						padding-left: 20px;
						padding-right: 20px;
						text-decoration: none;
					}
				}
				&:hover {
					@include boxy-shadow("0px 1px 14px rgba(0, 0, 0, 0.3)");
				}
			}
		}
	}
}
