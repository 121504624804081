.advanced-search-page-block {
	.search.found,
	.search.summary,
	.message.notice {
		display: none;
	}
	.form-block {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
		.legend {
			margin-left: 0px;
		}
		.fieldset {
			margin-bottom: 0px;
		}
		.fields.range {
			.field {
				&:first-child {
					.control {
						&:after {
							display: block !important;
							top: 14px !important;
						}
					}
				}
				&:last-child {
					div.mage-error {
						top: 53px !important;
					}
				}
			}
		}
		.field {
			.addon {
				display: block;
				input {
					width: 100%;
				}
				.addafter {
					padding-top: 0px !important;
					height: auto;
					top: 50% !important;
					@include transform(translateY(-50%));
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.advanced-search-page-block {
		.form-block {
			.fields.range {
				.field {
					&:first-child {
						.control {
							&:after {
								top: 7px !important;
							}
						}
					}
					&:last-child {
						div.mage-error {
							top: 40px !important;
						}
					}
				}
			}
		}
	}
}