.amasty-custom-form-block {
	margin-bottom: 40px;
	.rendered-form.amform-form {
        margin: 0 auto 10px;
        max-width: 700px;

		.amcform-page-wrap {
			display: flex;
			flex-wrap: wrap;
			.field {
				flex: 1 0 50%;
				max-width: 50%;
				margin-left: 0px;
				margin-right: 0px;
				.label {
					.required,
					.tooltip-element {
						display: none;
					}
				}
				input {
					max-width: none;
				}
				&:nth-child(odd) {
					padding-right: 10px;
				}
				&:nth-child(even) {
					padding-left: 10px;
				}
				&.fb-undefined {
					flex: 1 0 100%;
					max-width: 100%;
					padding-left: 0px;
					padding-right: 0px;
					textarea {
						min-height: 165px;
					}
				}
				&.fb-select {
					.control {
						position: relative;
						&:before {
							content: "";
							position: absolute;
							top: 1px;
							right: 1px;
							background-color: $color-1;
							border-radius: 4px;
							width: 10px;
							height: 5px;
                            max-height: 5px;
							background-repeat: no-repeat;
							background-position: 7px center;
							@include svg-icon("ic_chevron_thick_down_red");
							pointer-events: none;
                            transform: scale(1.2);
                            margin: 22px 10px;

                            @media (max-width: map-get($grid-breakpoints, "md") - 1) {
                                margin: 15px 10px;
                            }
						}
						select {
							display: block;
							width: 100%;
							height: 53px;
							font-family: $default_font_stack;
							font-size: 16px;
							font-weight: 300;
							line-height: 53px;
							padding-top: 1px;
							padding-bottom: 0px;
							padding-left: 17px;
							padding-right: 0px; /* must be 0 - for IE compatability */
							background: transparent;
							color: $color-13;
							border-radius: 3px;
							border: 1px solid $color-8;
							@include transition(all $default_transition_time);
							cursor: pointer;
							&:focus {
								@include boxy-shadow("0px 0px 2px rgba(52, 74, 106, 0.5)");
								border-color: $color-14;
							}
						}
					}
				}
				&.fb-file {
					.control {
						position: relative;
						&:before {
							content: "";
							position: absolute;
							top: 0px;
							bottom: 0px;
							left: 0px;
							right: 0px;
							background-color: $color_3;
						}
						input {
							margin: 0px;
							position: relative;
							z-index: 2;
							@include opacity(0);
						}
						.choose-file {
							color: $color_1;
							position: absolute;
							top: 50%;
							left: 0px;
							right: 0px;
							@include transform(translateY(-50%));
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							text-align: center;
							font-weight: 800;
							padding-left: 20px;
							padding-right: 20px;
						}
					}
				}
			}
			.amcform-toolbar {
				flex: 1 0 100%;
				max-width: 100%;
                text-align: right;
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.amasty-custom-form-block {
		.rendered-form.amform-form {
			.amcform-page-wrap {
				.field {
					&.fb-select {
						.control {
							&:before {
								height: 38px;
							}
							select {
								height: 40px;
								line-height: 40px;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.amasty-custom-form-block {
		.rendered-form.amform-form {
			.amcform-page-wrap {
				.field {
					flex: 1 1 100%;
					max-width: 100%;
					padding-left: 0px !important;
					padding-right: 0px !important;
				}
			}
		}
	}
}
