.discount-block {
	background-color: $color-14;
	color: $color-1;
	font-size: 14px;
	font-weight: 800;
	padding-top: 15px;
	padding-bottom: 23px;
	padding-left: 30px;
	padding-right: 30px;
	text-align: center;
	.discount-inner-wrapper {
		position: relative;
		span {
			display: block;
			font-size: 36px;
			font-weight: 800;
		}
	}
}

@media screen and (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.discount-block {
		line-height: 20px;
		padding-top: 10px;
		padding-bottom: 18px;
		padding-left: 25px;
		padding-right: 25px;
		span {
			font-size: 20px;
			margin-top: 5px;
		}
	}
}