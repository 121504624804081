.modal-popup {
	&.confirm {
		.modal-inner-wrap {

		}
	}
}

@media (max-width: 839px) {
	.modal-popup {
		&.confirm {
			.modal-inner-wrap {
				width: calc(100vw - 20px);
			}
		}
	}
}