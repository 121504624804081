.footer-menu-block {
	margin-top: 40px;
	padding-left: 110px;
	padding-right: 50px;
	.title {
		text-transform: uppercase;
		color: $color-14;
		margin-bottom: 30px;
	}
	.items {
		a {
			display: block;
			color: $color-14;
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
	&:before {
		content: "";
		position: absolute;
		top: 70px;
		bottom: 30px;
		border-left: 1px solid $color-18;
		@include transform(translateX(-83px));
	}
}
