.minicart-block {
	margin-top: -3px;
	margin-right: -10px;
	.mini-cart {
		& > #header-cart {
			display: none !important;
		}
		.ic-cart {
			color: $color-13;
		}
		.inner-heading {
			.label {
				color: $color-13;
				font-size: 12px;
				line-height: 14px;
				font-weight: 300;
				letter-spacing: 0.5px;
				display: inline-block;
				vertical-align: middle !important;
				padding-left: 4px;
				padding-top: 7px;
				cursor: default;
			}
		}
		.caret {
			display: none;
		}
		.inner-heading {
			padding: 0px !important;
			background-color: transparent !important;
			position: relative;
			.ic-cart {
				margin-top: 8px;
			}
			.counter {
				position: absolute;
				top: -11px;
				left: 15px;
				width: auto;
				height: auto;
				line-height: 1;
				background-color: transparent;
				.counter-number {
					width: 19px;
					height: 19px;
					line-height: 19px;
					text-align: center;
					font-size: 12px;
					font-weight: 800;
					background-color: $color-3;
					color: $color-1;
					border-radius: 50%;
					display: block;
					cursor: default;
				}
				.loading-mask {
					background-color: $color-1;
					.loader {
						img {
							max-width: 16px;
						}
					}
				}
			}
		}
		.mini-cart-heading {
			line-height: 38px;
			max-height: 38px;
			padding-right: 10px;
			&:hover {
				.inner-heading {
					.label {
						color: $color-3;
					}
				}
			}
			* {
				cursor: pointer !important;
			}
		}
		.mini-cart-content {
			left: auto;
			right: 0px;
			@include boxy-shadow("0px 0px 10px $color-8");
		}
		.mage-dropdown-dialog {
			position: absolute;
			z-index: 2;
			right: 0px;
			padding-top: 11px;
			margin-top: -10px;
			&:before {
				content: "";
				position: absolute;
				top: 0px;
				right: 42px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 12.5px 12px 12.5px;
				border-color: transparent transparent $color-8 transparent;
			}
			&:after {
				content: "";
				position: absolute;
				top: 1px;
				right: 42px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 12.5px 12px 12.5px;
				border-color: transparent transparent $color-1 transparent;
			}
			.mini-cart-content {
				background-color: $color-1;
				border: 1px solid $color-8;
				border-radius: 3px;
				margin-bottom: 0px;
				padding: 0px;
				min-width: 383px;
				width: auto;
				text-align: left;
				#minicart-content-wrapper {
					.block-content {
						.subtitle {
							&.empty {
								padding-left: 20px;
								padding-right: 20px;
								text-align: center;
							}
						}
						.minicart-items-wrapper {
							height: auto !important;
							max-height: calc(100vh - 205px);
							clear: both;
							margin-left: 0px;
							margin-right: 0px;
							padding: 0px;
							border-top: 0px solid $color-12;
							border-bottom: 1px solid $color-12;
							border-left: 0px;
							border-right: 0px;
							.minicart-items {
								.product-item {
									padding-top: 10px;
									padding-bottom: 10px;
									padding-left: 10px;
									padding-right: 10px;
									position: relative;
									border: 0px;
									@include transition(all $default_transition_time);
									& > .product {
										.product-item-photo {
											padding-right: 10px;
											.product-image-container {
												width: 95px !important;
												height: 67px;
												background-color: $color-1;
												display: block;
												@include boxy-shadow("0px 1px 14px rgba(0, 0, 0, 0.1)");
												.product-image-wrapper {
													height: 100%;
													.product-image-photo {
														max-width: 95px;
														max-height: 67px;
														width: auto !important;
														height: auto !important;
														position: relative;
														top: 50%;
														@include transform(translateY(-50%));
													}
												}
											}
										}
										.product-item-details {
											padding-left: 105px;
											padding-right: 20px;
											line-height: 67px;
											.product-item-details-inner {
												display: inline-block;
												vertical-align: middle;
												line-height: 1;
												.product-item-name {
													margin: 0px;
													margin-bottom: 6px;
													a {
														display: block;
														font-size: 12px;
														font-weight: 800;
														line-height: 18px;
														color: $color-13;
														max-width: 231px;
														&:hover {
															color: $color-3;
														}
													}
												}
												.product.options {
													display: none;
												}
												.product-item-pricing {
													.price-container {
														.price-wrapper {
															.minicart-price {
																.price {
																	display: block;
																	font-size: 16px;
																	font-weight: 800;
																	letter-spacing: 1px;
																	&:first-letter {
																		color: $color-3;
																	}
																}
															}
														}
													}
													.details-qty {
														display: none;
														.label {
															font-size: 12px;
															line-height: 32px;
															font-weight: 300;
															margin-bottom: 0px;
															float: left;
															width: auto;
															margin-right: 12px;
															&:after {
																display: none;
															}
														}
														input {
															font-family: $default_font_stack;
															display: block !important;
															width: 62px !important;
															border: 1px solid $color-8;
															border-radius: 3px;
															font-size: 12px;
															font-weight: 300;
															color: $color-13;
															text-align: center;
															@include transition(all $default_transition_time);
															float: left;
															margin-right: 0px;
															&:focus {
																@include boxy-shadow("0px 0px 2px rgba(52, 74, 106, 0.5)");
																border-color: $color-14;
															}
														}
														button {
															float: left;
															width: 32px;
															height: 32px;
															border-radius: 3px;
															background-color: $color-3;
															overflow: hidden;
															text-indent: -9999px;
															background-repeat: no-repeat;
															background-position: center center;
															@include svg-icon("ic_check_white");
															background-size: auto 7px;
															margin-left: 10px;
															&:hover {
																background-color: darken($color-3, 10%);
															}
														}
													}
												}
												.actions {
													margin: 0px;
													float: none;
													.primary {
														display: none;
													}
													.secondary {
														a {
															position: absolute;
															top: 0px;
															right: 0px;
                                                            width: 15px;
                                                            height: 15px;
                                                            margin: 15px;
															@include svg-icon("ic_cross_thin_text");
															overflow: hidden;
															text-indent: -9999px;
                                                            @include transition(transform $default_transition_time);

                                                            &:before {
																display: none;
															}
															&:hover {
																@include svg-icon("ic_cross_thin_red");
															}
														}
													}
												}
											}
										}
									}
									&:hover {
										background-color: $color-12;
									}
								}
							}
						}
						.items-total {
							display: none;
						}
						.custom-wrapper {
							.subtotal {
								margin: 0px;
								padding-top: 6px;
								padding-bottom: 5px;
								padding-left: 15px;
								padding-right: 15px;
								.label {
									display: inline-block;
									margin-right: 5px;
									&:after {
										font-size: 12px;
										font-weight: 800;
										margin-left: -3px;
									}
									span {
										font-size: 12px;
										font-weight: 800;
									}
								}
								.price-container {
									display: inline-block;
									.price-wrapper {
										.price {
											font-size: 16px;
											font-weight: 800;
											letter-spacing: 1px;
											display: block;
											&:first-letter {
												color: $color-3;
											}
										}
									}
								}
							}
							.actions {
								.primary {
								    margin: 0px !important;
								}
								button {
								    display: block;
								    width: 100%;
								    background-color: $color-3;
								    color: $color-1;
								    font-size: 15px;
								    line-height: 18px;
								    font-weight: 800;
								    letter-spacing: 1px;
								    text-transform: uppercase;
								    padding-top: 17px;
								    padding-bottom: 17px;
								    padding-left: 15px;
								    padding-right: 15px;
								    white-space: nowrap;
								    &:hover {
								    	background-color: darken($color-3, 10%);
								    }
								}
							}
							& + .actions {
								display: none !important;
								margin-top: 0px;
								position: relative;
								z-index: 1;
								a {
									display: block;
									background-color: $color-1;
									border-top: 1px solid $color-12;
									span {
										display: inline-block;
										color: $color-3;
										font-size: 12px;
										font-weight: 600;
										letter-spacing: 0.9px;
										position: relative;
										padding-top: 13px;
										padding-bottom: 13px;
										padding-right: 20px;
										&:before {
											content: "";
											position: absolute;
											top: 50%;
											right: 0px;
											@include transform(translateX(0px) translateY(-50%));
											width: 16px;
											height: 16px;
											background-repeat: no-repeat;
											background-position: center center;
											@include svg-icon("ic_chevron_medium_right_red");
											background-size: auto 13px;
											@include transition(all $default_transition_time);
										}
									}
									&:hover {
										span {
											text-decoration: underline;
											&:before {
												@include transform(translateX(3px) translateY(-50%));
											}
										}
									}
								}
							}
						}
						.minicart-widgets {
							display: none;
						}
					}
				}
			}
		}
	}
	&:hover {
		.mini-cart {
			.mage-dropdown-dialog {
				display: block !important;
			}
		}
	}
	.message-holder {
		position: absolute;
		z-index: 2;
		top: 100%;
		right: 10px;
		padding-top: 12px;
		padding-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
		border: 1px solid #b9b9b9;
		margin-top: -12px;
		@include transition(all $default_transition_time);
		@include transform(translateY(-10px));
		@include opacity(0);
		visibility: hidden;
		&.success {
			background-color: #b2f5b2;
		}
		&.active {
			visibility: visible;
			@include opacity(100);
			@include transform(translateY(0px));
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.minicart-block {
		.mini-cart {
			.inner-heading {
				.ic-cart {
					margin-top: 11px;
				}
			}
			.mage-dropdown-dialog {
				&:before {
					left: 33.5%;
					right: auto;
				}
				&:after {
					left: 33.5%;
					right: auto;
				}
				.mini-cart-content {
					#minicart-content-wrapper {
						.block-content {
							.minicart-items-wrapper {
								max-height: calc(100vh - 192px);
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.minicart-block {
		.mini-cart {
			.mage-dropdown-dialog {
				&:before {
					left: 88.5%;
				}
				&:after {
					left: 88.5%;
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.minicart-block {
		.mini-cart {
			.mage-dropdown-dialog {
				padding-left: 10px;
				padding-right: 10px;
				&:before {
					display: none;
				}
				&:after {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 400px) {
	.minicart-block {
		.mini-cart {
			.mage-dropdown-dialog {
				width: 100%;
				.mini-cart-content {
					min-width: 0px;
				}
			}
		}
	}
}


@media (max-width: 992px) {
	.minicart-block {
		margin-top: 0;
	}
}