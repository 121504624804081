.one-click-order-block {
	text-align: center;
	& > a {
		font-size: 15px;
		padding-top: 4px;
		padding-left: 36px;
		& > i {
			position: absolute;
			top: 0px;
			left: 0px;
		}
	}
}