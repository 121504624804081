.product-reviews-wrapper {
	display: block !important;
	@include opacity(100, important);
	.product-reviews-summary {
		position: relative;
		.rating-summary {
			.rating-result  {
				&:before {
					color: $color-8;
				}
				& > span {
					&:before {
						color: $color-3;
					}
				}
			}
		}
		.reviews-actions {
			position: absolute;
			top: 0px;
			bottom: 0px;
			left: 0px;
			right: 0px;
			overflow: hidden;
			text-indent: -9999px;
			a {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}
}
