@function getInlinedImage($file_name) {
	@return url(map-get($inlined_images, $file_name));
};

@mixin opacity( $opacity: 100, $important: null ) {
	@if $important == important {
		$important: !important;
	} @else {
		$important: null;
	}
	filter: alpha(opacity=$opacity) $important;
	-moz-opacity: $opacity / 100 $important;
	-khtml-opacity: $opacity / 100 $important;
	opacity: $opacity / 100 $important;
	zoom: 1;
}

@mixin transition( $parameters ) {
	transition: $parameters;
	-webkit-transition: $parameters;
}

@mixin transform( $transforms ) {
	 -moz-transform: $transforms;
	   -o-transform: $transforms;
	  -ms-transform: $transforms;
  -webkit-transform: $transforms;
		  transform: $transforms;
}

@mixin noselect() {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

// "box-shadow" is reserved by bootstrap
@mixin boxy-shadow( $parameters, $important: null ) {
	$parameters: unquote($parameters);
	@if $important == important {
		$important: !important;
	} @else {
		$important: null;
	}
	-webkit-box-shadow: $parameters $important;
	-moz-box-shadow: $parameters $important;
	box-shadow: $parameters $important;
}

@mixin radius( $parameters ) {
  -webkit-border-radius: $parameters;
	 -moz-border-radius: $parameters;
		  border-radius: $parameters;
}

@mixin linear-gradient($parameters) {
	$parameters: unquote($parameters);
	//background-color: $color_1; /* IE9 will show first color, because it does not support linear gradient */
	background: -webkit-linear-gradient($parameters); /* For Safari 5.1 to 6.0 */
	background: linear-gradient($parameters); /* Standard syntax (must be last) */
}

@mixin radial-gradient($parameters) {
	$parameters: unquote($parameters);
	//background-color: $color_1; /* IE9 will show first color, because it does not support linear gradient */
	background: -webkit-radial-gradient($parameters);
	background: radial-gradient($parameters); /* Standard syntax (must be last) */
}

@mixin filter( $filter ) {
	-webkit-filter: $filter;
			filter: $filter;
}

@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

@mixin placeholder {
	@include optional-at-root('::-webkit-input-placeholder') {/* WebKit, Blink, Edge */
		@content;
	}

	@include optional-at-root(':-moz-placeholder') {/* Mozilla Firefox 4 to 18 */
		@content;
		opacity:  1;
	}

	@include optional-at-root('::-moz-placeholder') {/* Mozilla Firefox 19+ */
		@content;
		opacity:  1;
	}

	@include optional-at-root(':-ms-input-placeholder') {/* Internet Explorer 10-11 */
		@content;
	}

	@include optional-at-root('::-ms-input-placeholder') {/* Microsoft Edge */
		@content;
	}
}
