.product-slider-wrapper {
	.product-slider-inner-left {
		width: 110px;
		float: left;
		.product-slider-nav-block {
			height: 520px;
			margin-top: -10px;
			margin-left: -15px;
			margin-right: -15px;
			overflow: visible;
			&.loading {
				overflow: hidden;
			}
			.slider-holder {
				.slick-list {
					height: 100% !important;
				}
				.slick-arrow {
					width: 100%;
					@include transform(none);
					background-image: none;
					&:before {
						content: "";
						margin-left: auto;
						margin-right: auto;
						width: 40px;
						height: 40px;
						background-repeat: no-repeat;
						background-position: center center;
						position: absolute;
						top: 0px;
						left: 0px;
						right: 0px;
						@include transition(transform $default_transition_time);
					}
					&.slick-prev {
						top: -7px;
						bottom: auto;
						left: 0px;
						right: 0px;
						&:before {
							@include svg-icon("ic_slider_nav_left_red");
							@include transform(rotate(90deg));
						}
					}
					&.slick-next {
						top: auto;
						bottom: -7px;
						left: 0px;
						right: 0px;
						&:before {
							@include svg-icon("ic_slider_nav_right_red");
							@include transform(rotate(90deg));
						}
					}
					&.slick-disabled {
						display: none !important;
					}
				}
				.slider-item {
					width: 110px;
					height: 110px;
					@include boxy-shadow("0px 1px 10px rgba(0, 0, 0, 0.1)");
					background-color: $color-1;
					border: 0px;
					margin-top: 10px;
					margin-bottom: 10px;
					margin-left: 15px;
					margin-right: 15px;
					@include transition(all $default_transition_time);
					background-size: auto;
					cursor: pointer;
					.item-inner {
						width: 100%;
						height: 100%;
					}
					&.active {
						@include boxy-shadow("0px 1px 10px rgba(0, 0, 0, 0.7)");
					}
				}
			}
		}
	}
	.product-slider-inner-right {
		width: 480px;
		float: right;
		.product-frame {
			@include boxy-shadow("0px 1px 14px rgba(0, 0, 0, 0.1)");
			position: relative;
			z-index: 1;
			.sticker-wrapper {
				bottom: 0px;
				right: 0px;
				z-index: 101;
				@include transition(all $default_transition_time);
				pointer-events: none;
				.sticker {
					&.custom {
						display: none;
					}
					&.new {
						position: absolute;
                        top: 8px;
                        left: 10px;
						width: 147px;
						height: 143px;
						@include svg-icon("ic_only_here_ribbon_lv");
						background-color: transparent;
						text-indent: -9999px;
						overflow: hidden;
                        transform: scale(1.2);
					}
					&.sale {
						position: absolute;
                        right: 40px;
                        bottom: 40px;
                        width: 92px;
                        height: 92px;
                        line-height: 104px;
                        font-size: 34px;
                        color: $color_2;
						@include transform(rotate(-10deg) scale(2));
						background-color: transparent;
						overflow: hidden;
						text-indent: -9999px;
						display: none;
						&:before {
							content: "";
							width: 93px;
							height: 95px;
							@include svg-icon("ic_discount_badge");
							@include transform(rotate(10deg));
							position: absolute;
							top: 0px;
							left: 0px;
							z-index: -1;
						}
						&:after {
							content: "-"attr(data-save);
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							text-align: center;
							text-indent: 0px;
							padding-right: 20px;
						}
					}
					&.save {
						display: block;
					}
				}
			}
			.product-slider-block {
				height: 500px;
				&.loading {
					overflow: hidden;
				}
				.slider-holder {
					.slider-item {
						background-size: auto;
						cursor: pointer;
						iframe {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
			&.video-state {
				.stock,
				.sticker-wrapper {
					display: none;
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.product-slider-wrapper {
		display: flex;
		flex-direction: column;
		.product-slider-inner-left {
			float: none;
			width: auto;
			order: 2;
			.product-slider-nav-block {
				height: auto;
				.slider-holder {
					.slick-arrow {
						top: 0px !important;
						bottom: 0px !important;
						width: 40px;
						height: auto;
						&:before {
							position: absolute;
							top: 50%;
							@include transform(translateY(-50%) !important);
							@include opacity(75, important);
						}
						&:active {
							&:before {
								@include opacity(100, important);
							}
						}
						&.slick-prev {
							right: auto;
							&:before {
								left: 0px;
							}
						}
						&.slick-next {
							left: auto;
							&:before {
								right: 0px;
							}
						}
					}
				}
			}
		}
		.product-slider-inner-right {
			float: none;
			width: auto;
			order: 1;
			.product-frame {
				margin-bottom: 20px;
				.sticker-wrapper {
					.sticker {
						&.new {
                            top: -12px;
                            left: -12px;
							width: 147px;
							height: 143px;
                            transform: scale(0.9);
						}
						&.sale {
							width: 99px;
							height: 99px;
							font-size: 30px;
							line-height: 102px;
                            @include transform(rotate(-10deg) scale(1));
                            right: -10px;
                            bottom: -10px;

                            &:after {
								padding-right: 15px;
							}
						}
					}
				}
				.product-slider-block {
					height: 312.5px;
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.product-slider-wrapper {
		.product-slider-inner-left {
			.product-slider-nav-block {
				.slider-holder {
					.slider-item {
						height: calc(100vw / 4 - 27px);
					}
				}
			}
		}
	}
}
