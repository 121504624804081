.popup-block {
	position:fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba($color-2, 0.5);
	@include transition(all 0.3s);
	@include opacity(0);
	visibility: hidden;
	overflow-x: hidden;
	overflow-y: auto;
	&.active{
		@include opacity(100);
		visibility: visible;
	}
	.popup-content {
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 80px;
		padding-right: 80px;
		& > .container {
			padding-left: 15px;
			padding-right: 15px;
			position: relative;
			.close-popup-button {
				position: absolute;
				z-index: 1;
				top: 0;
				left: -60px;
				width: 43px;
				height: 43px;
				@include svg-icon("ic_close_white_on_red");
				background-repeat: no-repeat;
				background-position: center center;
				cursor: pointer;
				@include transition(all $default_transition_time);
				&:hover {
					@include opacity(75);
				}
			}
			.inner-wrapper {
				background-color: $color-1;
				padding: 60px;
				border-radius: 3px;
				.content-overflow {
					overflow: auto;
					//max-width: calc(100vw - 110px);
				}
				&.loading {
					&:before {
						background-size: 50px 50px;
					}
				}
			}
		}
	}
	&.one-click-order-block-popup {
		.popup-content {
			& > .container {
				max-width: 710px;
				padding-left: 0;
				padding-right: 0;
				.inner-wrapper {
					position: relative;
					overflow: hidden;
					padding-top: 120px;
					padding-bottom: 80px;
					text-align: left;
					.form-block {
						h2 {
							color: $color-3;
						}
						.field {
							.control {
								display: flex;
								.left-side {
									flex: 1 1 auto;
									padding-right: 20px;
								}
								.right-side {
									flex: 1 1 234px;
									max-width: 234px;
								}
							}
						}
						.mage-error {
							text-align: left;
						}
					}
				}
			}
		}
	}
	&.forgot-password-block-popup {
		.popup-content {
			& > .container {
				max-width: 452px;
				padding-left: 0;
				padding-right: 0;
				.inner-wrapper {
					position: relative;
					overflow: hidden;
					padding-top: 90px;
					padding-bottom: 110px;
					text-align: left;
					h2 {
						text-align: center;
						margin-bottom: 41px;
					}
					.lock {
						border-top: 1px solid $color-12;
						margin-bottom: 18px;
						&:before {
							content: "";
							display: block;
							margin-left: auto;
							margin-right: auto;
							margin-top: -11px;
							width: 40px;
							height: 58px;
							background-repeat: no-repeat;
							background-position: center center;
							@include svg-icon("ic_lock_red");
						}
					}
					.form-block {
						.mage-error {
							text-align: left;
						}
					}
				}
			}
		}
	}
	&.custom-cms-block-popup {
		.popup-content {
			& > .container {
				max-width: 710px;
				padding-left: 0;
				padding-right: 0;
				.inner-wrapper {
					position: relative;
					overflow: hidden;
					text-align: left;
				}
			}
		}
	}
	&.product-gallery-popup {
		z-index: 1001;
		.popup-content {
			height: 100vh;
			padding-top: 80px;
			padding-bottom: 80px;
			& > .container {
				max-width: none;
				padding-left: 0;
				padding-right: 0;
				.close-popup-button {
					left: auto;
					right: 0;
					top: -60px;
				}
				.inner-wrapper {
					position: relative;
					overflow: hidden;
					text-align: left;
					padding: 30px;
					@include noselect();
					.object-wrapper {
						margin-left: 52px;
						margin-right: 52px;
						img {
							display: block;
							margin-left: auto;
							margin-right: auto;
							max-height: calc(100vh - 220px);
							pointer-events: none;
						}
						iframe {
							width: 100%;
							height: 100%;
							position: relative;
							z-index: 2;
						}
						img, iframe {
							position: relative;
							top: 50%;
							@include transform(translateY(-50%));
						}
						&.loading {
							min-height: 100px;
						}
					}
					.navigation {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						.item {
							position: absolute;
							top: 0;
							bottom: 0;
							width: 50%;
							cursor: pointer;
							&:before {
								content: "";
								position: absolute;
								top: 50%;
								width: 14px;
								height: 14px;
                                margin-top: -7px;
								background-repeat: no-repeat;
								background-position: center center;
                                background-size: 361px 326px;
								@include transition(all $default_transition_time);
								@include opacity(50);
							}
							&.prev {
								left: 0;
								&:before {
									left: 30px;
									@include svg-icon("ic_chevron_medium_right_red");
									@include transform(scaleX(-1));
								}
							}
							&.next {
								right: 0;
								&:before {
									right: 30px;
									@include svg-icon("ic_chevron_medium_right_red");
								}
							}
							&:active {
								&:before {
									@include opacity(100);
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
	.popup-block {
		&.product-gallery-popup {
			.popup-content {
				& > .container {
					.inner-wrapper {
						.navigation {
							.item {
								&:hover {
									&:before {
										@include opacity(100);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.popup-block {
		&.product-gallery-popup {
			.popup-content {
				padding-bottom: 20px;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.popup-block {
		.popup-content {
			padding-top: 70px;
			padding-left: 20px;
			padding-right: 20px;
			& > .container {
				.close-popup-button {
					top: -50px;
					left: auto;
					right: 0;
				}
				.inner-wrapper {
					padding: 15px;
				}
			}
		}
		&.one-click-order-block-popup {
			.popup-content {
				& > .container {
					.inner-wrapper {
						position: relative;
						overflow: hidden;
						padding-top: 40px;
						padding-bottom: 0;
						text-align: left;
						.form-block {
							.field {
								.control {
									display: block;
									.left-side {
										display: block;
										padding-right: 0;
										margin-bottom: 20px;
									}
									.right-side {
										display: block;
										max-width: 100%;
										button {
											width: 100%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&.forgot-password-block-popup {
			.popup-content {
				& > .container {
					.inner-wrapper {
						padding-top: 40px;
						padding-bottom: 50px;
					}
				}
			}
		}
		&.product-gallery-popup {
			.popup-content {
				& > .container {
					.inner-wrapper {
						padding: 15px;
						.object-wrapper {
							padding-left: 24px;
							padding-right: 24px;
							img {
								max-height: calc(100vh - 130px);
							}
						}
						.navigation {
							.item {
								&:before {
									width: 12px;
								}
								&.prev {
									&:before {
										left: 15px;
									}
								}
								&.next {
									&:before {
										right: 15px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
