.seasonal-block {
	.link-wrapper {
		a {
			display: block;
			color: $color-2;
			margin-bottom: 11px;
			text-decoration: none;
			font-size: 14px;
			line-height: 20px;
			&:hover {
				color: $color-3;
			}
			&:first-child {
				font-weight: 800;
			}
		}
	}
	.image-holder {
		display: block;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		position: relative;
		.discount-block {
			position: absolute;
			bottom: 0px;
			right: 0px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.seasonal-block {
		.link-wrapper {
			a {
				font-size: 10px;
				line-height: 15px;
			}
		}
		.image-holder {
			margin-left: -10px;
			margin-right: -10px;
		}
	}
}
