.logo-block {
	display: block;
	img {
		display: block;
	}
	.logo {
		margin-bottom: 0px;
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.logo-block {
		img {
            height: 50px;
            margin-top: -5px;
		}
	}
}
