.comments-block {
	margin-bottom: 76px;
	.amblog-comments-livestream {
		.top-wrapper {
			h2 {
				max-width: 768px;
				margin-left: auto;
				margin-right: auto;
				span {
					color: $color-3;
				}
			}
			hr {
				border-top: 1px solid $color-8;
				margin-bottom: 38px;
			}
		}
		.bottom-wrapper {
			hr {
				border-top: 1px solid $color-8;
			}
		}
		.am-comment-form {
			background-color: transparent;
			border: 0px;
			padding: 0px;
			margin: 0px;
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 34px;
			.add-comment-label {
				font-size: 15px;
				line-height: 18px;
				font-weight: 800;
				letter-spacing: 1px;
				color: $color-23;
				text-transform: uppercase;
				margin-bottom: 25px;
			}
			.field {
				margin-bottom: 24px;
			}
			input:not([type="checkbox"]):not([type="radio"]):not([type="file"]),
			textarea {
				padding-left: 17px !important;
				padding-right: 17px !important;
				width: 100% !important;
			}
			textarea {
				min-height: 100px;
				max-width: none;
				padding-top: 15px !important;
				padding-bottom: 15px !important;
				&:focus {
					background-color: $color-12;
				}
			}
			&.loading {
				height: 100px;
				overflow: hidden;
			}
		}
		.comments-list {
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;
			.amblog-comment {
				.am-comment-content {
					padding: 0px;
					margin: 0px;
					border: 0px;
					border-bottom: 1px solid $color-12;
					padding-bottom: 24px;
					margin-bottom: 38px;
					.am-comment-header {
						margin-bottom: 16px;
						.am-comment-author {
							font-size: 16px;
							line-height: 18px;
							font-weight: 800;
							color: $color-23;
							margin-right: 15px;
						}
						.am-comment-date {
							font-size: 12px;
							line-height: 16px;
							font-weight: 800;
							color: rgba($color-23, 0.4);
							position: relative;
							padding-left: 28px;
							padding-top: 2px;
							&:before {
								content: "";
								width: 18px;
								height: 18px;
								@include svg-icon("ic_clock_dark");
								background-repeat: no-repeat;
								background-position: center center;
								@include opacity(30);
								position: absolute;
								top: 0px;
								left: 0px;
							}
						}
						.am-comment-willapprove {
							line-height: 16px;
							padding-top: 2px;
						}
					}
					.comment-content {
						font-size: 16px;
						line-height: 26px;
						color: rgba($color-13, 0.7);
					}
					.form-actions {
						text-align: left;
						margin-top: 15px;
						.am-comment-reply-btn {
							display: inline-block;
							text-transform: none;
							a {
								display: block;
								font-size: 16px;
								line-height: 18px;
								font-weight: 800;
								letter-spacing: 1px;
								position: relative;
								padding-left: 25px;
								&:before {
									content: "";
									width: 15px;
									height: 15px;
									@include svg-icon("ic_comment_red");
									background-repeat: no-repeat;
									background-position: center center;
									position: absolute;
									top: 0px;
									left: 0px;
								}
							}
						}
					}
					&.just-added-comment {
						.form-actions {
							display: none;
						}
					}
				}
				.am-replies {
					padding-left: 88px;
				}
			}
		}
	}
	#amblog_loader_container {

	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.comments-block {
		.amblog-comments-livestream {
			.comments-list {
				.amblog-comment {
					.am-comment-content {
						.am-comment-header {
							.am-comment-willapprove {
								clear: left;
								margin-top: 10px;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.comments-block {
		.amblog-comments-livestream {
			.comments-list {
				.amblog-comment {
					.am-replies {
						padding-left: 20px;
					}
				}
			}
		}
	}
}
