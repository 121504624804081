.inputblock {
	position: relative;
	input, textarea, select {
		display: block;
		width: 100%;
		height: 53px;
		font-family: $default_font_stack;
		font-size: 16px;
		font-weight: 300;
		line-height: 53px;
		padding-top: 6px; /* Move the text down a bit so it is correctly vertically centered. On IE11 and less, the inputs placeholder will be pushed down by this even further, but after 1st blur it comes back to this fix */
		padding-bottom: 0px;
		padding-left: 17px;
		padding-right: 17px;
		background: transparent;
		color: $color-13;
		border-radius: 3px;
		border: 1px solid $color-8;
		@include transition(all $default_transition_time);
		&:focus {
			@include boxy-shadow("0px 0px 2px rgba(52, 74, 106, 0.5)");
			border-color: $color-14;
		}
	}
	&-select {
		select {
			padding-top: 1px;
			padding-right: 0px; /* must be 0 - for IE compatability */
			cursor: pointer;
		}
		&:before {
			content: "";
			position: absolute;
			top: 1px;
			right: 1px;
			background-color: $color-1;
			border-radius: 4px;
            width: 10px;
            height: 6px !important;
			@include svg-icon("ic_chevron_thick_down_red");
			pointer-events: none;
            margin: 22px 10px;
            transform: scale(1.2);

            @media (max-width: map-get($grid-breakpoints, "md") - 1) {
                margin: 15px 10px;
            }
		}
		&.control {
			position: relative;
		}
	}
	&-textarea {
		textarea {
			line-height: 20px;
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}

.inputblock-filled-style {
	input, textarea {
		display: block;
		font-family: $default_font_stack;
		font-size: 14px;
		font-weight: 300;
		background-color: $color-12;
		border: 0px solid $color-8 !important;
		border-radius: 3px;
		color: $color-13;
		height: 53px;
		line-height: 53px;
		padding-left: 17px;
		padding-right: 17px;
		@include transition(all $default_transition_time);
		&:focus {
			@include boxy-shadow("0px 0px 2px rgba(52, 74, 106, 0.5)", important);
			border-color: $color-14;
		}
	}
	textarea {
		height: auto;
		min-height: 263px;
		line-height: 20px;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.control {
		width: 100% !important;
	}
}

.inputblock-white-filled-style {
	input, textarea {
		display: block;
		font-family: $default_font_stack;
		font-size: 14px;
		font-weight: 300;
		background-color: $color-1;
		border: 0px solid $color-8;
		border-radius: 3px;
		color: $color-13;
		height: 53px;
		line-height: 53px;
		padding-left: 17px;
		padding-right: 17px;
		@include transition(all $default_transition_time);
		&:focus {
			@include boxy-shadow("0px 0px 2px rgba(52, 74, 106, 0.5)", important);
			border-color: $color-14;
		}
	}
	textarea {
		height: auto;
		min-height: 263px;
	}
	.control {
		width: 100% !important;
	}
}

input {
	&.js-invalid {
		@include boxy-shadow("0px 0px 3px " + $color-3);
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.inputblock-filled-style {
		input, textarea {
			height: 40px;
			line-height: 40px;
		}
		textarea {
			min-height: 100px;
		}
	}
	.inputblock {
		.inputblock-select {
			position: relative;

			select {
				height: 40px;
				line-height: 40px;
				min-width: 184px;
			}
		}
	}
}
