.consultation-b-block {
	max-width: 1140px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 82px;
	padding-bottom: 82px;
	padding-left: 45px;
	padding-right: 45px;
	border: 1px solid $color_8;
	display: flex;
	.left {
		flex: 1 0 195px;
		max-width: 195px;
	}
	.mid {
		flex: 1 1 100%;
		max-width: 100%;
	}
	.right {
		flex: 1 0 auto;
		padding-left: 20px;
	}
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.minor {
		font-size: 20px;
		color: $color_2;
		margin-bottom: 10px;
	}
	.major {
		font-size: 30px;
		font-weight: 600;
		color: $color_3;
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.consultation-b-block {
		padding-top: 30px;
		padding-bottom: 30px;
		flex-wrap: wrap;
		.left {
			flex: 1 0 110px;
			max-width: 110px;
		}
		.mid {
			flex: 1 1 calc(100% - 110px);
			max-width: calc(100% - 110px);
		}
		.right {
			flex: 1 0 100%;
			padding-left: 0px;
			margin-top: 20px;
			text-align: center;
		}
		img {
			margin-left: 0px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.consultation-b-block {
		padding: 20px;
		.minor {
			font-size: 14px;
			line-height: 1;
		}
		.major {
			font-size: 24px;
			line-height: 1;
		}
	}
}