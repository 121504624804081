.small-info-block {
	position: relative;
	padding-left: 66px;
	margin-bottom: 30px;
	img {
		position: absolute;
		left: 0px;
		top: 50%;
		@include transform(translateY(-50%));
		max-width: 45px;
		max-height: 45px;
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.small-info-block {
		img {
			top: 0%;
			@include transform(translateY(0%));
		}
	}
}
