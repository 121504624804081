a {
	color: $color-3;
	text-decoration: none;
	@include transition(all $default_transition_time);
	&:visited {
		color: $color-3;
	}
	&:hover,
	&:focus {
		color: $color-3;
		text-decoration: underline;
	}
	&.arrowed-link-style {
		display: inline-block;
		color: $color-3;
		font-size: 16px;
		font-weight: 800;
		letter-spacing: 0.9px;
		position: relative;
		padding-right: 20px;
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			right: 0px;
			@include transform(translateX(0px) translateY(-50%));
            width: 10px;
            height: 16px;
			@include svg-icon("ic_chevron_medium_right_red");
			@include transition(all $default_transition_time);
		}
		&:focus {
			text-decoration: none;
		}
		&:hover {
			text-decoration: underline;
			&:before {
				@include transform(translateX(3px) translateY(-50%));
			}
		}
		&.white-style {
			color: $color-1;
			&:before {
				@include svg-icon("ic_chevron_medium_right_white");
			}
		}
		&.togglable {
			&:hover {
				&:before {
					@include transform(translateX(0px) translateY(-50%));
				}
			}
			&.active {
				&:before {
					@include transform(translateX(0px) translateY(-50%) rotate(90deg));
				}
			}
		}
		&-reversed {
			padding-right: 0px;
			padding-left: 20px;
			&:before {
				right: auto;
				left: 0px;
				@include transform(translateX(0px) translateY(-50%) rotate(180deg));
			}
			&:hover {
				&:before {
					@include transform(translateX(-3px) translateY(-50%) rotate(180deg));
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	a {
		@include transition(none);
	}
}


@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	a {
		&.arrowed-link-style {
			font-size: 10px;
		}
	}
}
