.button {
	display: inline-block;
	font-family: $default_font_stack;
	font-size: 15px !important;
	font-weight: 800 !important;
	line-height: 18px !important;
	letter-spacing: 1px !important;
	min-width: 234px;
	text-align: center;
	padding-top: 19px;
	padding-bottom: 16px;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
	color: $color-1 !important;
	background-color: $color-3;
	border: 0px solid $color-1;
	border-radius: 0px;
	text-decoration: none !important;
	text-transform: uppercase;
	@include transition(all $default_transition_time);
	@include noselect();
	&:hover {
		background-color: lighten($color-3, 5%);
	}
	&:active {

	}
	&[disabled] {
		background-color: $color-6;
		cursor: not-allowed;
	}
	&.loading {
		cursor: default;
		@include opacity(100);
		&:before {
			background-size: 45px 45px;
		}
	}
	&-rounded-dark-blue-style {
		background-color: $color-14;
		border-radius: 3px;
		&:hover,
		&:active {
			background-color: $color-15;
		}
	}
}