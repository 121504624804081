.highlighted-products-block {
	.d-table {
		height: 305px;
		.d-table-row {
			.d-table-cell {
				vertical-align: middle;
			}
		}
	}
	.items {
		margin-left: -10px;
		margin-right: -10px;
		.item {
			float: left;
			width: 33.33%;
			padding-left: 10px;
			padding-right: 10px;
			text-align: center;
			.product-wrapper {
				display: inline-block;
				text-align: left;
				position: relative;
				.title {
					position: absolute;
					z-index: 1;
					bottom: -13px;
					left: 0px;
					font-size: 24px;
					line-height: 28px;
					font-weight: 800;
					color: $color-3;
					width: 200px;
					padding-top: 18px;
					padding-left: 27px;
					padding-right: 27px;
					&:before {
						content: "";
						position: absolute;
						z-index: -1;
						top: 0px;
						bottom: 0px;
						left: 0px;
						right: 0px;
						background-color: $color-1;
						@include transition(all $default_transition_time);
					}
				}
				&:before {
					content: "";
					position: absolute;
					top: 0px;
					bottom: 0px;
					left: 0px;
					right: 0px;
					background-color: $color-2;
					@include opacity(0);
					@include transition(all $default_transition_time);
				}
				&:hover {
					.title {
						&:before {
							top: -10px;
							right: -10px;
						}
					}
					&:before {
						@include opacity(40);
					}
				}
			}
			&:first-child {
                text-align: left;
			}
			&:last-child {
				text-align: right;
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.highlighted-products-block {
		.d-table {
			height: auto;
			.d-table-row {
				.d-table-cell {
					vertical-align: top;
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.highlighted-products-block {
        margin-top: 48px;
		.d-table {
			position: absolute;
			z-index: 2;
			width: 305px;
			height: 305px;
		}
		.items {
			.item {
				width: 50%;
				margin-bottom: 50px;
                &.hidden_mobile {
                    display: none;
                }
				&:nth-child(odd) {
					text-align: left;
				}
				&:nth-child(even) {
					text-align: right;
				}
			}
		}
	}
}


@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.highlighted-products-block {
		.d-table {
			width: 50%;
			height: auto;
			padding-right: 20px;
		}
		.items {
			.item {
				margin-bottom: 13px;
				.product-wrapper {
					.title {
						bottom: -9px;
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.highlighted-products-block {
		.d-table {
			h2 {
				font-size: 6.25vw;
				line-height: 6.25vw;
			}
			.text-style {
				font-size: 3.15vw;
				line-height: 4.7vw;
			}
			a {
				font-size: 3.15vw;
				line-height: 4.7vw;
				display: block;
				margin-top: 3.15vw;
			}
		}
		.items {
			.item {
				margin-bottom: 13px;
				.product-wrapper {
					.title {
						width: 30vw;
						font-size: 3.45vw;
						line-height: 4.38vw;
						padding-top: 2.5vw;
						padding-left: 3.15vw;
					}
				}
                &:last-child {
                    margin-bottom: 48px;
                }
			}
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
    .highlighted-products-block {
        margin-top: 48px;
    }
}
