.accordion-block {
	.item {
		margin-bottom: 15px;
		.title {
			position: relative;
			cursor: pointer;
			@include noselect();
			&:before {
				content: "";
                width: 8px;
                height: 16px;
				@include svg-icon("ic_chevron_medium_right_red");
				position: absolute;
				top: 8px;
				right: 0px;
			}
			h3 {
				margin-bottom: 0px;
				padding-right: 40px;
				@include transition(all $default_transition_time);
			}
		}
		.content {
			@include transition(all $default_transition_time);
			visibility: hidden;
			height: 0px;
			overflow: hidden;
			.content-inner {
				padding-top: 20px;
				padding-bottom: 20px;
				.fieldset {
					.inputblock {
						&:last-child {
							.field {
								margin-bottom: 0px;
							}
						}
					}
				}
			}
		}
		&.active {
			.title {
				&:before {
					@include transform(rotate(90deg));
				}
			}
			.content {
				visibility: visible;
				height: auto;
				overflow: visible;
			}
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "md")) {
	.accordion-block {
		.item {
			.title {
				&:hover {
					h3 {
						color: $color_3;
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.accordion-block {
		.item {
			.title {
				&:before {
					top: 1px;
				}
			}
		}
	}
}
