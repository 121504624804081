.product-list-page-block {
	.category-view {
        margin-bottom: 20px;
		& + .product-list-slider-block {
			margin-bottom: 0;
		}
	}
	.toolbar-products {
		margin-top: 0px !important;
		margin-bottom: 26px;
		.modes {
			float: right;
			padding: 0px;
			margin-left: 7px;
			.modes-mode {
				width: 20px;
				height: 32px;
				padding: 0px;
				margin-left: 8px;
				overflow: hidden;
				text-indent: -9999px;
				float: left;
				&:before,
				&:after {
					display: none;
				}
				&.mode-list {
					@include svg-icon("ic_list_gray");
					&.active {
						@include svg-icon("ic_list_red");
					}
				}
				&.mode-grid {
					@include svg-icon("ic_grid_gray");
					&.active {
						@include svg-icon("ic_grid_red");
					}
				}
			}
		}
		.toolbar-amount {
			font-size: 14px;
			padding: 0px;
			span {
				font-weight: 500;
			}
		}
		.toolbar-sorter {
			padding: 0px;
			position: relative;
			.sorter-label {
				display: none;
				visibility: hidden;
			}
			.sorter-action {
				float: left;
				width: 32px;
				height: 32px;
				position: relative;
				visibility: hidden;
				display: none;
				&:before {
					content: "";
					background-repeat: no-repeat;
					background-position: center center;
					background-size: auto 20px;
					position: absolute;
					top: 0px;
					bottom: 0px;
					left: 0px;
					right: 0px;
				}
			}
			.sorter-options {
				font-family: $default_font_stack;
				margin-left: 2px;
				border: 1px solid $color-8;
				border-radius: 3px;
				font-size: 12px;
				line-height: 30px;
				padding-top: 0px;
				padding-bottom: 0px;
				padding-left: 9px;
				padding-right: 9px;
				font-weight: 300;
				color: $color-13;
				cursor: pointer;
				min-width: 184px;
			}
			&:before {
				content: "";
				position: absolute;
				top: 1px;
				right: 1px;
				background-color: $color-1;
				border-radius: 4px;
                width: 10px;
                height: 5px;
				@include svg-icon("ic_chevron_thick_down_red");
				pointer-events: none;
                margin: 12px 5px;
                transform: scale(1.1);
			}
		}
	}
	.products.wrapper.items-grid,
	.products.wrapper.products-list {
		& + .category-page-header-block {
			display: none;
		}
	}
	.items-grid {
		.product-items {
			margin-left: -10px;
			margin-right: -10px;
			&:before,
			&:after {
				content: "";
				display: table;
			}
			&:after {
				clear: both;
			}
			.item {
				width: 240px;
				height: 340px !important;
				padding: 0px !important;
				border: 0px !important;
				margin-left: 10px;
				margin-right: 10px;
				margin-bottom: 22px !important;
				@include boxy-shadow(none);
				&:before {
					content: "";
					@include boxy-shadow("0px 1px 14px rgba(0, 0, 0, 0.1)");
					background-color: $color_1;
					position: absolute;
					top: 0px;
					bottom: 0px;
					left: 0px;
					right: 0px;
					@include transition(all $default_transition_time);
				}
				.product-item-info {
					.product-item-img {
						.actions-secondary {
							display: block;
						}
					}
					.product-item-details {
						text-align: center;
						padding-left: 10px;
						padding-right: 10px;
						position: relative;
						.product-item-name {
							font-size: 12px;
							line-height: 18px;
							margin: 0px;
							margin-bottom: 27px;
							a {
								display: block;
								font-size: 12px;
								line-height: 18px;
								font-weight: 800;
								color: $color_2;
								text-transform: uppercase;
								height: 33px;
								overflow: hidden;
							}
						}
						.product-gifting-block {
							position: absolute;
							top: 42px;
							left: 0px;
							right: 0px;
						}
						.price-box {
							padding-top: 38px;
						}
						.product-reviews-wrapper {
							margin-bottom: -38px;
						}
						.product-item-inner {
							visibility: hidden;
							height: 0px;
							overflow: hidden;
							@include opacity(0);
							@include transition(all $default_transition_time);
						}
					}
				}
				&:not(.no-hover-effects):hover {
					z-index: 3;
					&:before {
						bottom: -71px;
					}
                    @media screen and (min-width: 400px) {
                        &:before {
                            left: -21px;
                            right: -21px;
                        }
                    }
					.product-item-info {
						.product-item-img {
							.sticker-wrapper {
								left: -21px;
							}
							.actions-secondary {
								right: 0px !important;
							}
						}
						.product-item-details {
							.product-item-inner {
								visibility: visible;
								height: auto;
								@include opacity(100);
							}
						}
					}
				}
				&.no-hover-effects {
					.product-item-info {
						.product-item-img {
							.sticker-wrapper {
								left: 0px !important;
							}
							.actions-secondary {
								right: 0px !important;
							}
						}
					}
				}
				&.hover-downward-only {
					.product-item-info {
						.product-item-img {
							.sticker-wrapper {
								left: 0px !important;
							}
							.actions-secondary {
								right: 0px !important;
							}
						}
					}
					&:hover {
						height: auto !important;
						&:before {
							bottom: -12px;
							left: 0px !important;
							right: 0px !important;
						}
					}
				}
			}
		}
	}
	.products-list {
		margin-left: -20px;
		margin-bottom: 16px;
		.product-items {
			.product-item {
				border: 0px;
				padding: 20px;
				@include transition(all $default_transition_time);
				.product-item-info {
					.product-item-img {
						@include boxy-shadow("0px 1px 14px rgba(0, 0, 0, 0.1)");
						padding: 0px;
						vertical-align: middle;
						.product-item-photo {
							padding-top: 0px;
							padding-bottom: 0px;
						}
						.sticker-wrapper {
							left: 0px;
							.sticker {
								&.sale {
									bottom: 2px;
								}
							}
						}
						&.stock-unavailable {
							& + div.product-item-details > .price-box {
								display:none;
							}
						}
					}
					.product-item-details {
						.product-item-name {
							margin-bottom: 12px;
							.product-item-link {
								display: block;
								font-size: 18px;
								line-height: 18px;
								font-weight: 700;
								text-transform: uppercase;
								color: $color-2;
							}
						}
						.price-box {
							float: left;
							margin-right: 20px;
							.old-price {
								margin-right: 20px;
							}
						}
						.product-gifting-block {
							float: left;
							margin-top: 6px;
							margin-bottom: 25px;
						}
						.product-reviews-wrapper {
							float: left;
							clear: left;
							margin-top: -18px;
							.product-reviews-summary {
								margin-top: 0px !important;
								margin-bottom: 14px !important;
							}
						}
						.product-item-actions {
							clear: left;
							.actions-primary {
								float: left !important;
								margin-right: 20px;
								button {
									width: auto;
									min-width: 234px;
									margin-bottom: 10px;
								}
							}
							.actions-secondary {
								margin-top: 0px;
								padding: 0px;
								a {
									float: left;
									margin-bottom: 10px;
									margin-right: 20px;
									&:last-child {
										margin-right: 0px;
									}
								}
							}
						}
						.product-item-description {
							margin: 0px;
							margin-top: 5px;
							.more {

							}
						}
					}
					.product-item-inner {

					}
				}
				&:hover {
					background-color: $color-12;
				}
			}
		}
	}
	.product-item-img {
		margin-bottom: 12px;
		position: relative;
		overflow: visible;
		.product-item-photo {
			display: block;
			max-width: none !important;
			padding-top: 15px;
			padding-bottom: 15px;
			.product-image-container {
				width: auto !important;
				display: block;
				margin-left: auto;
				margin-right: auto;
				.product-image-wrapper {
					height: 150px !important;
					padding: 0px !important;
					img {
						max-width: 210px !important;
						max-height: 150px !important;
						position: relative;
						top: 50%;
						@include transform(translateY(-50%));
					}
					&.animator {
						position: absolute;
						overflow: visible;
						@include transition(all 2s);
						top: 0px !important;
						left: 0px !important;
						visibility: hidden;
						transform-origin: 100% 0px;
						-webkit-transform-origin: 100% 0px;
						width: 100%;
						height: 100% !important;
						z-index: 999;
					}
				}
			}
		}
		.sticker-wrapper {
			bottom: 0px;
			right: 0px;
			z-index: 2;
			@include transition(all $default_transition_time);
			pointer-events: none;
			.sticker {
				@include opacity(100);
				padding: 0px;
				&.custom {
					display: none !important;
				}
				&.new {
					position: absolute;
					top: -5px;
					left: -5px;
					width: 147px;
					height: 143px;
					@include svg-icon("ic_only_here_ribbon_lv");
					background-color: transparent;
					text-indent: -9999px;
					overflow: hidden;
				}
				&.sale {
					position: absolute;
					right: 0px;
					bottom: -15px;
					width: 99px;
					height: 99px;
					font-size: 30px;
					color: $color_2;
					line-height: 102px;
					@include transform(rotate(-10deg));
					background-color: transparent;
					overflow: hidden;
					text-indent: -9999px;
					&:before {
						content: "";
						width: 100%;
						height: 100%;
						background-repeat: no-repeat;
						background-position: left top;
						@include svg-icon("ic_discount_badge");
						@include transform(rotate(10deg));
						position: absolute;
						top: 0px;
						left: 0px;
						z-index: -1;
					}
					&:after {
						content: "-"attr(data-save);
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						text-align: center;
						text-indent: 0px;
						padding-right: 15px;
					}
				}
			}
		}
		.actions-secondary {
			position: absolute;
			z-index: 5;
			top: 22px;
			right: 50px !important;
			margin: 0px;
			padding: 0px;
			@include transition(all $default_transition_time);
			visibility: hidden;
		}
		&.stock-unavailable {
			& + .product-item-details > .price-box {
				display: none;
			}
		}
	}
    .price-box .old-price .price-container .price-wrapper {
        &:before {
            font-size: 25px !important;
        }
    }
	.price-box {
        div.register-kursi-15-percent {
            position: absolute;
            top: 70px;
            font-size: 10px;
            line-height: 12px;
            color: #E53647;
            text-align: left;
        }
		display: flex;
		.normal-price,
		.special-price,
		.old-price {
			display: block;
            width: 100%;
			justify-content: center;
			.price-container {
                float: left;
				display: block;
				.price-label {
					display: none;
				}
				.price-wrapper {
					display: inline-block;
					font-size: 16px;
					line-height: 1;
					font-weight: 800;
					color: $color_2;
					position: relative;
					padding-right: 25px;
					&:before {
						content: attr(data-price-full);
						font-size: 28px;
						position: relative;
						z-index: 1;
					}
					&:after {
						content: attr(data-price-change);
						position: absolute;
						top: 1px;
						left: calc(100% - 20px);
						font-size: 16px;
						font-weight: 800;
						@include noselect();
					}
					.price {
						overflow: hidden;
						position: absolute;
						bottom: 4px;
						right: 0px;
						font-size: 16px;
						font-weight: 800;
						@include noselect();
						width: 20px;
						height: 23px;
						text-indent: -9999px;
						&:before {
							content: "€";
							font-size: 12px;
							line-height: 0.6;
							position: absolute;
							bottom: 0px;
							left: 0px;
							text-indent: 0px;
						}
					}
				}
			}
		}
		.old-price {
			margin: 0px;
			order: 1;
			max-width: 50%;
			.price-container {
				.price-wrapper {
					color: $color_8;
					.strike {
						width: 100%;
						border-bottom: 2px solid $color_8;
						@include transform(rotate(-20deg));
						position: absolute;
						z-index: 2;
						top: 44%;
						left: 0px;
					}
				}
			}
		}
		.special-price {
			.price-container {
				.price-wrapper {
					color: $color-3;
				}
			}
		}
		.minimal-price-link {
			display: none;
		}
		.price-unit {
			flex: 0 1 auto;
            order: 3;
			margin-left: 5px;
			padding-left: 10px;
			border-left: 1px solid $color-13;
			color: $color-13;
			font-size: 13px;
			font-weight: 800;
			height: 30px;
			line-height: 49px;
		}
	}
	.product-item-actions {
		position: static !important;
		padding: 0px !important;
		margin: 0px !important;
		.stock {
			&.unavailable {
				display: none;
			}
		}
		.actions-primary {
			float: none !important;
			display: block;
			button {
				display: block;
				width: 100%;
				height: 53px;
				background-color: $color-3;
				color: $color-1;
				font-size: 15px;
				line-height: 18px;
				letter-spacing: 1px;
				text-transform: uppercase;
				padding-left: 10px;
				padding-right: 10px;
				&:hover {
					background-color: darken($color-3, 10%);
				}
			}
		}
	}
	.actions-secondary {
		a {
			float: none;
			display: block !important;
			margin-bottom: 4px;
            margin-right: 0;
            width: 28px;
            height: 28px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: auto 20px;
			@include transition(all $default_transition_time);
			span {
				display: none;
			}
			&.towishlist {
                background-color: $color-3;
                background-image: url('/assets/images/ic_heart_white.svg');
                &:hover {
					background-color: lighten($color-3, 5%);
				}
			}
			&.tocompare {
                background-color: $color-14;
                background-image: url('/assets/images/ic_compare_white.svg');
				&:hover {
					background-color: lighten($color-14, 5%);
				}
				&.added-to-comparison-table {
                    &::after {
                        width: 15px;
                        height: 9px;
                        margin: 11px auto;
                        transform: scale(1.2);
                        @include svg-icon("ic_check_white");
                    }
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.product-list-page-block {
		.toolbar-products {
			.pages {
				float: left;
			}
			.field.limiter {
				float: right;
			}
		}
		.products.wrapper {
			& + .toolbar-products {
				.field.limiter {
					display: block;
				}
			}
		}
		.items-grid {
			.product-items {
				.item {
					width: calc(50% - 20px);
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "lg") - 1) {
	.page-products.catalog-category-view  {
		.custom-page-block {
			display: none;
		}
	}
	.product-list-page-block {
		/*
		Hide block on mobile in initial load to prevent jumping,
		as its gets moved with javascript to another place
		 */
		.filter-block {
			display: none;
		}
		.toolbar-products:not(.footer) {
			display: grid;
			gap: 5px;
			grid-template-areas:
				"amount viewmode"
				"filter sorter";
			grid-template-columns: 57% 1fr;
			margin-bottom: 10px;
			.toolbar-sorter {
				max-width: 100%;
				margin-top: 10px;
				clear: left;
				.sorter-options {
					width: 100%;
					min-width: 100%;
				}
			}
			.toolbar-amount {
				grid-area: amount;
			}
			.modes {
				grid-area: viewmode;
				margin-left: auto;
			}
			.filter-block {
				margin-top: 10px;
				display: block;
				margin-bottom: 0;
				.filter {
					.filter-title strong {
						font-size: 12px !important;
						min-width: unset;
						padding-top: 7px;
						padding-bottom: 7px;

					}
				}
				grid-area: filter;
			}

			.toolbar-sorter {
				grid-area: sorter;
			}
			.pager-block {
				display: none;
			}
		}
		.products-list {
			margin-left: 0px;
			padding-left: 0px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.product-list-page-block {
		.toolbar-products {
			.toolbar-amount {
				float: left;
			}
			.modes {
				display: block;
				.modes-label {
					display: none;
				}
			}
		}
		.products-list {
			.product-items {
				.product-item {
					margin-bottom: 20px;
					.product-item-info {
						.product-item-img {
							display: block;
							margin-left: auto;
							margin-right: auto;
							width: 240px;
							padding-top: 15px;
							padding-bottom: 15px;
						}
						.product-item-details {
							.product-item-description {
								.more {
									float: right;
								}
							}
						}
					}
				}
			}
		}
		.items-grid,
		.products-list {
			& + .toolbar-products {
				.modes {
					display: none;
				}
			}
		}
	}
}



@media (max-width: map-get($grid-breakpoints, "sm") - 1) {

	.product-list-page-block {
		.products.wrapper {
			& + .toolbar-products {
				text-align: center;
				.product-items {
					display: inline-block;
				}
			}
		}
		.items-grid {
			.product-items {
				margin-left: 0px;
				margin-right: 0px;
				.item {
					width: auto;
					float: none;
					display: block;
					max-width: 330px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
    .product-list-page-block .price-box .old-price {
        max-width: 100%;
        width: 100%;
    }
    .product-list-page-block span.special-price {
        width: 40% !important;
    }

    .product-list-page-block .price-box div.register-kursi-15-percent {
        font-size: 15px;
        line-height: 20px;
        top: 40px;
    }
}
