.language-block {
	a {
		color: $color-9 !important;
		margin-right: 15px;
		&.active {
			color: $color-3 !important;
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}
