.search-block {
	position: relative;
	.block-search {
		display: block;
	}
	.field.search {
		& > .control {
			position: static;
			min-height: 0px;
		}
		.amsearch-wrapper-input {
			width: auto !important;
			max-width: 100%;
			position: static;
			.amsearch-close,
			.amsearch-loupe {
				display: none !important;
			}
		}
		#search {
			background-color: $color-12;
			border: 1px solid $color-8;
			border-radius: 3px;
			color: $color-9;
			font-size: 14px;
			font-weight: 800;
			width: 660px;
			height: 44px;
			padding-left: 17px;
			padding-right: 0px;
			text-transform: uppercase;
			@include placeholder {
				color: rgba($color-9, 1) !important; // IE10 & 11 required "important" flag in order to override default user agent style
				@include opacity(100); /* FF requires this to be 1 */
				@include transition(all $default_transition_time);
				font-style: normal !important;
			}
			&:focus {
				background-color: $color-1;
				border-color: $color-14;
				@include placeholder {
					color: rgba($color-9, 0.4) !important; // IE10 & 11 required "important" flag in order to override default user agent style
					@include opacity(100); /* FF requires this to be 1 */
				}
			}
		}
		.search-autocomplete {
			border: 1px solid $color-17;
			border-radius: 3px;
			margin-top: 10px !important;
			top: 44px;
			left: 0px;
			width: auto !important;
			max-width: none;
			.amsearch-results {
				border-top: 0px;
				padding-bottom: 0px;
				max-width: 100%;
				background-color: $color_1;
				.amsearch-leftside {
					flex: 1 0 225px;
					max-width: 225px;
					.amsearch-item-container {
						padding: 0px !important;
						&:after {
							display: none;
						}
					}
					.amasty-xsearch-block-header {
						padding: 0px;
						padding-top: 12px;
						padding-bottom: 10px;
						padding-left: 17px;
						padding-right: 17px;
						font-size: 12px;
						font-weight: 800;
						color: $color-13;
						&:before {
							display: none;
						}
					}
					.amsearch-item {
						display: block;
						padding: 0px;
						margin: 0px;
						.item-name {
							display: block;
							padding-top: 7px;
							padding-bottom: 7px;
							padding-left: 17px;
							padding-right: 17px;
							font-size: 12px;
							font-weight: 300;
							color: $color-13;
							text-decoration: none;
							background-color: transparent;
							&:hover {
								background-color: $color-12;
							}
						}
						.item-description {
							display: none;
						}
					}
				}
				.amsearch-products {
					padding-top: 20px;
					padding-bottom: 20px;
					position: relative;
					border-left: 1px solid $color-12;
					width: auto !important;
					flex: 1 1 auto;
					display: block;
					.amasty-xsearch-block-header {
						display: none;
					}
					.amsearch-wrapper-content {
						display: block;
						margin: 0px;
						padding: 0px;
					}
					.product-item {
						display: table;
						width: 100%;
						padding: 15px;
						cursor: default;
						max-width: 100%;
						&:before {
							display: none !important;
						}
						.product-item-inner {
							display: table-row;
							.amsearch-autocomplete-image {
								display: table-cell;
								vertical-align: top;
								width: auto;
								width: 151px;
								padding-right: 15px;
								.product-image-container {
									width: auto !important;
									display: block;
									position: relative;
									.product-image-wrapper {
										padding-bottom: 0px !important;
										display: block;
										height: auto;
										@include boxy-shadow("0px 0px 20px 3px rgba(0, 0, 0, 0.1)");
										background-color: $color-1;
										height: 106px;
										img {
											position: relative;
											bottom: auto;
											left: auto;
											right: auto;
											top: 50%;
											@include transform(translateY(-50%));
											width: auto !important;
											height: auto !important;
											max-width: 136px;
											max-height: 106px;
											border: 0px;

										}
									}
								}
							}
							.amsearch-autocomplete-information {
								display: table-cell;
								vertical-align: middle;
								width: auto;
								padding-left: 0px;
								.information-container {
									height: auto;
									.product-item-details {
										height: auto;
										min-height: 0px;
										display: block;
										.amsearch-wrapper-inner {
											display: block;
										}
										.product-item-name {
											margin: 0px;
											margin-bottom: 9px;
											.product-item-link {
												display: block;
												font-size: 12px;
												line-height: 18px;
												font-weight: 800;
												color: $color-2;
												.amsearch-highlight {
													font-weight: inherit;
													padding: 0px;
													background-color: transparent;
													color: inherit;
													padding: 0px;
												}
											}
										}
										.product-item-description {
											display: none;
										}
										.price-box {
											margin: 0px;
											max-width: none;
											display: flex;
											.normal-price,
											.special-price,
											.old-price {
												display: block;
												.price-container {
													display: block;
													.countering-ko-removal-actions-wrapper {
														display: block;
													}
													.price-label {
														display: none;
													}
													.price-wrapper {
														display: block;
														.price {
															display: block;
															font-size: 16px;
															line-height: 1;
															font-weight: 800;
															color: $color-2;
															&::first-letter {
																color: $color-3;
															}
														}
													}
												}
											}
											.old-price {
												float: left;
												margin: 0px;
												margin-left: 10px;
												text-decoration: none;
												.price-container {
													.price-wrapper {
														color: $color-8;
														position: relative;
														.strike {
															width: 100%;
															border-bottom: 1px solid $color-8;
															@include transform(rotate(-20deg));
															position: absolute;
															z-index: 2;
															top: 44%;
															left: 0px;
														}
														.price {
															color: $color-8;
															&::first-letter {
																color: $color-8;
															}
														}
													}
												}
											}
											.special-price {
												float: left;
												.price-container {
													.price-wrapper {
														color: $color-3;
													}
												}
											}
											&:before,
											&:after {
												content: "";
												display: table;
											}
											&:after {
												clear: both;
											}
											.minimal-price-link {
												display: none;
											}
											.price-unit {
												flex: 0 1 auto;
												margin-left: 11px;
												padding-left: 10px;
												border-left: 1px solid $color-13;
												color: $color-13;
												font-size: 13px;
												font-weight: 800;
												height: 14px;
												line-height: 17px;
											}
										}
										.product-item-actions {
											margin-top: 13px;
											width: auto;
											display: block;
											.actions-primary {
												clear: left;
												button {
													display: inline-block;
													font-family: $default_font_stack;
													font-size: 10px !important;
													font-weight: 700 !important;
													line-height: 18px !important;
													letter-spacing: 1px !important;
													width: 130px;
													text-align: center;
													padding-top: 8px;
													padding-bottom: 6px;
													padding-left: 10px;
													padding-right: 10px;
													cursor: pointer;
													color: $color-1 !important;
													background-color: $color-3;
													border: 0px solid $color-1;
													border-radius: 0px;
													text-decoration: none !important;
													text-transform: uppercase;
													@include transition(all $default_transition_time);
													@include noselect();
													&:hover {
														background-color: lighten($color-3, 5%);
													}
												}
											}
											.actions-secondary {
												padding: 0px;
												a {
													float: left;
													display: block !important;
													margin-bottom: 4px;
													margin-left: 23px;
													margin-right: 0;
													width: 32px;
													height: 32px;
													@include transition(all $default_transition_time);
													span {
														display: none;
													}
                                                    &:after {
                                                        content: '';
                                                        display: block;
                                                        height: 30px;
                                                        margin: 0 auto;
                                                    }
													&.towishlist {
														background-color: $color-3;
														&:hover {
															background-color: lighten($color-3, 5%);
														}
                                                        &:after {
                                                            width: 24px;
                                                            @include svg-icon("ic_heart_white");
                                                        }
													}
													&.tocompare {
														background-color: $color-14;
														&:hover {
															background-color: lighten($color-14, 5%);
														}
                                                        &:after {
                                                            width: 22px;
                                                            @include svg-icon("ic_compare_white");
                                                        }
													}
												}
											}
										}
									}
								}
							}
						}
						&:before,
						&:after {
							content: "";
							display: table;
						}
						&:after {
							clear: both;
						}
						&:hover {
							background-color: $color-12;
						}
					}
					.amsearch-more-results {
						text-align: right;
						padding: 15px;
						padding-bottom: 0px;
						a {
							font-size: 12px;
						}
					}
					&:before {
						content: "";
						position: absolute;
						top: 0px;
						bottom: 0px;
						left: 0px;
						width: 17px;
						@include linear-gradient("90deg, rgba(167, 169, 172, 0.15) 0%, rgba(196, 196, 196, 0) 100%");
					}
				}
				.amasty-xsearch-num-results {
					display: none;
				}
			}
		}
	}
	.block-content,
	.form.minisearch {
		& > .actions {
			position: absolute;
			top: 0px;
			right: 0px;
			button {
				display: block;
				width: 44px !important;
				height: 44px !important;
				background-color: $color-14 !important;
				border-radius: 3px;
				padding: 0px !important;
				@include opacity(100, important);
				transition: all $default_transition_time !important;
				-webkit-transition: all $default_transition_time !important;
				margin: 0px !important;
				pointer-events: all;
				cursor: pointer;

				&:before {
				    content: '';
				    display: block;
                    @include svg-icon("ic_magnify_white", important);
                    height: 26px;
                    width: 20px;
                    margin: 0 auto;
                }

				span {
					display: none;
				}
				&:hover {
					background-color: $color-15 !important;
				}
			}
		}
	}
	.amasty-xsearch-loader {
		margin-right: 0px;
		top: 0px;
		right: 0px;
		width: 44px;
		height: 44px;
		background-color: $color-14;
		border-radius: 3px;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: getInlinedImage("ic_loading_magnify_white.gif");
	}
}

.amsearch-overlay {
	display: none !important;
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.search-block {
		position: relative;
		.field.search {
			float: left;
			#search {
				width: 310px;
			}
			.search-autocomplete {
				width: 680px !important;
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
	.search-block {
		.field.search {
			.search-autocomplete {
				.amsearch-results {
					.amsearch-products {
						border-left: 0px;
						.amsearch-more-results {
							text-align: center;
						}
						&:before {
							top: 0px;
							bottom: auto;
							right: 0px;
							width: auto;
							height: 17px;
							@include linear-gradient("180deg, rgba(167, 169, 172, 0.15) 0%, rgba(196, 196, 196, 0) 100%");
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.search-block {
		.field.search {
			.search-autocomplete {
				width: 100% !important;
				max-width: 100% !important;
				.amsearch-results {
					.amsearch-products {
						.product-item {
							.product-item-inner {
								.amsearch-autocomplete-image {
									width: 70px;
									.product-image-container {
										.product-image-wrapper {
											img {
												width: 70px !important;
												height: 54px !important;
											}
										}
									}
								}
								.amsearch-autocomplete-information {
									.information-container {
										.product-item-details {
											.product-item-actions {
												display: none;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	.search-block {
		.field.search {
			.search-autocomplete {
				margin-bottom: 0px;
				margin-left: 0px;
				margin-right: 0px;
				.amsearch-results {
					.amsearch-leftside {
						max-width: none;
					}
				}
			}
		}
	}
}
