#jvlabelWrap,
.__jivoMobileButton {
	width: 75px !important;
	height: 75px !important;
	overflow: hidden !important;
	display: block !important;
	min-width: 0px !important;
	border-radius: 50% !important;
	background: $color-3 !important;
	bottom: 5px !important;
	right: 40px !important;
	@include boxy-shadow(none);
	margin-right: 0px !important;
	margin-bottom: 0px !important;

	&:before {
	    display: block;
	    content: '' !important;
        @include svg-icon("ic_chat_white", important);
        margin: 15px 10px;
        width: 52px;
        height: 43px;

        @media (max-width: map-get($grid-breakpoints, "md") - 1) {
            width: 53px;
            transform: scale(0.5);
            margin: -4px -9px;
        }
    }

	* {
		@include opacity(0);
	}
}

.__jivoMobileButton > [class*="button"] {
	display: none !important;
}

@media (max-width: map-get($grid-breakpoints, "md") - 1) {
	#jvlabelWrap,
	.__jivoMobileButton {
		width: 36px !important;
		height: 36px !important;
		background-size: 70% 70% !important;
		bottom: 21px !important;
		right: 5px !important;
	}
}
