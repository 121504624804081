.form-block {
	fieldset {
		.field {
			&:before {
				display: none !important;
			}
		}
	}
	.field {
		margin-bottom: 37px;
		.label {
			display: block;
			padding: 0px !important;
			text-align: left !important;
			width: auto !important;
			float: none !important;
			font-size: 14px;
			line-height: 1;
			font-weight: 300;
			color: $color-8;
			margin-bottom: 5px;
			span {
				display: block;
				&.required {
					display: inline;
				}
			}
			&:after {
				display: none;
			}
		}
		.control {
			display: block;
			width: auto !important;
			float: none !important;
		}
		input {
			&[type="checkbox"] {
				display: block;
				visibility: hidden;
				margin: 0px;
				padding: 0px;
				width: 0px;
				height: 0px;
				overflow: hidden;
				& + label {
					display: block !important;
					padding-left: 40px !important;
					padding-top: 4px !important;
					position: relative;
					cursor: pointer;
					font-size: 12px;
					line-height: 14px;
					color: $color-13;
					&:before {
						content: "";
						position: absolute;
						top: 1px;
						left: 0px;
						width: 20px;
						height: 20px;
						border-radius: 3px;
						background-repeat: no-repeat;
						background-position: center center;
						border: 2px solid $color-3;
					}
					&:hover {
						&:before {
							border-color: darken($color-3, 10%);
						}
					}
				}
				&:checked {
					& + label {
						&:before {
							background-color: $color-3;
							@include svg-icon("ic_check_white");
                            border: 5px solid $color-3;
                            border-right-width: 3px;
                            border-left-width: 2px;
						}
					}
				}
			}
			&:not([name*="rating"])[type="radio"] {
				display: block;
				visibility: hidden;
				margin: 0px;
				padding: 0px;
				width: 0px;
				height: 0px;
				overflow: hidden;
				& + label {
					display: block !important;
					padding-left: 40px !important;
					padding-top: 4px !important;
					position: relative;
					cursor: pointer;
					font-size: 12px;
					line-height: 14px;
					color: $color-13;
					&:before {
						content: "";
						position: absolute;
						top: 1px;
						left: 0px;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						background-repeat: no-repeat;
						background-position: center center;
						background-size: 15px auto;
						border: 2px solid $color-13;
					}
					&:hover {
						&:before {
							border-color: darken($color-3, 10%);
						}
					}
				}
				&:checked {
					& + label {
						&:before {
							background-color: $color-3;
							border-color: $color-3;
						}
					}
				}
			}
		}
	}
	div.mage-error[generated] {
		font-size: 14px;
		text-align: right;
	}
	&.custom-mage-error-handling {
		div.mage-error[generated] {
			display: none;
		}
		&.failed {
			div.mage-error[generated] {
				display: block;
			}
		}
	}
}
