
.icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-position: center center;
	vertical-align: top;
	@include transition(all $default_transition_time);
	&-phone-red {
		@include svg-icon("ic_phone_red");
		width: 14px;
		height: 14px;
	}
	&-mail-red {
		@include svg-icon("ic_mail_red");
		width: 14px;
		height: 14px;
	}
	&-cart-red {
		@include svg-icon("ic_cart_red");
		width: 14px;
		height: 14px;
		margin-top: -3px;
	}
	&-heart-text {
		@include svg-icon("ic_heart_text");
		width: 24px;
		height: 20px;
	}
	&-person-text {
		@include svg-icon("ic_person_text");
		width: 18px;
		height: 20px;
	}
	&-hamburger-text {
		@include svg-icon("ic_hamburger_text");
		width: 19px;
		height: 19px;
	}
	&-facebook-white {
		@include svg-icon("ic_facebook_white");
		width: 10px;
		height: 17px;
	}
	&-twitter-white {
		@include svg-icon("ic_twitter_white");
		width: 20px;
		height: 18px;
	}
	&-shipping-red {
		@include svg-icon("ic_shipping_red");
		width: 47px;
		height: 25px;
	}
	&-client-card-red {
		@include svg-icon("ic_client_card_red");
		width: 45px;
		height: 26px;
	}
	&-pickup-dark {
		@include svg-icon("ic_pickup_dark");
		width: 35px;
		height: 25px;
	}
	&-delivery-dark {
		@include svg-icon("ic_delivery_dark");
		width: 46px;
		height: 25px;
	}
	&-twoway-red {
		@include svg-icon("ic_arrow_right_red");
		width: 21px;
		height: 12px;
	}
	&-lightning-red {
		@include svg-icon("ic_lightning_red");
		width: 22px;
		height: 34px;
	}
	&-pickup-red {
		@include svg-icon("ic_pickup_red");
        width: 48px;
        height: 34px;
        margin: 10px 4px;
	}
	&-delivery-red {
		@include svg-icon("ic_delivery_red");
		width: 54px;
		height: 30px;
        margin: 12px 0;
	}
    &-delivery-blue {
        background-image: getInlinedImage("ic_delivery_blue.svg");
        width: 55px;
        height: 55px;
    }
	&-manipulator-red {
		@include svg-icon("ic_manipulator_red");
		width: 47px;
		height: 45px;
	}
	&-loader-red {
		@include svg-icon("ic_loader_red");
		width: 55px;
		height: 55px;
	}
    &-delivery-point-2h {
        background-image: getInlinedImage("ic_2h_red.svg");
        width: 55px;
        height: 55px;
    }
    &-delivery-point-dreilini {
        background-image: getInlinedImage("ic_delivery_point_dreilini.svg");
        width: 55px;
        height: 55px;
    }
	&-delivery-point-circlek {
		background-image: getInlinedImage("ic_delivery_point_circlek.svg");
		width: 55px;
		height: 55px;
	}
	&-delivery-point-omnivia {
		background-image: getInlinedImage("ic_delivery_point_omnivia.svg");
		width: 55px;
		height: 55px;
	}
	&-delivery-point-pasts {
		background-image: getInlinedImage("ic_delivery_point_pasts.svg");
		width: 55px;
		height: 55px;
	}
	&-delivery-point-dpd {
		background-image: getInlinedImage("ic_delivery_point_dpd.svg");
		width: 55px;
		height: 55px;
	}
	&-refresh-navy {
		@include svg-icon("ic_refresh_navy");
	}
	&-compare-white {
		@include svg-icon("ic_compare_white");
        width: 22px;
	}
	&-wallet-red {
		background-image: getInlinedImage("ic_wallet_red.jpg");
		width: 45px;
		height: 45px;
		background-size: 100% 100%;
	}
}
