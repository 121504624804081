.slider-partners-block {
	h2 {
		margin-bottom: 58px;
	}
	.block-content {
		margin: 0px;
		margin-left: -10px;
		margin-right: -10px;
	}
	.owl-carousel {
		.owl-wrapper-outer {
			.owl-wrapper {
				.owl-item {
					& > .item {
						padding-top: 0px;
						padding-bottom: 0px;
						padding-left: 10px;
						padding-right: 10px;
						line-height: 157px;
						a {
							display: block;
							@include opacity(100, important);
						}
						img {
							display: inline-block;
							vertical-align: middle;
							max-width: 157px;
							max-height: 157px;
							margin-left: auto;
							margin-right: auto;
							@include filter(grayscale(100%));
							@include transition(all $default_transition_time);
						}
						&:hover {
							img {
								@include filter(grayscale(0%));
							}
						}
					}
				}
			}
		}
		.owl-controls {
			.owl-buttons {
				position: static;
				.owl-prev,
				.owl-next {
					position: absolute;
					top: 50%;
					@include transform(translateY(-50%));
					width: 40px;
					height: 40px;
					background-repeat: no-repeat;
					background-position: center center !important;
					background-color: transparent !important;
					overflow: hidden;
					text-indent: -9999px;
					@include transition(all $default_transition_time);
				}
				.owl-prev {
					left: -10px;
					@include svg-icon("ic_arrow_circle_left_red", important);
					&:hover {
						left: -15px;
					}
				}
				.owl-next {
					right: -10px;
					@include svg-icon("ic_arrow_circle_right_red", important);
					&:hover {
						right: -15px;
					}
				}
			}
		}
	}
}
