@mixin svg-icon($name, $important: null){
    @if $important == important {
        $important: !important;
    } @else {
        $important: null;
    }

    $icon: map-get($icons, $name);
    background-image: url(/assets/images/css/icons.svg) $important;
    background-repeat: no-repeat;
    background-position: map-get($icon, x) map-get($icon, y) !important;
}

$icons: (
    "ic_2h_red": (
        x: 14.37699680511182%,
        y: 85.29856115107914%
    ),
"ic_arrow_circle_left_red": (
        x: 93.44859813084113%,
        y: 40.55944055944056%
    ),
"ic_arrow_circle_right_red": (
        x: 93.44859813084113%,
        y: 26.573426573426573%
    ),
"ic_arrow_right_red": (
        x: 43.69026548672567%,
        y: 40.929863535385586%
    ),
"ic_bell_white": (
        x: 41.470588235294116%,
        y: 67.48504983388705%
    ),
"ic_cart_red": (
        x: 33.08931185944363%,
        y: 92.78555157826229%
    ),
"ic_chat_white": (
        x: 79.66774193548389%,
        y: 56.18374558303887%
    ),
"ic_check_white": (
        x: 98.83861671469741%,
        y: 56.413249211356465%
    ),
"ic_chevron_medium_right_red": (
        x: 99.14406779661017%,
        y: 16.022508038585208%
    ),
"ic_chevron_medium_right_white": (
        x: 96.88418079096046%,
        y: 52.678456591639865%
    ),
"ic_chevron_thick_down_red": (
        x: 99.14164305949008%,
        y: 51.037383177570085%
    ),
"ic_chevron_thick_left_white": (
        x: 99.71067415730337%,
        y: 53.09119496855346%
    ),
"ic_chevron_thick_right_dark": (
        x: 98.3061797752809%,
        y: 53.09119496855346%
    ),
"ic_chevron_thick_right_red": (
        x: 99.95507258585349%,
        y: 67.87106918238993%
    ),
"ic_client_card_red": (
        x: 44.30379746835443%,
        y: 79.04333333333334%
    ),
"ic_clock_dark": (
        x: 99.99125364431487%,
        y: 0%
    ),
"ic_close_white_on_red": (
        x: 94.3301886792453%,
        y: 0%
    ),
"ic_cloud_red": (
        x: 93.44859813084113%,
        y: 14.675767918088738%
    ),
"ic_comment_gray": (
        x: 99.1242774566474%,
        y: 48.02243589743589%
    ),
"ic_comment_red": (
        x: 99.1242774566474%,
        y: 40.330128205128204%
    ),
"ic_compare_white": (
        x: 54.57227138643068%,
        y: 78.26072607260726%
    ),
"ic_cross_rounded_white": (
        x: 67.75147928994083%,
        y: 78.26072607260726%
    ),
"ic_cross_text": (
        x: 99.87769008998515%,
        y: 5.78461933991066%
    ),
"ic_cross_thick_red": (
        x: 98.55459770114942%,
        y: 68.73566878980891%
    ),
"ic_cross_thick_white": (
        x: 98.55459770114942%,
        y: 72.55732484076434%
    ),
"ic_cross_thin_red": (
        x: 98.83861671469741%,
        y: 60.20192307692308%
    ),
"ic_cross_thin_text": (
        x: 98.83861671469741%,
        y: 64.68910256410257%
    ),
"ic_cross_white": (
        x: 99.70058139534883%,
        y: 10.624595469255663%
    ),
"ic_delivery_dark": (
        x: 30.158730158730158%,
        y: 67.48504983388705%
    ),
"ic_delivery_red": (
        x: 48.40196078431373%,
        y: 33.435810810810814%
    ),
"ic_discount_badge": (
        x: 56.50034332799269%,
        y: 0%
    ),
"ic_eye_gray": (
        x: 99.1242774566474%,
        y: 44.24999999999999%
    ),
"ic_facebook_fill_red": (
        x: 80.18506493506494%,
        y: 38.827838827838825%
    ),
"ic_facebook_outline_red": (
        x: 80.18506493506494%,
        y: 0%
    ),
"ic_facebook_white": (
        x: 97.15864022662889%,
        y: 16.12621359223301%
    ),
"ic_grid_gray": (
        x: 79.76539589442815%,
        y: 77.49346405228758%
    ),
"ic_grid_red": (
        x: 61.58357771260997%,
        y: 66.38235294117646%
    ),
"ic_hamburger_text": (
        x: 38.74269005847953%,
        y: 92.0605708381764%
    ),
"ic_heart_text": (
        x: 55.19287833827893%,
        y: 66.38235294117646%
    ),
"ic_heart_white": (
        x: 48.07121661721068%,
        y: 66.38235294117646%
    ),
"ic_lightning_red": (
        x: 63.01775147928994%,
        y: 49.01712328767123%
    ),
"ic_list_gray": (
        x: 67.44868035190616%,
        y: 66.38235294117646%
    ),
"ic_list_red": (
        x: 73.31378299120234%,
        y: 66.38235294117646%
    ),
"ic_loader_red": (
        x: 0%,
        y: 85.29856115107914%
    ),
"ic_loading_red_on_white": (
        x: 19.93355481727575%,
        y: 53.80827067669173%
    ),
"ic_loading_white_on_red": (
        x: 0%,
        y: 53.80827067669173%
    ),
"ic_lock_red": (
        x: 37.38317757009346%,
        y: 53.406716417910445%
    ),
"ic_magnify_white": (
        x: 73.90029325513197%,
        y: 77.74754098360656%
    ),
"ic_mail_red": (
        x: 99.41159420289856%,
        y: 21.85079365079365%
    ),
"ic_manipulator_red": (
        x: 29.61783439490446%,
        y: 83.49647887323944%
    ),
"ic_minus_red": (
        x: 99.41159420289856%,
        y: 20.626543209876544%
    ),
"ic_only_here_ribbon_lv": (
        x: 0%,
        y: 0%
    ),
"ic_person_text": (
        x: 78.71720116618076%,
        y: 66.38235294117646%
    ),
"ic_phone_red": (
        x: 99.41159420289856%,
        y: 30.813504823151124%
    ),
"ic_phone_white": (
        x: 99.41159420289856%,
        y: 35.636655948553056%
    ),
"ic_pickup_dark": (
        x: 62.495384615384616%,
        y: 32.99%
    ),
"ic_pickup_red": (
        x: 0%,
        y: 69.56506849315069%
    ),
"ic_plus_red": (
        x: 99.41159420289856%,
        y: 25.751612903225805%
    ),
"ic_quotes_red": (
        x: 24.159021406727827%,
        y: 95.68120805369128%
    ),
"ic_refresh_navy": (
        x: 61.06194690265487%,
        y: 78.26072607260726%
    ),
"ic_shipping_red": (
        x: 15.286624203821656%,
        y: 67.71%
    ),
"ic_slider_nav_left_full_red": (
        x: 93.44859813084113%,
        y: 54.54545454545455%
    ),
"ic_slider_nav_left_red": (
        x: 93.44859813084113%,
        y: 68.53146853146853%
    ),
"ic_slider_nav_right_full_red": (
        x: 93.44859813084113%,
        y: 82.51748251748252%
    ),
"ic_slider_nav_right_red": (
        x: 0%,
        y: 99.6958041958042%
    ),
"ic_twitter_fill_red": (
        x: 51.94805194805195%,
        y: 52.42857142857143%
    ),
"ic_twitter_outline_red": (
        x: 80.18506493506494%,
        y: 19.413919413919412%
    ),
"ic_twitter_white": (
        x: 62.4633431085044%,
        y: 57.323624595469255%
    ),
"ic_wallet_red": (
        x: 12.422360248447205%,
        y: 99.34843205574913%
    )
);
