.leasing-calculator-block {
	.accordion-block {
		.item {
			.title {
				display: inline-block;
				&:before {
					top: 4px;
				}
				h4 {
					margin-bottom: 0px;
					padding-right: 25px;
					@include transition(all $default_transition_time);
				}
			}
		}
	}
}

@media (min-width: map-get($grid-breakpoints, "md")) {
	.leasing-calculator-block {
		.accordion-block {
			.item {
				.title {
					&:hover {
						h4 {
							color: $color_3;
						}
					}
				}
			}
		}
	}
}
