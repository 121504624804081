.product-alert-block {
	margin-top: 35px;
	max-width: 265px;
	margin-left: auto;
	margin-right: auto;
	.product-alert-block-inner-wrapper {
		.product {
			margin-bottom: 2px;
			a {
				position: relative;
				display: block;
				font-family: $default_font_stack;
				font-size: 15px !important;
				font-weight: 800 !important;
				line-height: 18px !important;
				letter-spacing: 1px !important;
				min-width: 234px;
				text-align: center;
				padding-top: 19px;
				padding-bottom: 16px;
				padding-left: 62px;
				padding-right: 20px;
				cursor: pointer;
				color: $color-1 !important;
				background-color: $color-3;
				border: 0px solid $color-1;
				border-radius: 0px;
				text-decoration: none !important;
				text-transform: uppercase;
				@include transition(all $default_transition_time);
				@include noselect();
				&:before {
					content: "";
					margin-right: 10px;
					@include svg-icon("ic_bell_white");
					width: 19px;
					height: 23px;
					position: absolute;
					top: 50%;
					left: 32px;
					@include transform(translateY(-50%));
				}
				&:hover {
					background-color: lighten($color-3, 5%);
				}
			}
			& + .product-alert-text-bottom {
				display: block;
			}
		}
		.product-alert-text-bottom {
			display: none;
			text-align: right;
			color: $color-3;
		}
	}
}
